@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";
.folder_header {
  padding-bottom: 12px;

  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  .folder_name_header {
    font-size: 30px !important;
    font-weight: 200 !important;
    margin-bottom: 0 !important;
    cursor: pointer;
    @include flexbox();
    @include align-items(center);
    svg {
      margin-left: 10px;
      color: $blue-primary;
      font-size: 20px;
      cursor: pointer;
    }
    span {
      @media (max-width: 575px) {
        font-size: 22px !important;
      }
    }
  }
  .folder_name_container {
    .folder_name_header_container {
      .folder_name_header {
        font-size: 30px !important;
        font-weight: 200 !important;
        margin-bottom: 0 !important;
        cursor: pointer;
        @include flexbox();
        @include align-items(center);
        svg {
          margin-left: 10px;
          color: $blue-primary;
          font-size: 20px;
          cursor: pointer;
        }
        span {
          @media (max-width: 575px) {
            font-size: 22px !important;
          }
        }
      }
    }
    svg {
      margin-right: 10px;
    }
  }

  .edit_folder_name_input {
    background: transparent;
    font-weight: 100;
    font-family: $font;
    color: black;
    margin-right: 15px;
    font-size: 30px;
    border: 1px dashed rgba($blue-primary, 0.6);
    border-radius: 5px;
    outline: none;
    @media (max-width: 575px) {
      font-size: 20px;
      margin-right: 10px;
      width: 230px;
    }
  }

  .folder_functionnalities {
    @include flexbox();
    @include align-items(center);
    .folder_function {
      margin: 0 5px;
      height: 52px;
      // width: 160px;
      padding: 0 15px;
      border-radius: 6px;
      border: 1px solid #ccc;
      font-size: 18px;
      font-weight: 500;
      color: $dark-gray;
      cursor: pointer;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      transition: all 0.2s;
      &:hover {
        -webkit-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
      }
      p {
        margin-bottom: 0;
        margin-left: 8px;
      }
      svg {
        width: 26px;
        height: 24px;
      }
    }
  }
}
.list_folders_products {
  overflow-y: scroll;
  height: 82vh;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgb(161, 161, 161);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  h6 {
    color: $blue-primary;
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0;
  }
  .list_products {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    & > a {
      padding: 0px !important;
    }
    // @media (min-width: 420px) {
    //   grid-template-columns: 1fr 1fr;
    // }
    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1700px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 2200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    .draggable-product {
      width: 100% !important;
    }
    // .product_card {
    //   display: flex;
    //   flex-direction: column;
    // }
    .product_card {
      height: 470px !important;
      width: 340px !important;
      .product_card_page {
        height: 395px !important;
        width: 100% !important;
      }
    }
    & > a {
      text-decoration: none !important;
    }
  }
}

.cp_item {
  position: relative;
  text-decoration: none !important;
  color: $dark-gray !important;
  font-weight: 600;
}

.customized_icon_folder {
  position: absolute;
  left: 10px;
  top: 10px;
  opacity: 0.5;
}

.customization_in_progress {
  position: absolute !important;
  right: 30px !important;
  top: 10px !important;
}
.customization_in_progress > .ant-badge-status-processing {
  background-color: #1890ff !important;
  width: 20px !important;
  height: 20px !important;
  &::after {
    border: 1px solid #1890ff !important;
  }
}

.customization_done {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  color: rgb(6, 204, 6) !important;
  font-size: 20px;
}
