@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./mixins.scss";
@import "../../sass/variables.scss";

// html {
//   // font-size: 10px;
//   // this to prepare for using REM;
// }

.folder_label{
  font-size: 8px !important;
  color: #757575;
}




.collection_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr  1fr ;
  justify-content: center;
  gap: 10px;


  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    //margin-top: 370px;
    margin-top: 59px;
  }

  @media (min-width: 425px) and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .collection_card {
    width: 100%;
    /*    background-repeat: no-repeat;
        background-size: cover;*/



    .discover_card_footer {
      p {
        font-size: 25px;
      }
    }
  }

}
.footer-add-to-collection{
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0%;
  gap: 20px;
}
.footer-add-to-collection_mobile{
  position: fixed;
  bottom: 0%;
  width: 100%;
  background: #ffffff;

}
.submit_button_white_discard_collection {
  width: fit-content;
  cursor: pointer !important;
  background:  $white !important;
  // padding from xd
  padding: 1rem 4rem !important;
  border: 1px solid $blue-primary !important;
  @include border-radius(8px);
  color: $blue-primary !important;
  font-family: $font !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  outline: none !important;
  @media (min-width: 992px) {
    font-size: 16px !important;
  }
  @media (max-width: 600px) {
    font-size: 12px !important;
    padding: 12px 24px !important;
  }
  @include transition(all, 0.1s, ease-in-out);
/*  &:hover {
    background:  $blue-primary !important;
    color:  $white!important;
    border: 1px solid $blue-primary !important;
  }*/
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51) !important;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
  border-radius: 5px !important;
}
.submit_button_white_discard_collection_mobile {
  width: fit-content;
  cursor: pointer !important;
  background:  $white !important;
  // padding from xd
  padding: 1rem 4rem !important;

  font-family: $font !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  outline: none !important;
  @media (min-width: 992px) {
    font-size: 16px !important;
  }
  @media (max-width: 600px) {
    font-size: 12px !important;
    padding: 12px 24px !important;
  }
  @include transition(all, 0.1s, ease-in-out);
  /*  &:hover {
      background:  $blue-primary !important;
      color:  $white!important;
      border: 1px solid $blue-primary !important;
    }*/
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51) !important;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
  border-radius: 5px !important;
}

.drop-zone-text{
  font-size: 16px !important;
  padding: 8px;
  @media (max-width: 991px) {
    font-size: 12px !important;
    padding: 8px;

  }
}
.add-to-collection-title{
font-size: 32px;
  font-weight: bold;
  @media (max-width: 991px) {
    font-size: 20px;
    font-weight: bold;
  }
}

.add-to-collection-description{
  font-size: 16px;
  @media (max-width: 991px) {
    font-size: 12px;
  }
}

.btn-damage-chooser {
  border-radius: 100%;
  border: none;
  background: transparent;
  border: 1px solid #00468c;
  height: 80px;
  width: 80px;
  cursor: pointer;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  object-fit: cover;
  overflow: hidden;
  @include transition(all, 0.3s, ease);
  img {
    object-fit: cover;
    height: 80px;
    width: 80px;
  }
  &:focus {
    outline: none;
  }
  &:active {
    @include transform(scale(0.9));
  }
}

.product-3d-config-tabs {
  // .ant-tabs-content-holder {
  //   height: 650.5px;
  // }
  .ant-tabs-content {
    // height: 650.5px !important;
    form {
      height: 100%;
    }
  }
  .ant-tabs {
    border-top: 1px solid #e0e0e0;
  }
  .top-header {
    padding: 21px;
    @media screen and (max-width: 1024px) {
      padding: 16px;
    }
    .title {
      color: #00468c;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      font-size: 20px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
    .product-code {
      color: #182844;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      font-size: 32px;
      font-weight: bold;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }

    .product-name {
      color: #182844;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      font-size: 24px;
      font-weight: bold;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }
  .ant-tabs-tab {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    height: unset !important;
    width: 100% !important;
    margin-top: 0px !important;
    @include transition(all, 0.2s, ease);
    color: #7c7c7c !important;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1px !important;
    &::before {
      @include transition(all, 0.5s, ease);
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0;
      background: #00468c;
      z-index: -1;
    }
    svg {
      path {
        @include transition(all, 0.2s, ease);
        fill: #7c7c7c;
      }
    }
    &:hover {
      color: #ffffff !important;
      border-color: transparent !important;
      &::before {
        width: 100%;
      }
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
  .ant-tabs-tab-btn {
    width: 100% !important;
    outline: unset !important;
    height: 100% !important;
  }
  //ant overide :
  .ant-tabs-tab-active {
    background: transparent;
    &::before {
      width: 100%;
    }
    border-color: #00468c !important;
    color: #ffffff !important;
    .custom-tab {
      color: #ffffff !important;

      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }

  .ant-tabs-tabpane {
    padding: 0px;
    padding-top: 20px;
    padding-right: 20px;
  }
  //form

  .textarea-custom {
    border: 1px solid #cecece;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
  }
}
.custom-select-tabs {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-select {
  margin: 0 !important;
  padding: 0;
}
.select-input-input {
  margin: 0 !important;
  padding: 0 !important;
}

.canvas-container,
.canvas-container-back {
  width: 100%;
  height: 100%;
}
#lazer-canvas {
  //cursor: none !important;
}

#lazer-canvas-back {
  //cursor: none !important;
}

.custom-brush-cursor,
.custom-brush-cursor-back {
  top: -500px;
  left: -500px;
  @include transform(translate(-50%, -50%));
  pointer-events: none;
  display: block;
  @include border-radius(100%);
  border-radius: 100%;
  position: fixed;
  width: 44px;
  height: 44px;
  // border: 1px solid #ff0707;
}

.container-configurator {
  width: 100%;
  position: relative;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  .choose-cursor-btn-configurator {
    @media screen and (max-width: 769px) {
      top: 85%;
    }
    border: 1px solid #809ec2;
    width: 70px;
    height: 70px;
    // left: 48%;
    top: 0%;
    // margin-bottom: 10px;
    position: absolute;
    z-index: 99;
    // margin-right: 14px;
    cursor: pointer;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include transition(all, 0.3s, ease);
    svg {
      width: 34px;
      height: 34px;
      path {
        fill: #000000;
        @include transition(all, 0.3s, ease);
      }
    }
    border-radius: 100%;
    background: white;
    &:focus {
      outline: none;
    }
    &:hover {
      background: #00468c;
      svg {
        path {
          fill: #ffffff;
          @include transition(all, 0.3s, ease);
        }
      }
    }
  }
  .vertical-line {
    @media screen and (max-width: 769px) {
      top: 91%;
    }
    position: absolute;
    width: 2px;
    height: 100px;
    background: #809ec2;
    margin: 20px auto 0 auto;
    left: 50%;
    top: 5%;
  }
  .configurator {
    @media screen and (max-width: 769px) {
      top: 104%;
    }
    padding: 20px;

    @include flexbox();
    @include flex-direction(column);
    position: absolute;
    z-index: 9;
    //@include flexbox();
    @include align-items(center);
    @include justify-content(center);
    left: 50%;
    top: 5%;
    @include transform(translateX(-50%));
    background: white;
    border: 1px solid #809ec2;
    border-radius: 200px;
    padding-bottom: 30px;
  }
}
input,
textarea {
  &:focus {
    outline: none;
  }
}

.my-own-design-configurator {
  // margin-top: 10vh;
  position: relative;
  @include flexbox();
  @include flex-direction(row);
  @include justify-content(center);
  width: 100%;

  .my-own-config-col {
    flex: 0.5;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
  }
  .m-o-jean-img-container {
    display: block;
    position: relative;
    max-height: 500px;
    img {
      -drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      position: relative;
      object-fit: contain;
      width: 100%;

      max-height: 500px;
      height: 100%;
    }

    .circle-btn-shooser {
      &.active {
        border: 1px solid white;
      }
      position: absolute;
      width: 41px;
      height: 41px;
      border-radius: 100%;
      border: none;
      cursor: pointer;
      @include transition(all, 0.2s, ease);
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      // background: #ff872a;
      &:focus {
        outline: none;
      }
      svg {
        width: 14px;
        path {
          fill: white;
        }
      }
      .delete-damage-buttons {
        display: none;
      }
      &:hover {
        .delete-damage-buttons {
          display: flex;
        }
        .damage-indicator {
          display: none;
        }
      }
    }
    .colorsContainer {
      position: absolute;
      @include flexbox();
      @include flex-direction(row);
      @include justify-content(center);
      @include align-items(center);
      min-width: 100px;
      @include transition(all, 0.5s, ease);
      cursor: pointer;
      //  z-index: 9999999999999999999999999;
      border-radius: 10px;
      padding: 5px;
      border: 1px solid black;
      max-width: 160px;
      min-height: 55px;
      flex-wrap: wrap;
      .circle-btn-shoosers {
        &.active {
          border: 1px solid white;
        }

        width: 41px;
        height: 41px;
        border-radius: 100%;
        margin-left: 3px;
        margin-bottom: 2px;
        border: none;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        &:hover {
          width: 45px;
          height: 45px;
          border-radius: 100%;
          @include transition(all, 0.5s, ease);
        }
        svg {
          width: 14px;
          path {
            fill: white;
          }
        }
      }
    }
  }
  .m-o-customizer-picker-containers {
    @media screen and (max-width: 769px) {
      top: 108%;
    }
    position: absolute;
    top: 20%;
    background: white;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    min-width: 100px;
    z-index: 999999999999999999999999999999999999;
    border: 1px solid #7a9ec5;
    border-radius: 60px;
    overflow: auto;
    // padding-top: 10px;
    // padding-bottom: 10px;
    .filled-btn-picker-m-o {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      border-radius: 100%;
      border: none;
      background: #00468c;
      border: 1px solid #00468c;
      height: 70px;
      width: 70px;
      cursor: pointer;
      padding: 0;
      margin: 2px;
      object-fit: cover;
      overflow: hidden;
      @include transition(all, 0.3s, ease);
      svg {
        object-fit: cover;
        width: 30%;
        path {
          fill: white;
        }
      }
      &:focus {
        outline: none;
      }
      &:active {
        @include transform(scale(0.9));
      }
    }
    .btn-with-dot-indicator {
      position: relative;
    }
    .btn-damage-chooser-mo {
      position: relative;
      border-radius: 100%;
      border: none;
      background: #00468c;
      border: 1px solid #00468c;
      height: 60px;
      width: 60px;
      cursor: pointer;
      padding: 0;
      //  margin: 2px;
      margin-bottom: 3px;
      margin-top: 3px;
      object-fit: cover;
      overflow: hidden;
      @include transition(all, 0.3s, ease);
      img {
        object-fit: cover;
        height: 60px;
        width: 60px;
      }
      .delete-damage-icon {
        display: none;
      }
      &:focus {
        outline: none;
      }
      &:active {
        @include transform(scale(0.9));
      }

      &:hover {
        img {
          display: none;
          @include transition(all, 0.3s, ease);
        }
        .delete-damage-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          @include transition(all, 0.6s, ease);
        }
      }
    }
  }
  .m-o-customizer-picker-container {
    @media screen and (max-width: 769px) {
      top: 108%;
    }
    position: absolute;
    top: 0%;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    min-width: 100px;
    z-index: 999999999999999999999999999999999999;
    border: 1px solid #7a9ec5;
    border-radius: 60px;
    overflow: auto;
    // padding-top: 10px;
    // padding-bottom: 10px;
    .filled-btn-picker-m-o {
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      border-radius: 100%;
      border: none;
      background: #00468c;
      border: 1px solid #00468c;
      height: 70px;
      width: 70px;
      cursor: pointer;
      padding: 0;
      margin: 2px;
      object-fit: cover;
      overflow: hidden;
      @include transition(all, 0.3s, ease);
      svg {
        object-fit: cover;
        width: 30%;
        path {
          fill: white;
        }
      }
      &:focus {
        outline: none;
      }
      &:active {
        @include transform(scale(0.9));
      }
    }
    .btn-with-dot-indicator {
      @media screen and (max-width: 769px) {
        margin-left: 3px;
      }
      position: relative;
    }
    .btn-damage-chooser-mo {
      position: relative;
      border-radius: 100%;
      border: none;
      background: #00468c;
      border: 1px solid #00468c;
      height: 70px;
      width: 70px;
      cursor: pointer;
      padding: 0;
      //  margin: 2px;
      margin-bottom: 6px;
      margin-top: 6px;
      object-fit: cover;
      overflow: hidden;
      content: "\f2ed";
      font-family: "Font Awesome 5 Free";
      font-weight: 800;
      // top: 50%;
      @include transition(all, 0.3s, ease);
      img {
        object-fit: cover;
        height: 71px;
        width: 71px;
      }
      .delete-damage-icon {
        display: none;
      }
      &:focus {
        outline: none;
      }
      &:active {
        @include transform(scale(0.9));
      }

      &:hover {
        img {
          display: none;
          @include transition(all, 0.3s, ease);
        }
        .delete-damage-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          @include transition(all, 0.6s, ease);
        }
      }
    }
  }
}

.style_input {
  border: 1px solid #cececea9 !important;
  border-radius: 5px;
  font-size: 20px;
  padding: 10px;
  height: auto;
  width: 100%;
}

.btn-configurator-holder {
  // margin-top: auto;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: white;
  @include flexbox();
  @include justify-content(flex-end);
  @include align-self(flex-end);
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.btn-configurator-holder-custom-product {
  // margin-top: auto;
  width: 100%;

  background: white;
  @include flexbox();
/*  @include justify-content(flex-end);
  @include align-self(flex-end);*/
  justify-content: center;
  align-self: center;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}


.colour_slider {
  .ant-slider-handle {
    width: 8px !important;
    height: 28px !important;
    margin-top: -13px !important;
    background-color: #00468c !important;
    border: solid 2px #00468c !important;
    border-radius: 5px !important;
  }
  .ant-slider-rail {
    background: #e0f0f8 !important;
  }
  .ant-slider-mark {
    margin: 15px 0;
  }
  .ant-slider-step {
    .ant-slider-dot {
      top: -6px !important;
      width: 6px !important;
      height: 15px !important;
      margin-left: -4px !important;
      border-radius: 6px !important;
    }
  }
}

.upload-ow-design-wrapper {
  @media screen and (max-width: 769px) {
    margin-left: 3px;
  }
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-ow-design-btn {
  border: 2px solid #809ec2;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  color: #00468c;
  cursor: pointer;
  background-color: white;
  padding: 10px;
  height: 70px;
  width: 70px;
  border-radius: 100%;
  font-size: 60px;
  font-weight: bold;
  svg {
    pointer-events: none;
  }
  &:hover {
    background-color: #00468c !important;
  }
  &:active {
    background-color: #00468c !important;
  }
}
.upload-ow-design-btn-laser {
  border: 2px solid #809ec2;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  color: #00468c;
  cursor: pointer;
  background-color: white;
  padding: 10px;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  font-size: 60px;
  font-weight: bold;
  svg {
    pointer-events: none;
  }
  &:hover {
    background-color: #00468c !important;
  }
  &:active {
    background-color: #00468c !important;
  }
}
.upload-ow-design-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.scrollable-items-previews {
  @media screen and (max-width: 769px) {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2px;
  }
  // max-height:400px;
  box-sizing: content-box;
  // padding: 10px;
  // overflow-y: auto;
  z-index: 999999999999999;
  // overflow-x: hidden;
}

.filled-btn-picker-m-os {
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  border-radius: 100%;
  border: none;
  background: #00468c;
  border: 1px solid #00468c;
  height: 70px;
  width: 70px;
  cursor: pointer;
  padding: 0;
  margin: 2px;
  object-fit: cover;
  overflow: hidden;
  @include transition(all, 0.3s, ease);
  svg {
    object-fit: cover;
    width: 30%;
    path {
      fill: white;
    }
  }
  &:focus {
    outline: none;
  }
  &:active {
    @include transform(scale(0.9));
  }
}
.btn-with-dot-indicators {
  position: relative;
}
.btn-damage-chooser-mos {
  position: relative;
  border-radius: 100%;
  border: none;
  background: transparent;
  border: 1px solid #00468c;
  height: 70px;
  width: 70px;
  cursor: pointer;
  padding: 0;
  margin: 2px;
  margin-bottom: 6px;
  margin-top: 6px;
  object-fit: cover;
  overflow: hidden;
  @include transition(all, 0.3s, ease);
  img {
    object-fit: cover;
    height: 71px;
    width: 71px;
  }
  &:focus {
    outline: none;
  }
  &:active {
    @include transform(scale(0.9));
  }
}
.choose-cursor-btn-brush {
  @media screen and (max-width: 769px) {
    top: 90%;
  }
  border: 1px solid #809ec2;
  width: 70px;
  height: 70px;
  // left: 48%;
  top: 0%;
  // margin-bottom: 10px;
  position: absolute;
  z-index: 99;
  // margin-right: 14px;
  cursor: pointer;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include transition(all, 0.3s, ease);
  svg {
    width: 34px;
    height: 34px;
    path {
      fill: #000000;
      @include transition(all, 0.3s, ease);
    }
  }
  border-radius: 100%;
  background: white;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #00468c;
    svg {
      path {
        fill: #ffffff;
        @include transition(all, 0.3s, ease);
      }
    }
  }
}
.vertical-line-lazer {
  @media screen and (max-width: 769px) {
    top: 95%;
  }
  position: absolute;
  width: 2px;
  height: 50px;
  background: #809ec2;
  margin: 20px auto 0 auto;
  left: 50%;
  top: 7%;
}

.attached-files-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
