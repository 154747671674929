@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";


.side_menu {
  height: 100%;
  width: 362px;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  padding: 32px 42px;
  overflow-y: scroll;
  box-shadow: 2px 0px 18px rgba(0, 0, 0, 0.3);

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: transparent;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  @media (max-width: 425px) {
    width: 328px;
  }
}
.logo_side_menu {
  height: 70px;
  margin-bottom: 60px;
}
.mix_product  {
  color: #7C7C7C !important;
text-decoration: none !important;
  background-color: transparent;
}

.denim_knit_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $dark-gray;
  width: 230px;
  .denim_knit_seperator {
    min-height: 26px;
    min-width: 1px;
    background-color: #dcdbdc;
  }
  .denim_knit_text {
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 24px;
  }

  .denim_knit_text_mobile {
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 16px;
  }
  margin-bottom: 30px;
}
.denim_knit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $dark-gray;
  width: 200px;
  .denim_knit_seperator {
    min-height: 26px;
    min-width: 1px;
    background-color: #dcdbdc;
  }
  .denim_knit_text {
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 24px;
  }

  .denim_knit_text_mobile {
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 16px;
  }
  margin-bottom: 30px;
}

.selected_menu {
  color: $blue-primary;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
}
.menu_elements {
  color: $black !important;
  @include flexbox();
  @include flex-direction(column);
  // @include justify-content(space-between);
  // @include align-items(center);
  p {
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    display: inline;
    margin-bottom: 0;
    width: fit-content;
    @media (max-width: 1366px) {
      font-size: 24px;
    }
  }
  p:hover {
    color: $blue-primary;
  }

  .menu_element {
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 16px !important;
  }

  .menu_element_mobile{
    position: relative;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 16px !important;
  }
/*  .side_menu > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 12px !important;
    background: red !important;
  }*/
  .menu_element:after {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    display: block;
    margin-top: -2px;
    right: 0;
    background: #fff;
    transition: width 0.2s ease;
    -webkit-transition: width 0.2s ease;
  }

  .menu_element:hover:after {
    width: 100%;
    left: 0;
    background: $blue-primary;
  }
  .menu_element_new {
    margin-bottom: 10px;
    @media (max-width: 1366px) {
      margin-bottom: 10px;
    }
    a {
      text-decoration: none !important;
      color: black;
      &:hover {
        color: $blue-primary;
      }
    }
  }
  .menu_element_collection {
    margin-top: 50px;
    @media (max-width: 1366px) {
      margin-top: 5px;
    }
  }
}
.add-new-product-button {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: $blue-primary;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
  font-family: $font;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.add-new-product-button-mobile{
  width: 55px;
  height: 100%;
  border-radius: 32px;
  background-color: $blue-primary;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 10px;
  text-decoration: none !important;
}
.add-new-product-button-mobile:hover{

  color: #fff;

}
.add-new-product-button:hover {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $blue-primary;
  padding: 10px;
  text-decoration: none;
  border: 1px solid #00468c;
}
.marginTopAddnewproduct{
  margin-top: 25px !important;
}
.select_add_product{
  font-size: 16px;
  font-family: $font;
  @media (max-width: 879px) {
    font-size: 12px !important;

  }

}
.title_add_new_product{
  font-size: 24px !important;
  text-transform: uppercase;
  @media (max-width: 879px) {
    font-size: 16px !important;

  }
}
.select_add_product::placeholder {
  font-size: 16px;
  @media (max-width: 879px) {
    font-size: 12px !important;

  }
}

.table_list_ofproduct{
  font-size: 16px;
  @media (max-width: 879px) {
    font-size: 12px !important;

  }
}

.lable_add_new_product{
  font-size: 16px;
  @media (max-width: 879px) {
    font-size: 12px !important;

  }
}
.icon {
  display: inline-block;
  font-size: 24px !important;
}
.outside_click {
  position: absolute;
  top: 0;
  left: 0;
}
.sub_menu_extension {
  width: calc(100vw - 362px);
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
}

.asidemenumobile {
  @include flexbox();
  @include flex-direction(column);
  padding: 0px;
  text-transform: capitalize !important;
  .ant-collapse-header {

    @media (max-width: 879px) {
      font-size: 16px !important;
      text-transform: capitalize !important;
      font-weight: 500;
      color: black !important;
    }

  }
  .ant-collapse-content {
    @media (min-width: 1100px) {
      font-size: 14px;
    }
    @media (min-width: 1366px) {
      font-size: 16px;
    }
    @media (min-width: 1600px) {
      font-size: 18px;
    }
    @media (min-width: 1920px) {
      font-size: 20px;
    }
  }

  .category_link_container {
    .categories_links {
      cursor: pointer;
      text-underline-position: under;
      svg {
        height: 18px;
        width: 18px;
        margin-left: 5px;
        margin-bottom: 8px;
        padding: 4px;
      }
      span {
        font-weight: 400;
        &:hover {
          color: $blue-primary;
        }
      }
    }
    .sub_elements {
      margin-left: 12px;
      // padding-top: 10px;
      .sub_element_container {
        .sub_element {
          margin-bottom: 8px;
          text-transform: uppercase;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            color: $blue-primary;
          }
          svg {
            height: 18px;
            width: 18px;
            margin-left: 5px;
            margin-bottom: 8px;
            padding: 4px;
          }
          span {
            font-weight: 400;
          }
          @media screen and (max-width: 1366px) {
            font-size: 14px;
          }
        }
        .sub_element_children {
          margin-left: 24px;
          // padding-top: 10px;
          .sub_element_child_container {
            .sub_element_child {
              margin-bottom: 8px;
              text-transform: uppercase;
              font-size: 16px;
              cursor: pointer;
              &:hover {
                color: $blue-primary;
              }
              span {
                font-weight: 400;
              }
              // @media (max-width: 1920px) {
              //   font-size: 20px;
              // }
              @media (max-width: 1600px) {
                font-size: 18px;
              }
              @media (max-width: 1366px) {
                font-size: 14px;
              }
              @media (max-width: 1100px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
.menu_list_mobile {
  @include flexbox();
  @include flex-direction(column);

  & > a {
    margin: 15px 0;
    text-decoration: none;
    color: black;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    display: inline;
    margin-bottom: 0;
    width: -moz-fit-content;
    width: fit-content;
  }
  & > a:hover {
    text-decoration: none;
  }
}

.mobile_menu_elements {
  p {
    font-size: 25px;
  }
}

.side_menu > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  svg {
    right: 78px;
    width: 42px;
    height: 42px;
    padding-bottom: 13px;
  }
}
.side_menu .ant-collapse-header {
  text-decoration: none !important;
  padding-left: 0 !important;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  margin-bottom: 0;
  text-transform: uppercase;

  &:hover {
    color: $blue-primary;
    text-decoration: underline;
  }

  & > svg {
    top: 50% !important;
    transform: translateY(-50%) !important;
    width: 42px;
    height: 42px;
    padding-bottom: 13px;
  }
}
.kids_sub_elements .ant-collapse-header {
  text-decoration: none !important;
  padding-left: 0 !important;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  margin-bottom: 0;
  text-transform: uppercase;

  &:hover {
    color: $blue-primary;
    text-decoration: underline;
  }

  & > svg {
    top: 50% !important;
    transform: translateY(-50%) !important;
    width: 42px;
    height: 42px;
    padding-bottom: 13px !important;
    right: 140px !important;
  }
}

.side_menu > .ant-divider-horizontal,
.menu_list_mobile > .ant-divider-horizontal {
  margin: 5px 0;
}

.mobile_menu_extension_header {
  @include flexbox();
  @include align-items(center);
  color: black;
  & > p {
    margin: 0;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
  }
}
.mobile_menu_extension_body {
  @include flexbox();
  @include flex-direction(column);
  margin-top: 20px;

  & > .sub_menu_elements > a {
    @include flexbox();
    @include flex-direction(column);
  }
  & > .sub_menu_elements > a {
    @include flexbox();
    @include flex-direction(column);
  }

  & > .sub_menu_elements > .sub_menu_element {
    margin: 15px 0;
  }
  & > .sub_menu_elements > .sub_menu_element > svg {
    margin-left: 10px;
    margin-bottom: 2px;
  }
  & > .sub_menu_elements > .sub_menu_element > a,
  & > .sub_menu_elements > a,
  & > .sub_sub_menu_elements > a,
  &
    > .sub_menu_elements
    > .kids_sub_elements
    > .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box
    > .sub_sub_menu_elements
    > .sub_sub_menu_element {
    color: black !important;
    text-decoration: none !important;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    text-transform: uppercase;
  }

  & > .sub_sub_menu_elements > a {
    color: black !important;
    text-decoration: none !important;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    padding-left: 20px;
    margin: 12px 0;
  }
  & > .sub_sub_menu_elements {
    @include flexbox();
    @include flex-direction(column);
  }
}
.mobile_menu_extension_body {
  .category_link_container {
    .categories_links {
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: $blue-primary;
      }
      svg {
        height: 18px;
        width: 18px;
        margin-left: 5px;
        margin-bottom: 8px;
        padding: 4px;
      }
      span {
        font-weight: 400;
      }
    }
    .sub_elements {
      margin-left: 12px;
      // padding-top: 10px;
      .sub_element_container {
        .sub_element {
          margin-bottom: 8px;
          text-transform: uppercase;
          font-size: 18px;
          cursor: pointer;
          &:hover {
            color: $blue-primary;
          }
          svg {
            height: 18px;
            width: 18px;
            margin-left: 5px;
            margin-bottom: 8px;
            padding: 4px;
          }
          span {
            font-weight: 400;
          }
        }
        .sub_element_children {
          margin-left: 24px;
          // padding-top: 10px;
          .sub_element_child_container {
            .sub_element_child {
              margin-bottom: 8px;
              text-transform: uppercase;
              font-size: 16px;
              cursor: pointer;
              &:hover {
                color: $blue-primary;
              }
              span {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

.submenu-mobile-collapse {
  .ant-collapse-header {
    padding: 0px !important;
  }
}

.menu_element_kids a {
  text-decoration: none !important;
  color: black;
}
