@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.myspace_aside {
  @include flexbox();
  @include flex-direction(column);
  width: 85%;
  height: 100%;
  @media (max-width: 575px) {
    width: 100%;

    .hello_title{
      font-size: 12px !important;
    }
    .user_name {
      font-weight: bold;
      font-size: 12px !important;
    }
  }
  header {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    margin-bottom: 25px;

    div:first-child {
      @include flexbox();
      @include align-items(center);

      div {
        margin-left: 20px;
      }
      p {
        margin: 0;
        font-size: 14px;
      }
      .user_name {
        font-weight: bold;
        font-size: 16px !important;
      font-family: $font;
      }


      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }

    @media (max-height: 768px) {
      margin-bottom: 0;


    }
  }

  main {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    height: 90%;
    font-family: $font;

    h1 {
      font-weight: 200;
      // font-size: 2rem;
      margin: 20px 0;
      color: black;
      @media screen and (max-width: 575px) {
        font-size: 1.6rem !important;
        display: inline;
      }
      @media screen and (min-width: 768px) {
        font-size: 1.9rem !important;
      }
      @media screen and (min-width: 992px) {
        font-size: 2.2rem !important;
      }
      @media screen and (min-width: 1100px) {
        font-size: 2.4rem !important;
      }
      @media screen and (min-width: 1366px) {
        font-size: 2.6rem !important;
      }
      @media screen and (min-width: 1600px) {
        font-size: 3rem !important;
      }
      @media screen and (min-width: 1920px) {
        font-size: 3.3rem !important;
      }
    }

    .links_list {
      @include flexbox();
      @include flex-direction(column);
      margin-top: 35px;

      @media (max-height: 768px) {
        margin-top: 20px;
      }
    }
  }
}

.welcome{
  font-size: 24px !important;
  font-family: $font;
  @media (max-width: 775px) {
    font-size: 16px !important;
  }
}
.myspace_elememt{
  font-size: 16px !important;
  @media (max-width: 775px) {
    font-size: 12px !important;
  }
}
.dropdown_link {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  &:hover > div > svg:first-child {
    background: $blue-sky-light;
  }
  &:hover > div > div {
    transform: translateX(15px) !important;
  }
  &:hover > div {
    transform: none !important;
  }
  & > div {
    @include flexbox();
    @include align-items(center);
    margin-left: 0 !important;
    & > svg {
      background: transparent;
      width: 60px !important;
      height: 60px !important;
      padding: 15px !important;
      border-radius: 50% !important;
      transition: all 0.3s ease-in-out !important;
    }
    &:hover > svg:first-child {
      background: $blue-sky-light;
    }
  }

  & > svg:last-child {
    color: $blue-primary;
  }
}
.link_item {
  @include flexbox();
  @include align-items(center);
  margin-bottom: 22px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  /*
    &:hover > div {
      transform: translateX(15px);
    }

    &:hover > svg:first-child {
      background: $blue-sky-light;
    }*/
  @media (max-height: 800px) {
    margin-bottom: 16px;
  }
  & > svg {
    background: transparent;
    width: 60px !important;
    height: 60px !important;
    min-width: 55px !important;
    min-height: 55px !important;
    padding: 15px !important;
    border-radius: 50% !important;
    transition: all 0.3s ease-in-out !important;
    overflow: unset !important;
    @media screen and (max-width: 575px) {
      padding: 16px !important;
    }
  }

 .link_animation_myspace {
    position: relative;
   font-size: 16px !important;
   font-family: $font;
   @media (max-width: 775px) {
     font-size: 12px !important;
   }
  }

  .link_animation_myspace:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: -5px;
    left: 0;
    background-color: $blue-primary;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .link_animation_myspace:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  div {
    margin-left: 25px;
    color: black;
    transition: all 0.3s ease-in-out;

    @media (max-width: 575px) {
      margin-left: 15px;
    }

    h2 {
      font-weight: 400;
      margin: 0;
      font-size: 20px;
    }

    p {
      font-weight: 200;
      font-size: 14px;
      margin: 0;

      @media (max-width: 1366px) {
        font-size: 12px;
      }
    }
  }
}
.link_item_logout {
  margin-top: 15px;
  @media (max-width: 575px) {
    margin-top: 0;
  }
}

.language_dropdown {
  height: fit-content;
  border-radius: 8px;

  & > a {
    text-decoration: none;
  }
  & a:hover {
    background: $blue-primary !important;
    color: white !important;
  }
}
