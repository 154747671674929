@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.order_option_details {
  @include flexbox();
  @include flex-direction(column);
  width: 100%;
  position: relative;
}
.option_details_title {
  color: $blue-primary;
  font-size: 20px;
  text-transform: uppercase;
  padding-left: 15px;
}
.label_option_direct_order{
  font-size: 16px;
font-weight: 400 !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}
.one_option_details {
  font-family: $font;

  label {
    font-weight: 600;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }

  & > .col-md-5 > .dropzone {
    height: 100%;
    width: 100%;
  }
}
.option_details_select_wrapper > .ant-select > .ant-select-selector {
  // height: 100% !important;
  height: 50px !important;
  padding: 10px !important;
}
.option_details_select_wrapper > .ant-select > .ant-select-selector,
.option_details_input {
  border: 1px solid #cececea9;
  border-radius: 5px !important;
  font-size: 16px;
  padding: 10px;
  // height: 100% !important;
  font-family: $font;
  outline: none;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}
.option_details_input_error {
  border: 1px solid red !important;
}
.option_details_select_wrapper > .ant-select > .ant-select-arrow {
  font-size: 30px;
  top: 50%;
  right: 20px;
  transform: translateY(-80%);
  color: $blue-primary;
}

.option_details_btns {
  @include flexbox();
  @include align-items(center);
  @include align-self(flex-end);

  .option_btn {
    background: white;
    font-family: $font;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 10px 15px;
    outline: none;
    transition: all 0.3 ease-in-out;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
    &:hover {
      box-shadow: 0px 1px 3px rgba(#000000, 0.09);
    }
  }
  .option_remove {
    color: $dark-gray;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  .option_save {
    color: $blue-primary;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
}
.file_uploaded_miniature {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  background: $blue-sky-light;
  border-radius: 5px;
  padding: 5px 20px;
  height: 100%;

  & > div > svg {
    font-size: 35px;
    color: $blue-primary;
    margin-right: 10px;
    cursor: pointer;
  }
  & > .file_icons > svg,
  & > .file_icons > a > svg {
    font-size: 18px;
    color: $dark-gray;
    margin-left: 10px;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      color: $blue-primary;
      opacity: 1;
    }
  }

  .file_uploaded_details p:first-child {
    font-weight: 500 !important;
    font-family: $font !important;
    color: black !important;
    font-size: 15px !important;
    @media (max-width: 575px) {
      font-size: 11px !important;
    }
  }
  .file_uploaded_details p:last-child {
    font-weight: 300 !important;
    font-family: $font !important;
    color: $dark-gray !important;
    font-size: 15px !important;
    @media (max-width: 575px) {
      font-size: 11px !important;
    }
  }
}

.order_option_details {
  @media (max-width: 575px) {
    padding: 0;
    padding-top: 85px;
  }
}

.order_btn_mobile {
  position: sticky;
  bottom: 0;
  width: 100%;
//  height: 80px;
  height: 60px;
  background: $blue-primary;
  color: white;
  font-family: $font;
 font-size: 12px;
  border: none;
}

.option_title_input_new {
  border: none;
  border-bottom: 1px solid rgba($dark-gray, 0.5);
  outline: none;
  width: 55%;
  padding-left: 0;

  &:hover {
    border-color: $blue-primary;
  }
  &:focus {
    border-color: $blue-primary;
  }
}
.wash_text {
  color: black !important;
}

.ant-spin-dot {
  color: $blue-primary;
}

.alerts_list {
  position: absolute;
  left: 20px;
  bottom: 0px;
}

.input_in_select_wrapper {
  @include flexbox();
  @include align-items(center);

  & > input {
    height: 45px;
    font-size: 16px;
  }
}
.input_in_select::placeholder {
  opacity: 0.4;
  font-size: 16px;
}

.add_new_wash_btn {
  font-size: 18px;
  background: white;
  border: none;
  border-radius: 5px;
  @include transition(all, 0.2s, ease-in-out);
}

.add_new_wash_btn:hover {
  color: $blue-primary;
  background: rgba($light-gray, 0.3);
}

/* .ant-radio-checked .ant-radio-inner {
  border-color: #00468c !important;
  color: #00468c !important;
}
.ant-radio-inner::after {
  background-color: #00468c !important;
}
 */

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $blue-primary !important;
  font-size: 16px;
  @media (max-width: 768px) {
  font-size: 12px;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff !important;
  background: $blue-primary !important;
  border-color: $blue-primary !important;

}

.ant-radio-button-wrapper{
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}
.main_option {
  background-color: $blue-primary !important;
  color: white !important;
}
