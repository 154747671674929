@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.products_page_container {
  padding-top: 110px;
  .bread_crumb_list_products {
    position: fixed;
    z-index: 10;
    text-align: center;
    top: 107px;
    width: 100%;
    height: 55px;
    padding: 0 60px;
    background-color: white;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    .bread_crumb_item {
      color: black;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        color: $blue-primary;
      }
    }

    & > img {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      top: 88px;
    }
  }
  .spinner_loading_collections {
    @media (max-width: 800px) {
      width: 35px;
      height: 35px;
    }
  }
}

.activeSearch {
  border: 1px solid #cecece;
  margin-left: 25px;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 14px;
}
.activeSearchSelect {
  border: 1px solid #cecece;
  margin-left: 25px;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 14px;
  background-color: #182844;
  color: #ffffff;
}

.question_description{
  font-size: 16px;
  @media (max-width: 800px) {
    font-size: 12px;
  }

}

.products_page_container_faq {
  padding-top: 0px;
  .bread_crumb_list_products {
    position: fixed;
    z-index: 10;
    text-align: center;
    top: 107px;
    width: 100%;
    height: 55px;
    padding: 0 60px;
    background-color: white;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    .bread_crumb_item {
      color: black;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        color: $blue-primary;
      }
    }

    & > img {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      top: 88px;
    }
  }
  .spinner_loading_collections {
    @media (max-width: 800px) {
      width: 35px;
      height: 35px;
    }
  }
}
.active-question {
  background-color: #A5AB36;
}
.active-question > button {
  color: #f8faff !important;
  border-radius: 5px;
  font-weight: bold;
}
.products_page {
  @include flexbox();
  .products_list {
    display: flex;
    flex-direction: column;
    /*     overflow-y: scroll;
 */
    height: calc(100vh - 110px);
    width: 100%;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    .unavailable_products_container {
      font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      display: table;
      min-height: 25vh !important;
      margin: 0 auto;
      .unavailable_products {
        font-weight: 300;

        display: table-cell;
        vertical-align: middle;
      }
    }

    .submit_button_white {
      font-size: 20px;
      padding: 14px 60px;
      margin: 10px auto 20px;
    }
  }
}
.filter {
  min-width: 21%;
  overflow-y: scroll;
  height: calc(100vh - 120px);
  &::-webkit-scrollbar {
    display: none;
  }

  .ant-collapse-content-box {
    div span {
      font-weight: 300;
    }
  }

  @media (max-width: 850px) {
    width: 35%;
  }

  aside {
    @include flexbox();
    @include flex-direction(column);
    padding: 30px;

    .ant-collapse-header {
      font-weight: bold;
      @media (min-width: 1100px) {
        font-size: 12px;
      }
      @media (min-width: 1366px) {
        font-size: 14px;
      }
      @media (min-width: 1600px) {
        font-size: 16px;
      }
      @media (min-width: 1920px) {
        font-size: 18px;
      }
    }
    .ant-collapse-content {
      @media (min-width: 1100px) {
        font-size: 14px;
      }
      @media (min-width: 1366px) {
        font-size: 16px;
      }
      @media (min-width: 1600px) {
        font-size: 18px;
      }
      @media (min-width: 1920px) {
        font-size: 20px;
      }
    }

    .category_link_container {
      .categories_links {
        cursor: pointer;
        text-underline-position: under;
        svg {
          height: 18px;
          width: 18px;
          margin-left: 5px;
          margin-bottom: 8px;
          padding: 4px;
        }
        span {
          font-weight: 400;
          &:hover {
            color: $blue-primary;
          }
        }
      }
      .sub_elements {
        margin-left: 12px;
        // padding-top: 10px;
        .sub_element_container {
          .sub_element {
            margin-bottom: 8px;
            text-transform: uppercase;
            font-size: 16px;
            cursor: pointer;
            &:hover {
              color: $blue-primary;
            }
            svg {
              height: 18px;
              width: 18px;
              margin-left: 5px;
              margin-bottom: 8px;
              padding: 4px;
            }
            span {
              font-weight: 400;
            }
            @media screen and (max-width: 1366px) {
              font-size: 14px;
            }
          }
          .sub_element_children {
            margin-left: 24px;
            // padding-top: 10px;
            .sub_element_child_container {
              .sub_element_child {
                margin-bottom: 8px;
                text-transform: uppercase;
                font-size: 16px;
                cursor: pointer;
                &:hover {
                  color: $blue-primary;
                }
                span {
                  font-weight: 400;
                }
                // @media (max-width: 1920px) {
                //   font-size: 20px;
                // }
                @media (max-width: 1600px) {
                  font-size: 18px;
                }
                @media (max-width: 1366px) {
                  font-size: 14px;
                }
                @media (max-width: 1100px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .asidefaq {
    @include flexbox();
    @include flex-direction(column);
    padding: 0px;

    .ant-collapse-header {
      font-weight: bold;
      @media (min-width: 1100px) {
        font-size: 12px;
      }
      @media (min-width: 1366px) {
        font-size: 14px;
      }
      @media (min-width: 1600px) {
        font-size: 16px;
      }
      @media (min-width: 1920px) {
        font-size: 18px;
      }
    }
    .ant-collapse-content {
      @media (min-width: 1100px) {
        font-size: 14px;
      }
      @media (min-width: 1366px) {
        font-size: 16px;
      }
      @media (min-width: 1600px) {
        font-size: 18px;
      }
      @media (min-width: 1920px) {
        font-size: 20px;
      }
    }

    .category_link_container {
      .categories_links {
        cursor: pointer;
        text-underline-position: under;
        svg {
          height: 18px;
          width: 18px;
          margin-left: 5px;
          margin-bottom: 8px;
          padding: 4px;
        }
        span {
          font-weight: 400;
          &:hover {
            color: $blue-primary;
          }
        }
      }
      .sub_elements {
        margin-left: 12px;
        // padding-top: 10px;
        .sub_element_container {
          .sub_element {
            margin-bottom: 8px;
            text-transform: uppercase;
            font-size: 16px;
            cursor: pointer;
            &:hover {
              color: $blue-primary;
            }
            svg {
              height: 18px;
              width: 18px;
              margin-left: 5px;
              margin-bottom: 8px;
              padding: 4px;
            }
            span {
              font-weight: 400;
            }
            @media screen and (max-width: 1366px) {
              font-size: 14px;
            }
          }
          .sub_element_children {
            margin-left: 24px;
            // padding-top: 10px;
            .sub_element_child_container {
              .sub_element_child {
                margin-bottom: 8px;
                text-transform: uppercase;
                font-size: 16px;
                cursor: pointer;
                &:hover {
                  color: $blue-primary;
                }
                span {
                  font-weight: 400;
                }
                // @media (max-width: 1920px) {
                //   font-size: 20px;
                // }
                @media (max-width: 1600px) {
                  font-size: 18px;
                }
                @media (max-width: 1366px) {
                  font-size: 14px;
                }
                @media (max-width: 1100px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.filter_tags_wrapper {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  padding: 10px;
  width: 100%;

  & > div {
    @include flexbox();
    @include align-items(center);

    & > p {
      margin: 0;
      color: #5c5c5c;
      font-family: $font;
      font-size: 20px;
    }
  }

  & > p {
    @include flexbox();
    @include align-items(center);
    margin: 0;
    margin-right: 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-family: $font;
    color: #5c5c5c;
    font-weight: 300;
  }
}
.filter_tag {
  @include flexbox();
  @include justify-content(space-between);
  background: $blue-sky-light;
  font-family: $font;
  width: fit-content;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 400;
  margin-right: 20px;

  p {
    margin: 0;
  }
  & p:first-child {
    margin-right: 5px;
  }
  & > span {
    margin-left: 10px;
    cursor: pointer;
  }
  white-space: nowrap;
}
.filter_tags {
  @include flexbox();
  @include align-items(center);
  margin-left: 10px;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 5px;
}
.filter_tags::-webkit-scrollbar {
  height: 4px !important;
}

/* Track */
.filter_tags::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.filter_tags::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: gainsboro;
}

/* Handle on hover */
.filter_tags::-webkit-scrollbar-thumb:hover {
  background: rgb(161, 161, 161);
}
.filter_checkbox {
  appearance: none;
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: white;
  border: 1px solid $light-gray;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_checkbox::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f00c";
  font-size: 13px;
  color: white;
  display: none;
}

.filter_checkbox:hover {
  background: $blue-sky-light;
}
.filter_checkbox:checked {
  background: $blue-primary;
  border-color: $blue-primary;
}
.filter_checkbox:checked:after {
  display: block;
}

.ant-skeleton-avatar {
  width: 18vw !important;
  height: 45vh !important;
}

.price_range_wrapper {
  border: 1px solid $light-gray;
  border-radius: 10px;
  padding: 10px 30px;

  .prices_filter > p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .ant-slider-handle {
    background-color: $blue-primary;
    border: solid 2px $blue-primary;
    width: 20px;
    height: 20px;
  }
  .ant-slider-track {
    background: #e0f0f8;
  }
}
.ant-slider:hover .ant-slider-track {
  background: $blue-secondary;
}

.filter_checkbox:checked + label {
  color: black;
}
.filter_checkbox + label {
  color: gray;
}
.filter_checkbox + label {
  cursor: pointer;
}
.filter_checkbox + label:hover {
  color: black;
}

.one_product_link > a:hover {
  text-decoration: none;
}

.filter_product_button {
  background: white;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  @include flexbox();
  @include align-items(center);
  padding: 10px 10px 10px 20px;
  outline: none !important;
}

.num_items {
  white-space: nowrap;
  font-size: 16px !important;
}
#panel-css .ant-collapse-header {
  color: #00468c;
  font-weight: bold;
}
.btn {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer !important;
  background: #ffffff !important;
  padding: 7px 10px !important;
  border: 1px solid #00468c !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  color: #00468c !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
  outline: none !important;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  border-radius: 5px !important;
}

.btn:hover {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer !important;
  background: #00468c !important;
  padding: 7px 10px !important;
  border: 1px solid #00468c !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  color: #ffffff !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
  outline: none !important;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  border-radius: 5px !important;
}
#tabs > .ant-tabs-nav > .ant-tabs-nav-wrap {
  background-color: #f8faff;
}
#tabs > .ant-tabs-nav {
  margin-bottom: 0px;
}


.faq_go_back{
  color: #182844;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #182844;
  align-items: center;
  padding: 10px;
  display: flex;
  border-radius: 8px;
  @media screen and (max-width: 900px) {
      .go_back_text{
        display: none;
      }
  }
}
.faqhomemobile{
  @media screen and (max-width: 900px) {
  display: none;
  }
}
.responsive_faq{
  @media screen and (max-width: 900px) {
    .web {
      display: none;
    }

  }
  @media screen and (min-width: 900px) {

    .mobile {
      display: none;
    }

  }
}

.home_question_faq{
    color: $blue-primary;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 25px;
  text-decoration: underline;
}

.title_card_faq{
  font-size: 16px;
  color:  $blue-primary;
  font-weight: bold;
  @media screen and (max-width: 900px) {
    font-size: 12px;
    text-align: center;
  }
}


.search-bar {
  background-color: #ffffff; /* Adjust background color as needed */
  border-radius: 10px;
  padding: 4px;
  display: flex;
  align-items: center;
  border: 1px solid #b4b4b4;
}


.company_name{
  font-size: 16px;
  font-family: $font;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.text_par_model{
  font-size: 16px ;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.input-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.search_input {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  font-size: 16px;
}

.submit_input {
  background-color: white; /* Adjust button color as needed */
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: none;
}
.drawerfaqmobile > .ant-drawer-content-wrapper{
 // top:26% !important;
}

.drawerfaqmobile > .ant-drawer-mask {
  background: transparent !important;
}
.headerContent{
  padding-top: 56px;
  font-size: 20px;
  color: #03468C;
  @media screen and (max-width: 900px) {
    padding-top: 6px !important;

  }
}
.faqheader{
  height: 200px;
  background-color: #F8FAFF;
  text-align: center;

  @media screen and (max-width: 900px) {
    height: 100px;
    background-color: #F8FAFF;
    text-align: center;
  }
}

div.scrollmenu {
  background-color: #ffffff;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: #1c1c1c;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color:#182844;
  color: white !important;
}

 .activeMobileFaq {
  background-color: #182844;
  color: white !important;
   border-bottom: 2px solid #c5c5c5;
}

 .filterfaq{
   border-right: 1px solid #E4E4E4;

   @media screen and (min-width: 900px) {
    width: 90%;
   }
   @media screen and (min-width: 1040px) {
     width: 65%;
   }
   @media screen and (min-width: 1440px) {
     width: 30%;
   }
 }

 .list-faq-category{

   grid-template-columns: 1fr 1fr 1fr 1fr;
   grid-gap: 2em;
   display: grid;
 }
 .card-faq{
   width: 300px;
   border: 1px solid #cecece;
 }
.row-responsible-choice{

  align-items: center;
  border: 1px solid #c0c0be;
  margin-bottom: 32px !important;
  padding: 25px !important;
}
.h1_reponsible_choice{
  font-size: 24px;
  font-weight: 700;
  font-family: $font;
  color:$blue-primary !important;

  @media  screen and (max-width: 786px){
    font-size: 16px;
  }
}
.product_description_reponsible {
  font-size: 16px;

  font-family: $font !important;
  @media  screen and (max-width: 786px){
    font-size: 12px !important;
  }
}
.img-responsible-choice{
  object-fit: fill;
  width: 250px;
  height: 300px;
}
@media  screen and (min-width: 2200px){
  .list-faq-category {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .card-faq{
    width: 350px !important;

  }
}
@media screen and (min-width: 1440px){
  .list-faq-category {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .card-faq{
    width: 300px !important;

  }
}
@media screen and (max-width: 1320px){
  .list-faq-category {
    grid-template-columns: 1fr 1fr 1fr ;
  }
  .card-faq{
    width: 300px !important;

  }
}
@media screen and (max-width: 1200px){
  .list-faq-category {
    grid-template-columns: 1fr 1fr 1fr  ;
  }
  .card-faq{
    width: 200px !important;

  }
}
@media screen and (max-width: 768px){
  .list-faq-category {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .card-faq{
    width: 150px !important;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

}
@media screen and (max-width: 480px) {
  .list-faq-category {
    grid-template-columns: 1fr 1fr ;
  }
  .card-faq{
    width: 150px !important;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.searchfaq >  .ant-input-group >  .ant-input-affix-wrapper:not(:last-child) {

  height: 60px !important
}
.searchfaq > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary){
  height: 60px !important;
  width: 60px !important;
}
/* .embed-responsive {
  position: sticky !important;
  top: 20px;
  display: block !important;
  //   width: 20% !important;
  // height: 70% !important;
  padding: 0;
  overflow: hidden;
}
 */
