@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.add_folder_container {
  width: 100vw;
  // height: calc(100vh - 109px) !important;
  background-color: white;
  position: absolute;
  bottom: 0px;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  z-index: 1000;
  @media screen and (max-width: 1099px) {
    height: calc(100vh - 105px) !important;
  }
  @media screen and (max-width: 768px) {
    height: calc(100vh - 87px) !important;
    padding: 25px 0;
    display: block;
  }

  svg {
    path {
      fill: $blue-primary;
    }
  }
  .ant-select-selector {
    padding: 0;
  }
  .alerts_position {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .css-yk16xz-control {
    border: none !important;
    border-bottom: 1px solid #ccc !important;
    .css-g1d714-ValueContainer {
      padding-left: 0 !important;
    }
  }
  .basic-multi-select{
    font-size: 16px!important;
    //font-weight: 300;
    @media screen and (max-width: 786px) {
      font-size: 16px!important;

    }
  }
  .tags_field {
    font-size: 20px;
    margin-bottom: 0;
    @media screen and (max-width: 575px) {
      padding: 12px 12px 15px !important;
    }
    .react-tagsinput {
      background-color: white;
      border-radius: 2px;
      border: none;
      // border-bottom: 1px solid #dcdbdc;
      color: black;
      display: inline-block;
      font-family: sans-serif;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px;
      margin-right: 5px;
      padding: 0;
      .react-tagsinput-tag {
        border-radius: 5px;
        border: 1px solid #d9e1f7;
        background-color: #f4f7ff;
        color: $blue-primary;
        padding: 5px 7px;
      }
      input {
        font-size: 1.2em;
        border: 0;
        border-bottom: 1px solid #ccc;
        font-family: inherit;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
        width: 100%;
      }
    }
  }
  .add_folder_top {
    text-align: center;
    svg {
      width: 60px;
      height: 61px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        width: 40px;
      }
    }
    & > h3 {
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .form_add_folder {
    width: 100%;
    display: flex;
    height: 260px;
    @media screen and (max-width: 768px) {
      height: 235px;
    }
    @media screen and (max-width: 575px) {
      height: 160px;
    }
  }
  .form_add_article {
    width: 100%;
    display: flex;
    height: 400px;
    @media screen and (max-width: 768px) {
      height: 235px;
    }
  }

  .form_add_folder_right,
  .form_add_folder_left {
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 575px) {
      padding: 12px;
    }
  }
  .form_add_folder_right {
    font-size: 20px;
    padding-bottom: 40px;
    #description {
      height: 138px;
      border: 1px solid #ccc;
      outline: none;
      padding: 5px 10px;
      font-size: 16px;
      resize: none;
    }
  }
  .form_add_article_left {
    .ant-select {
      width: 100% !important;
      // border: none;
      font-size: 1.2em;
      border: 0;
      border-bottom: 1px solid #ccc;
    }
    .ant-select-open {
      outline-style: none !important;
    }
    .tags_field {
      font-size: 20px;
      margin-bottom: 0;
      @media screen and (max-width: 575px) {
        padding: 12px 12px 15px;
      }
      .react-tagsinput {
        background-color: white;
        border-radius: 2px;
        border: none;
        // border-bottom: 1px solid #dcdbdc;
        color: black;
        display: inline-block;
        font-family: sans-serif;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 0;
        .react-tagsinput-tag {
          border-radius: 5px;
          border: 1px solid #d9e1f7;
          background-color: #f4f7ff;
          color: $blue-primary;
          padding: 5px 7px;
        }
        input {
          font-size: 1.2em;
          border: 0;
          border-bottom: 1px solid #ccc;
          font-family: inherit;
          -webkit-appearance: none;
          border-radius: 0;
          padding: 0;
          cursor: text;
          width: 100%;
        }
      }
    }
  }
  .buttons {
    align-self: flex-end;
    // padding-right: 30px;
    .submit_button_white_confirm {
      background-color: $blue_primary;
      color: white;
    }
    .submit_button_white_confirm,
    .submit_button_white_discard {
      padding-right: 90px;
      padding-left: 90px;
      margin: 0 30px;
      @media screen and (max-width: 768px) {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
  }
  .buttons_form {
    text-align: center;
    @media screen and (max-width: 768px) {
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100%;
      max-width: 100%;
    }
    button {
      @media screen and (max-width: 768px) {
        width: 50%;
        margin-left: 0 !important;
        margin-right: 0 !important  ;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
      }
      @media screen and (min-width: 1200px) {
        // width: 50%;
        margin-left: 10px !important;
        margin-right: 10px !important  ;
        padding: 20px 30px;
        margin: 0 30px;
      }
    }
  }
  .alert_danger {
    background-color: rgba(255, 91, 91, 0.2);
  }
  // .field_article {
  //   margin-bottom: 80px;
  // }
}
// .react-tagsinput-tag {
//   input {
//     font-size: 1.5em;
//     border: 0;
//     border-bottom: 1px solid #ccc;
//     font-family: inherit;
//     -webkit-appearance: none;
//     border-radius: 0;
//     padding: 0;
//     cursor: text;
//   }

//   input:focus {
//     outline: 0;
//     border-bottom: 1px solid #666;
//   }

//   label {
//     letter-spacing: 0.05em;
//   }
//   input:placeholder-shown + label {
//     cursor: text;
//     max-width: 66.66%;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     transform-origin: left bottom;
//     transform: translate(0, 1.5rem) scale(1.5);
//   }
//   ::-webkit-input-placeholder {
//     opacity: 0;
//     transition: inherit;
//   }
//   input:focus::-webkit-input-placeholder {
//     opacity: 1;
//   }
//   input:not(:placeholder-shown) + label,
//   input:focus + label {
//     transform: translate(0, 0) scale(1);
//     cursor: pointer;
//   }
// }

.add_order_drawer_subtitle {
  font-size: 16px;
  font-family: $font;
  color: $dark-gray;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.field_article > textarea {
  border: 1px solid #ccc;
  outline: none;
  resize: none;
}
.textarea_new_order {
  font-size: 16px;
  font-family: $font;
  color: black;
}

.add_order_container {
  @media screen and (max-width: 1099px) {
    height: 100vh !important;
  }
  @media screen and (max-width: 768px) {
    height: 100vh !important;
    padding: 25px 0;
    display: block;
  }
}

#datetime > .ant-picker-focused {
  box-shadow: none;
  -webkit-box-shadow: "none";
  border-color: "none";
}
