@import "./variables.scss";
@import "./mixins.scss";

.my_orders {
  padding: 109px 30px 0 30px;
  background: $whitish;
  min-height: 100vh;
  & > .container {
    @media (min-width: 1200px) {
      max-width: 1300px;
    }
  }
}

.my_orders_header_btns {
  .active,
  .--selected {
    border-color: $blue-primary !important;
    color: $blue-primary !important;
    border-left: 1px solid $blue-primary !important;
    border-right: 1px solid $blue-primary !important;
    background-color: #a5ab36 !important;
  }
  & > .ant-btn-ghost {
    color: gray;
    font-family: $font;
    height: 50px;
    width: 280px;
    font-weight: 500;
    @media (max-width: 1031px) {
      width: 200px;
      font-size: 12px !important;
    }
    @media (max-width: 768px) {
      width: 120px;
      font-size: 12px !important;
    }
    @media (max-width: 527px) {
      width: 80px;
      padding: 5px;
      font-size: 12px !important;
    }
    @media (max-width: 360px) {
      width: auto;
      padding: 2px;
      font-size: 12px !important;
    }
  }
  & > .ant-btn-ghost:focus {
    color: gray;
    border-color: $blue-primary !important;
  }
  & > .ant-btn-ghost:hover {
    color: gray;
    border-color: $light-gray !important;
  }
  & > .active:hover {
    color: $blue-primary !important;
    border-color: $blue-primary !important;
  }
  & > .ant-btn-ghost:first-child {
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  & > .ant-btn-ghost:last-child {
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
}

.my_orders_header {
  @include flexbox();
  @include justify-content(space-between);
  margin-top: 20px;

  .ant-picker {
    border-color: $light-gray;
    border-radius: 5px;
    padding: 15px;
  }
  .ant-picker-focused {
    border-color: $blue-primary;
  }
  .ant-picker-cell-inner {
    background: $blue-primary !important;
  }

  .my_orders_header_left {
    /*    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);*/
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;*/

    & > h1 {
      font-size: 32px;
      font-family: $font;
      color: $blue-primary;
      text-transform: capitalize;
      font-weight: 700;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 16px !important;
      }
    }

    & > .my_orders_header_btns {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }
}
.my_orders_header_type {
  & > .my_orders_header_type_btns {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
}
.my_orders_header_type_btns {
  .active,
  .--selected {
    border-color: $blue-primary !important;
    color: $blue-primary !important;
    border-left: 1px solid $blue-primary !important;
    border-right: 1px solid $blue-primary !important;
    background-color: #a5ab36 !important;
  }
  & > .ant-btn-ghost {
    color: gray;
    font-family: $font;
    height: 50px;
    width: 280px;
    font-weight: 500;

    @media (max-width: 768px) {
      min-width: 120px;
      font-size: 12px !important;
    }
    /*    @media (max-width: 527px) {
      min-width: 120px;
      padding: 5px;
      font-size: 12px !important;
    }
    @media (max-width: 360px) {
      min-width: 120px;
      padding:2px;
      font-size: 12px !important;
    }*/
  }
  & > .ant-btn-ghost:focus {
    color: gray;
    border-color: $blue-primary !important;
  }
  & > .ant-btn-ghost:hover {
    color: gray !important;
    border-color: $light-gray !important;
    background-color: transparent!important;
    box-shadow: 0px 5px 10px rgba(black, 0.1);
    transition: all 0.3s ease-in-out !important;

  }
  & > .active:hover {
    color: $blue-primary !important;
    border-color: $blue-primary !important;

  }
  & > .ant-btn-ghost:first-child {
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  & > .ant-btn-ghost:last-child {
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
}
.title_myorder {
  font-size: 32px !important;
  font-weight: bold;
  text-transform: capitalize;
  color: $blue-primary;
  font-family: $font;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 20px !important;
  }
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $blue-primary !important;
}
.ant-picker-today-btn {
  color: $blue-primary;
}
.ant-picker-clear > span > svg,
.ant-picker-suffix > span > svg {
  fill: $blue-primary !important;
}
.table-direct-order {
  font-size: 16px !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0 20px;
  font-family: $font;
}

.table > tbody > tr > td > a > .ant-btn-ghost {
  border-color: $blue-primary !important;
  color: $blue-primary !important;
  border-radius: 5px;
  height: 50px !important;
  transition: all 0.2s ease-in-out;
}
.table > tbody > tr > td > a > .ant-btn-ghost:hover {
  border-color: $blue-primary !important;
  background: $blue-primary !important;
  color: white !important;
}
.table > thead > tr > th {
  border: none;
  font-weight: 400;
}

.table > tbody > tr > td {
  border-bottom: 1px solid $light-gray;
  background: white;
}
.table > tbody > tr > td:first-child {
  border-left: 1px solid $light-gray;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.table > tbody > tr > td:last-child {
  border-right: 1px solid $light-gray;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.table > tbody > tr > td {
  vertical-align: middle;
}

.table > tbody > tr {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}

.table > tbody > tr:hover {
  transform: translateY(-3px);
  box-shadow: 0px 2px 10px rgba(black, 0.1);
  //  background-color: red;
}
.myorder_order_name {
  font-weight: bold;
  padding-left: 20px;
  @media (max-width: 768px) {
    padding-left: 10px;
  }
}

.status_msg {
  font-weight: 400;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.status_btn_order {
  border-radius: 5px !important;
  font-weight: 600;
  color: $blue-primary;
  border: none;
  width: 50% !important;
  min-width: fit-content !important;
  height: 50px !important;

  @media (max-width: 768px) {
    height: 30px !important;
    font-size: 12px;
  }
}
.status_btn_order:hover {
  border: none;
  color: $blue-primary;
}

.canceled_article_btn {
  border-radius: 5px !important;
  margin-right: 10px !important;
  height: 45px !important;
  font-family: $font;

  & > span {
    vertical-align: 0 !important;
  }
  & > span > svg {
    color: $blue-primary;
  }

  &:hover,
  &:focus {
    border-color: $blue-primary !important;
    color: $blue-primary !important;
  }
}

.my_orders_header_right {
  & > span {
    text-decoration: underline;
    font-size: 14px;
    color: $blue-primary;
    font-weight: 300;
    cursor: pointer;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $blue-primary !important;
}

.collab_list_myorders {
  white-space: nowrap;
}

.profile_direct_order {
  @media (max-width: 768px) {
    width: 40px !important;
    height: 40px !important;
  }
}

.see_more_order_details_btn {
  @media (max-width: 768px) {
    width: 100px !important;
  }
}

.my_orders_first_cell {
  & > svg {
    min-width: 24px;
  }
}

.date_my_orders > .ant-picker-input > input {
  @media (max-width: 768px) {
    display: none;
  }
}
.date_my_orders {
  @media (max-width: 768px) {
    height: 40px !important;
  }
}
.my_orders_header_btns > button {
  @media (max-width: 768px) {
    font-size: 12px;
    height: 40px !important;
  }
}
