@import "../sass/mixins.scss";
@import "../sass/variables.scss";
.my_collection_container {
  height: 100vh !important;
  // width: 450px !important;
  // overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 30px;
  width: 100%;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  overflow: hidden !important;
  @media screen and (max-width: 1099px) {
    padding: 90px;
  }
  @media screen and (max-width: 768px) {
    padding: 88px;
  }
  .collections_contents {
    @include flexbox();
    // width: calc(100vw - 450px);
    position: relative;
    // @media screen and (max-width: 1366px) {
    //   width: calc(100vw - 420px);
    // }
    // & > .spinner {
    //   width: calc(100vw - 450px);
    // }
  }
  .height {
    height: 120vh !important;
  }

  .right_side_folders_products_reduced {
    width: 410px !important;
    border-right: 1px solid #ccc !important;
    @media screen and (max-width: 1919px) {
      width: 360px !important;
    }
    .product_image_container {
      height: 50px;
      width: 50px;
      @include flexbox();
      @include align-items(center);
      .img_product_folder {
        width: 48px;
      }
    }
    .one_folder {
      width: 343px;
      @media screen and (max-width: 1919px) {
        width: 290px;
      }
    }
  }
  .right_side_folders_products {
    position: relative;
    padding: 12px 14px !important;
    width: 100% !important;
    max-height: 87.1vh;
    @media screen and (max-width: 768px) {
      max-height: 89.3vh;
    }
    .list_folders_products {
      overflow-y: scroll;
      height: 82vh;
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: rgb(161, 161, 161);
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }

      .list_sub_folders {
        h6 {
          color: $blue-primary;
          font-size: 18px;
          font-weight: 200;
          margin: 15px 0;
        }
        .one_folder {
          margin-right: 0;
        }
      }
      .list_folders_collection {
        display: grid;
        grid-template-columns: 1fr;

        @media (min-width: 992px) {
          grid-template-columns: 1fr 1fr;
        }
        @media (min-width: 1200px) {
          grid-template-columns: 1fr 1fr 1fr;
        }
        @media (min-width: 1400px) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        @media (min-width: 1700px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
        @media (min-width: 2200px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        gap: 10px;
        & > div {
          padding: 0px !important;
        }

        & > div > div > div {
          width: 100% !important;
          margin: 0px !important;
        }
      }
      // .list_products {
      //   display: flex;
      //   flex-wrap: wrap;
      //   .product_card {
      //     display: flex;
      //     flex-direction: column;
      //   }
      // }
      .list_products_header {
        padding-bottom: 20px;
        h6 {
          color: $blue-primary;
          font-size: 18px;
          font-weight: 700;
          margin: 15px 0;
        }
        .product_card {
          display: flex;
          flex-direction: column;
        }
      }
    }
    @media screen and (max-width: 1366px) {
      width: calc(100vw - 420px);
    }
    @media screen and (max-width: 768px) {
      width: calc(100vw - 400px);
    }
    @media screen and (max-width: 575px) {
      width: 100vw;
    }
    .folder_header {
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      height: 64px;
      .folder_name_header {
        font-size: 30px !important;
        font-weight: 200 !important;
        margin-bottom: 0 !important;
        cursor: pointer;
        svg {
          margin-left: 10px;
          color: $blue-primary;
          font-size: 20px;
          cursor: pointer;
        }
      }
      .folder_name_container {
        .folder_name_header_container {
          .folder_name_header {
            font-size: 30px !important;
            font-weight: 200 !important;
            margin-bottom: 0 !important;
            cursor: pointer;
            svg {
              margin-left: 10px;
              color: $blue-primary;
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
        svg {
          margin-right: 10px;
        }
      }

      .folder_functionnalities {
        @include flexbox();
        @include align-items(center);
        .folder_function {
          margin: 0 5px;
          height: 52px;
          // width: 160px;
          padding: 0 30px;
          border-radius: 6px;
          border: 1px solid #ccc;
          font-size: 18px;
          font-weight: 500;
          color: $dark-gray;
          cursor: pointer;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          transition: all 0.2s;
          &:hover {
            -webkit-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
            box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
          }
          p {
            margin-bottom: 0;
            margin-left: 8px;
          }
          svg {
            width: 26px;
            height: 24px;
          }
        }
      }
    }
  }
  .my_collection_folders {
    padding: 15px;
    padding-bottom: 20px;
    // overflow-y: scroll;
    background-color: #f4f7ff;
    height: calc(100vh - 109px);
    min-width: 350px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-direction(column);

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    @media screen and (max-width: 1366px) {
      min-width: 350px;
    }
    // @media screen and (max-width: 1024px) {
    //   min-width: 400px;
    // }
    @media screen and (max-width: 1024px) {
      min-width: 300px;
    }
    @media screen and (max-width: 768px) {
      height: calc(100vh - 89px);
    }
    @media screen and (max-width: 575px) {
      min-width: 100%;
      height: calc(100vh - 87px);
    }

    .my_collection_folders_header {
      width: 100%;
      @media screen and (max-width: 575px) {
        width: 90%;
      }
      .submit_button_blue_add_folder {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        svg {
          height: 24px;
          width: 24px !important;
        }
      }
      .my_collection_header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        // @media screen and (max-width: 575px) {
        //   width: 90%;
        // }
        p,
        h2 {
          margin-bottom: 0;
        }
        margin-bottom: 12px;
      }
      .collection_header_title {
        font-size: 20px;
        color: $blue-primary;
      }
    }

    .my_collection2_folders_header {
      width: 100%;
      @media screen and (max-width: 575px) {
        width: 90%;
      }
      .submit_button_blue_add_folder {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        svg {
          height: 24px;
          width: 24px !important;
        }
      }
      .my_collection2_header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        // @media screen and (max-width: 575px) {
        //   width: 90%;
        // }
        p,
        h2 {
          margin-bottom: 0;
        }
        margin-bottom: 12px;
      }
      .collection2_header_title {
        font-size: 20px;
        color: $blue-primary;
      }
    }
    .collection_list_folders {
      @include flexbox();
      // @include align-items(center);
      @include justify-content(flex-start);
      @include flex-direction(column);
      // padding-top: 20px;
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      /* width */
      // &::-webkit-scrollbar {
      //   width: 5px;
      // }
      height: 500px;
      @media screen and (max-width: 575px) {
        width: 90%;
      }
    }
    .delete_folder_container {
      @media screen and (max-width: 575px) {
        width: 90%;
        margin: 0 auto;
      }
    }
    overflow-y: hidden;
    .delete_folder {
      height: 90px;
      padding: 10px;
      width: 100%;
      border: 2px solid #c8d4f0;
      border-radius: 8px;
      background-color: white;
      border-style: dashed;
      margin: 10px auto;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      @include flex-direction(column);
      // @media screen and (max-width: 1024px) {
      //   width: 300px;
      // }
      // @media screen and (max-width: 575px) {
      //   width: 100%;
      // }
      p {
        font-size: 12px;
        color: $dark-gray;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }
    .delete_hovered {
      border: 2px solid #ff0045;
      background-color: #f7dfec;
      color: #ff0045 !important;
      border-style: dashed;
    }
  }
.folder_my_selection_mobile{
  @media screen and (max-width: 575px) {
      padding: 2px !important;
      height: 70px;
    display: flex;
   justify-content: center;
      .folder_miniature_header > .folder_name{
        font-size: 16px !important;
      }
    .folder_miniature_description{
      display: none;
    }
    .mycollection_folder_icon{
    display: none;
  }
  }

}
  .lockfld{
    font-size: 20px;
    color: $blue-primary;
    @media screen and (max-width: 867px) {
      font-size: 18px !important;
    }
  }
#sample_3d > span{
  font-size: 16px !important;
  @media screen and (max-width: 867px) {
    font-size: 12px !important;
  }
}
.request_access_btn{
  font-size: 16px;
  -webkit-border-radius:8px;
  background-color: $blue-primary;
  color: white;
  padding: 10px 25px 10px 25px;
  @media screen and (max-width: 867px) {
    font-size: 12px;
  }

}
  .single_folder{

    @media screen and (max-width: 576px) {
     padding: 0px;
      height: 50px !important;
      .order_miniature_info  .order_miniature_name > svg {
        display: none !important;
      }
      .order_miniature_info .order_miniature_name > h2 {
        font-size: 12px !important;
        font-weight: 200!important;
      }

      .season_position{
        display: none !important;
      }
      .collab_list {
        display: none !important;
      }
    }

  }
  .my_collection2_folders {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 10px;
    padding-left: 10px;
    // overflow-y: scroll;
    background-color: #f4f7ff;
    height: calc(100vh - 109px);
    min-width: 350px;
    @include flexbox();
    @include align-items(center);
    /*     @include justify-content(space-between);
 */
    @include flex-direction(column);

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    @media screen and (max-width: 1366px) {
      min-width: 350px;
    }
    // @media screen and (max-width: 1024px) {
    //   min-width: 400px;
    // }
    @media screen and (max-width: 1024px) {
      min-width: 300px;
    }
    @media screen and (max-width: 768px) {
      height: calc(100vh - 89px);
    }
    @media screen and (max-width: 575px) {
    //  min-width: 100%;
      min-width: 150px;
      height: calc(100vh - 87px);
    }

    .my_collection2_folders_header {
      width: 100%;
      @media screen and (max-width: 575px) {
        width: 90%;
      }
      .submit_button_blue_add_folder2 {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        @media screen and (max-width: 575px) {
          font-size: 12px;
          justify-content: center;
          width: 100%;
          span {
           display: none;

          }
        }
        svg {
          height: 24px;
          width: 24px !important;
        }
      }
      .my_collection2_header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        // @media screen and (max-width: 575px) {
        //   width: 90%;
        // }
        p,
        h2 {
          margin-bottom: 0;
        }
        margin-bottom: 12px;
      }
      .collection2_header_title {
        font-size: 20px;
        color: $blue-primary;
      }
    }
    .collection2_list_folders {
      @include flexbox();
      // @include align-items(center);
      @include justify-content(flex-start);
      @include flex-direction(column);
      // padding-top: 20px;
      overflow-y: scroll;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      /* width */
      // &::-webkit-scrollbar {
      //   width: 5px;
      // }
      height: 500px;
      @media screen and (max-width: 575px) {
        width: 90%;
      }
    }
    .delete2_folder_container {
      @media screen and (max-width: 575px) {
        width: 90%;
        margin: 0 auto;
      }
    }
    overflow-y: hidden;
    .delete2_folder {
      height: 90px;
      padding: 10px;
      width: 100%;
      border: 2px solid #c8d4f0;
      border-radius: 8px;
      background-color: white;
      border-style: dashed;
      margin: 10px auto;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
      @include flex-direction(column);
      // @media screen and (max-width: 1024px) {
      //   width: 300px;
      // }
      // @media screen and (max-width: 575px) {
      //   width: 100%;
      // }
      p {
        font-size: 12px;
        color: $dark-gray;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }
    .delete2_hovered {
      border: 2px solid #ff0045;
      background-color: #f7dfec;
      color: #ff0045 !important;
      border-style: dashed;
    }
  }
  .empty_collection {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
    @include justify-content(center);
    height: 100%;
    @media screen and (max-width: 1024px) {
    }
    img {
      height: 300px;
      @media screen and (max-width: 1400px) {
        height: 200px;
      }
      @media screen and (max-width: 1024px) {
        height: 200px;
      }
      @media screen and (max-width: 575px) {
        height: 120px;
      }
    }
    .collection_welcome {
      text-align: center;
      @media screen and (max-width: 1400px) {
        font-size: 32px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 22px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    .submit_button_blue_add_folder {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 450px;
      padding: 18px 25px;
      svg {
        height: 24px;
        width: 24px !important;
      }
    }
    .collection_welcome_description {
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
    .folders_image{
      @media screen and (max-width: 1024px) {
        width: 180px;
      }
    }
  }
  .folder_content_container {
    background-color: white;
    overflow-y: scroll;
    height: calc(100vh - 162px);
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.collection_name_selected{
  font-size: 32px !important;
  color: $blue-primary!important;

  @media screen and (max-width: 768px) {
    font-size: 20px!important;
  }
}
.grid-container > div {
  width: auto;
}
.grid-container-collection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  @media (min-width: 360px) {
    grid-template-columns: 1fr  ;
  }
  @media (min-width: 800px) {
    grid-template-columns: 1fr  1fr   ;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr  ;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 1700px) {
    grid-template-columns: 1fr 1fr 1fr 1fr ;
  }
  @media (min-width: 2200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr ;
  }

}
.launch_collection{
  font-size: 16px;
  border: 1px solid $blue-primary;
  color: $blue-primary;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 5px !important;
    .icon_send{
      padding: 8px !important;


    }
    .text_button{
      display: none;
    }
  }
}
.view_deleted_items {
  margin-top: 12px;
  width: 100%;
  margin: 10px auto;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  padding: 0 36px;
  @media screen and (max-width: 1366px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  p {
    cursor: pointer;
    color: $blue-primary;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.sub_right_side_folders_products {
  position: relative;
  padding: 12px 28px !important;
  padding-right: 0 !important;
  width: 71%;
  .back_arrow_folder {
    position: absolute;
    top: 18px;
    left: -23px;
    cursor: pointer;
  }
  @media screen and (max-width: 1919px) {
    width: 55% !important;
  }
  @media screen and (max-width: 1820px) {
    width: 50% !important;
  }
  @media screen and (max-width: 1366px) {
    width: 42% !important;
  }
  .one_folder {
    width: 343px;
    @media screen and (max-width: 1366px) {
      width: 290px;
      .product_image_container {
        height: 50px;
        width: 50px;
        @include flexbox();
        @include align-items(center);
        .img_product_folder {
          width: 48px;
        }
      }
    }
  }
}

.collection_content_container {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  position: relative;

  .back_arrow_folder {
    position: absolute;
    top: 18px;
    left: -23px;
    cursor: pointer;
  }
  .alerts_position {
    z-index: 10;
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-30%);
  }
}

.right_side_folders_products_individual {
  .folder_header {
    .folder_name_header_container {
      margin-left: 14px;
    }
    .folder_functionnalities {
      .folder_function {
        padding: 0 15px !important;
      }
    }
  }
}
.right_side_folders_products_mobile {
  .folder_header {
    .folder_name_header_container {
      margin-left: 5px;
    }
    .folder_functionnalities {
      .folder_function {
        padding: 0 15px !important;
        @media screen and (max-width: 575px) {
          padding: 0 10px !important;
        }
      }
    }
  }
  .one_folder {
    .product_image_container {
      height: 56px;
      width: 56px;
      @include flexbox();
      @include align-items(center);
      .img_product_folder {
        width: 54px;
      }
    }
  }
}
