//colors
$white: #ffffff;
$whitish: #fcfdff;
//$blue-primary: #00468c;
$blue-primary: #182844;
$blue-secondary: #7a9ec5;
$blue-sky: #e5f1fa;
$blue-sky-light: #f4f7ff;
$black: #000000;
$dark-gray: #7c7c7c;
$light-gray: #e0e0e0;

//spacing
$padding-xl: 6rem;
$padding-l: 4rem;
$padding-m: 3rem;
$padding-sm: 2rem;
$padding-s: 1.5rem;
$padding-xs: 0.8rem;

// fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$font: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
// Big Titles
$huge-h1-font-xl: 4.6rem;
$huge-h1-font-l: 4.2rem;
$huge-h1-font-m: 4rem;
$huge-h1-font-sm: 3.9rem;
$huge-h1-font-s: 3.2rem;
$huge-h1-font-xs: 2.6rem;

$huge-h2-font-xl: 2.7rem;
$huge-h2-font-l: 2.3rem;
$huge-h2-font-m: 1.9rem;
$huge-h2-font-sm: 1.5rem;
$huge-h2-font-s: 1.1rem;
$huge-h2-font-xs: 0.8rem;

// standard titles
$h1-font-xl: 3.125rem;
$h1-font-l: 2.7rem;
$h1-font-m: 2.3rem;
$h1-font-sm: 1.9rem;
$h1-font-s: 1.5rem;
$h1-font-xs: 1.1rem;

$h2-font-xl: 2.5rem;
$h2-font-l: 2.3rem;
$h2-font-m: 1.9rem;
$h2-font-sm: 1.7rem;
$h2-font-s: 1.3rem;
$h2-font-xs: 1.1rem;

// title font weight
$h1_font_weight_light: 400;
$h1_font_weight: bold;
