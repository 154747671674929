@import "./mixins.scss";
@import "./variables.scss";

.myorder_confirmation {
  padding-top: 109px !important;
  // height: 100vh !important;
  //   hide scroll bar
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto !important;
    overflow: unset;
    padding-top: 88px !important;
  }

  & > div > .proto_img {
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
  }
}
.proto_container {
  background: $whitish;
  // height: calc(100vh - 109px);
  // overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: $blue-secondary;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  @media (max-width: 768px) {
    height: calc(100vh - 88px);
  }
}
.proto_header {
  @include flexbox();
  @include align-items(center);
  & > h1 {
    font-size: 20px;
    font-family: $font;
    color: $blue-primary;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    margin-left: 15px;
  }
}

.proto_breadcrumb_container {
  @include flexbox();
  @include flex-direction(column);
  font-family: $font;
  margin: 20px;

  .proto_breadcrumb {
    color: $blue-secondary;
    font-size: 16px;
    font-weight: 400;
  }

  .proto_msg_welcome {
    color: $dark-gray;
    font-weight: 500;
    font-size: 16px ;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
.ant-steps-item-description{
  font-size: 16px !important;
  color: $blue-primary !important;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.ant-steps-item-icon > .ant-steps-icon > .anticon {
  vertical-align: 0 !important;
}
.ant-steps-item-description {
  color: #00468c !important;
}
.ant-steps-item-finish:hover
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  text-decoration: underline;
}
.ant-steps-item-active:hover
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  text-decoration: underline;
}

.cancelled .ant-steps-item > .ant-steps-item-container {
  & > .ant-steps-item-content {
    & > .ant-steps-item-title {
      margin-right: 10px;
      color: red !important;
      font-family: $font;
      font-weight: 400;
      font-size: 16px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  & > .ant-steps-item-tail {
    &::after {
      background-color: red !important;
      height: 2px;
    }
  }

  & > .ant-steps-item-icon {
    border-width: 2px;
    border-color: red;
    width: 50px;
    height: 50px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: white !important;

    & > .ant-steps-icon {
      font-family: $font;
      font-size: 18px;
      font-weight: 600;
      color: red !important;
    }
  }
}

// IN PROGRESS STEP
.ant-steps-item-active > .ant-steps-item-container {
  & > .ant-steps-item-content {
    & > .ant-steps-item-title {
      margin-right: 10px;
      color: $blue-primary !important;
      font-family: $font;
      font-weight: 400;
      font-size: 16px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  & > .ant-steps-item-tail {
    &::after {
      background-color: #7D7E72 !important;
      height: 2px;
    }
  }
  & > .ant-steps-item-icon {
    border-width: 2px;
    border-color: $blue-primary;
    width: 50px;
    height: 50px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: white !important;

    & > .ant-steps-icon {
      font-family: $font;
      font-size: 18px;
      font-weight: 600;
      color: $blue-primary !important;
    }
  }
}

// WAITING STEP
.ant-steps-item-wait > .ant-steps-item-container {
  & > .ant-steps-item-content {
    & > .ant-steps-item-title {
      margin-right: 10px;
      color: #7D7E72 !important;
      font-family: $font;
      font-weight: 400;
      font-size: 16px;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  & > .ant-steps-item-tail {
    &::after {
      background-color:#7D7E72 !important;
      height: 2px;
    }
  }
  & > .ant-steps-item-icon {
    border-width: 2px;
    border-color: #7D7E72;
    width: 50px;
    height: 50px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: white !important;

    & > .ant-steps-icon {
      font-family: $font;
      font-size: 18px;
      font-weight: 600;
      color: #7D7E72 !important;
    }
  }
}

// CANCELLED STEP
.cancelled .ant-steps-item-finish > .ant-steps-item-container {
  & > .ant-steps-item-content {
    & > .ant-steps-item-title {
      margin-right: 10px;
      color: red !important;
      font-family: $font;
      font-weight: 600;
      font-size: 16px;
    }
  }
  & > .ant-steps-item-tail {
    &::after {
      background-color: red !important;
      height: 2px;
    }
  }

  & > .ant-steps-item-icon {
    border-width: 2px;
    border-color: red;
    width: 50px;
    height: 50px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: white !important;

    & > .ant-steps-icon {
      font-family: $font;
      font-size: 18px;
      font-weight: 600;
      color: red !important;
    }
  }
}

.current .ant-steps-item-finish > .ant-steps-item-container {
  & > .ant-steps-item-content {
    & > .ant-steps-item-title {
      margin-right: 10px;
      color: red !important;
      font-family: $font;
      font-weight: 600;
      font-size: 16px;
    }
  }
  & > .ant-steps-item-tail {
    &::after {
      background-color: red !important;
      height: 2px;
    }
  }

  & > .ant-steps-item-icon {
    border-width: 2px;
    border-color: red;
    width: 50px;
    height: 50px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: white !important;

    & > .ant-steps-icon {
      font-family: $font;
      font-size: 18px;
      font-weight: 600;
      color: red !important;
    }
  }
}
// FINISHED STEP
.ant-steps-item-finish > .ant-steps-item-container {
  & > .ant-steps-item-content {
    & > .ant-steps-item-title {
      margin-right: 10px;
      color: #A5AA35 !important;

      font-family: $font;
      font-weight: 600;
      font-size: 16px;
    }
  }
  & > .ant-steps-item-tail {
    &::after {
      background-color: #A5AA35 !important;
      height: 2px;
    }
  }

  & > .ant-steps-item-icon {
    border-width: 2px;
    border-color: #A5AA35;
    width: 50px;
    height: 50px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    background-color: white !important;

    & > .ant-steps-icon {
      font-family: $font;
      font-size: 18px;
      font-weight: 600;
      color: #A5AA35 !important;
    }
  }
}
.ant-steps-item-tail {
  top: 21px !important;
  left: 9px !important;
}

.ant-steps-item-content {
  width: 143px !important;
}

.proto_footer {
  padding: 20px;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
  & > div {
    & > h1 {
      color: $blue-primary;
      font-family: $font;
      font-size: 28px;
      font-weight: 700;
    }
    & > p {
      font-family: $font;
      font-size: 18px;
      color: $dark-gray;
    }
  }
}

.comment_proto_order {
  resize: none;
  border-radius: 5px;
  border: 1px solid $light-gray;
  width: 100%;
  outline: none;
  padding: 10px;
  font-family: $font;
}

.proto_footer_btns {
  @include flexbox();
  @include justify-content(flex-end);
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
  .cancel_btn {
    border-radius: 5px;
    color: $blue-primary;
    background: white;
    border-color: $light-gray;
    height: 45px;
    width: 200px;
    font-family: $font;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  .approve_btn {
    border-radius: 5px;
    background: $blue-primary;
    border-color: $blue-primary;
    color: white;
    height: 45px;
    width: 200px;
    font-family: $font;
    font-size: 16px;
    margin-left: 20px;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  .cancel_btn:hover,
  .approve_btn:hover {
    border-color: $blue-primary;
  }
}

.cancel_drawer > .ant-drawer-content-wrapper {
  height: calc(100vh - 109px) !important;
  box-shadow: none !important;
  .ant-drawer-content .ant-drawer-content .ant-drawer-body {
    text-align: center;
  }
  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body > div {
    text-align: center;
  }

  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body > div > h1 {
    font-family: $font !important;
    font-weight: 500 !important;
    font-size: 45px !important;
  }
  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
  }
}

.cancel_drawer > .ant-drawer-content-wrapper {
  @media (max-width: 768px) {
    height: calc(100vh - 88px) !important;
  }
}

.cancel_options_container {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  margin: 70px 0 !important;
}

.cancel_other_input {
  border: 1px solid $light-gray;
  outline: none;
  margin-left: 15px;
  border-radius: 5px;
  padding: 5px;
  width: 200px;
}

.cancel_drawer_container {
  padding: 20px;
  & > div {
    font-family: $font;
    & > h1 {
      font-size: 45px;
      color: black;
    }
    & > p {
      font-size: 18px;
      color: $dark-gray;
    }
  }
}

@media (max-width: 550px) {
  .cancel_drawer_btns {
    & > button {
      height: 70px !important;
      width: 150px !important;
    }
  }
}

.ant-comment-inner {
  @include align-items(center);

  .ant-comment-content {
    @include flexbox();
    @include align-items(basline);
  }
}

.final_order_select > .ant-select > .ant-select-selector,
.option_details_input {
  border: 1px solid #cececea9;
  border-radius: 5px !important;
  font-size: 20px;
  padding: 10px;
  height: auto;
  font-family: $font;
  outline: none;
  width: 100%;
  height: 50px !important;
  @include align-items(center);
  align-items: center;
  &:hover {
    border: 1px solid #cececea9;
  }
}

.option_details_search {
  border: 1px solid #cececea9;
  // border-radius: 5px !important;
  font-size: 20px;
  padding: 10px;
  height: auto;
  font-family: $font;
  outline: none;
  width: 100%;
  height: 40px !important;
  @include align-items(center);
  align-items: center;
  &:hover {
    border: 1px solid #cececea9;
  }
}
.final_order_select > .ant-select > .ant-select-arrow {
  font-size: 25px;
  top: 50%;
  right: 20px;
  transform: translateY(-80%);
  color: $blue-primary;
}
.final_order_select {
  @include flexbox();
  @include align-items(center);

  & > label {
    white-space: nowrap;
    color: $dark-gray;
    font-family: $font;
  }
}

.not_approved_drawer {
  font-family: $font;
  & > div > h1 {
    font-size: 45px;
  }
  & > div > p {
    color: $dark-gray;
    font-weight: 400;
  }
}

.table_production {
  font-family: $font;
}
