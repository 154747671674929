@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.direct_order_list {
  padding-bottom: 50px;
  background-color: $blue-sky-light;
  height: calc(100vh - 109px);
  // min-width: 400px;
  @include flexbox();
  @include align-items(center);
  //   @include justify-content(space-between);
  @include flex-direction(column);

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgb(161, 161, 161);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 87px);
    padding: 30px !important;
  }
  @media (min-width: 540px) and (max-width: 770px) {
    min-width: 300px;
    padding: 15px !important;
  }
  @media (min-width: 769px) {
    min-width: 400px;
    padding: 1.5rem 3rem 3rem 3rem !important;
  }
}
.direct_order_list_header_wrapper {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-direction(column);
  width: 100%;

  & > a {
    width: 100%;
    &:hover {
      text-decoration: none;
    }
  }

  & > .submit_button_blue {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;

    @media (min-width: 540px) and (max-width: 768px) {
      font-size: 14px;
    }
  }

  & > .direct_order_list_header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    width: 100%;
  }
  & > .direct_order_list_header > .direct_order_header_icons {
    @include flexbox();
    @include align-items(center);
  }
  & > .direct_order_list_header > .direct_order_header_icons > svg:first-child {
    margin-right: 20px;
  }
  & > .direct_order_list_header > .direct_order_header_icons > img:first-child {
    margin-right: 20px;
    cursor: pointer;
  }
}
.direct_order_list_header > .direct_order_list_title {
  text-transform: uppercase;
  color: $blue-primary;
  font-family: $font;
  font-weight: 600;
  font-size: 20px;
}

.direct_order_header_icon {
  color: $blue-primary;
  font-size: 20px;
  cursor: pointer;
}
.direct_order_add_new_order{

  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  @media screen and (max-width: 575px) {
    font-size: 12px;
    justify-content: center;
    width: 100%;
    span {
      display: none;

    }
  }
  svg {
    height: 24px;
    width: 24px !important;
  }

}

.request_access_btn{
  font-size: 16px;
  -webkit-border-radius:8px;
  background-color: $blue-primary;
  color: white;
  padding: 10px 25px 10px 25px;
  @media screen and (max-width: 867px) {
    font-size: 12px;
  }

}
.empty_collection_direct_order {
  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);
  @include justify-content(center);
  height: 100%;
  @media screen and (max-width: 1024px) {
  }
  img {
    height: 300px;
    @media screen and (max-width: 1400px) {
      height: 200px;
    }
    @media screen and (max-width: 1024px) {
      height: 200px;
    }
    @media screen and (max-width: 575px) {
      height: 120px;
    }
  }
  .collection_welcome {
    text-align: center;
    @media screen and (max-width: 1400px) {
      font-size: 32px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .submit_button_blue_add_folder {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 450px;
    padding: 18px 25px;
    svg {
      height: 24px;
      width: 24px !important;
    }
  }
  .collection_welcome_description {
    text-align: center;
    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }
  }
  .folders_image{
    @media screen and (max-width: 1024px) {
      width: 180px;
    }
  }
}
.direct_order_list_body_wrapper {
  align-self: stretch;
  overflow-y: scroll;
  margin-top: 5px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgb(161, 161, 161);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

.order_miniature_bg {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);

  margin: 5px 0;
  height: 90px;
  padding: 20px;
  width: 100%;
  border: 1px solid $blue-primary;
  //   border: 2px solid #c8d4f0;
  border-radius: 8px;
  background-color: white;

  .order_miniature_info {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    .order_miniature_name {
      @include flexbox();
      @include align-items(center);
      margin-bottom: 10px;
      & > svg {
        width: 20px;
        height: 21px;
        @media (min-width: 540px) and (max-width: 768px) {
          width: 14px;
          height: 15px;
        }
      }
      & > h2 {
        font-size: 16px;
        font-weight: 700;
        margin-left: 15px;
        margin: 0;
        margin-left: 20px;

        @media (min-width: 360px) and (max-width: 768px) {
          font-size: 12px !important;
          white-space: nowrap;
          margin-left: 8px;
        }
      }
    }

    & > p {
      font-size: 14px;

      @media (min-width: 540px) and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 2px 8px 2px rgba(71, 71, 71, 0.1);
  }
}
// ORDER FOR COLLABORATOR
.order_miniature {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);

  margin: 5px 0;
  height: 90px;
  padding: 20px;
  width: 100%;
  border: 1px solid $blue-primary;
  //   border: 2px solid #c8d4f0;
  border-radius: 8px;
  background-color: $blue-sky-light;

  .order_miniature_info {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    .order_miniature_name {
      @include flexbox();
      @include align-items(center);
      margin-bottom: 10px;

      & > svg {
        width: 20px;
        height: 21px;
        @media (min-width: 540px) and (max-width: 768px) {
          width: 14px;
          height: 15px;
        }
      }
      & > h2 {
        font-size: 16px;
        font-weight: 700;
        margin-left: 15px;
        margin: 0;
        margin-left: 20px;

        @media (min-width: 360px) and (max-width: 768px) {
          font-size: 12px;
          white-space: nowrap;
          margin-left: 8px;
        }
      }
    }

    & > p {
      font-size: 12px;

      @media (min-width: 540px) and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 2px 8px 2px rgba(71, 71, 71, 0.1);
  }
}
// ORDER FOR NO COLLABORATOR
.order_miniature_not_collab {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);

  margin: 5px 0;
  height: 90px;
  padding: 20px;
  width: 100%;
  border: 2px dashed #c8d4f0;
  //   border: 2px solid #c8d4f0;
  border-radius: 8px;
  background-color: $blue-sky-light;

  .order_miniature_info {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    .order_miniature_name {
      @include flexbox();
      @include align-items(center);
      margin-bottom: 10px;

      & > svg {
        width: 20px;
        height: 21px;
        @media (min-width: 540px) and (max-width: 768px) {
          width: 14px;
          height: 15px;
        }
      }
      & > h2 {
        font-size: 16px;
        font-weight: 700;
        margin-left: 15px;
        margin: 0;
        margin-left: 20px;

        @media (min-width: 360px) and (max-width: 768px) {
          font-size: 12px;
          white-space: nowrap;
          margin-left: 8px;
        }
      }
    }

    & > p {
      font-size: 12px;

      @media (min-width: 540px) and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  .request_access_order {
    font-family: $font;
    font-size: 14px;
    color: $blue-primary;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;

    @media (min-width: 540px) and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .request_access_order_sent {
    color: $dark-gray;
    cursor: unset;
  }
}
.anticon-exclamation-circle {
  display: none;
}
.ant-popover-message-title {
  padding-left: 0;
}

.ant-popover-buttons {
  & > .ant-btn-sm {
    border-radius: 5px;
  }
  & > .ant-btn-sm:hover:first-child {
    border-color: $blue-primary;
    color: $blue-primary;
  }
  & > .ant-btn-primary {
    background: $blue-primary;
    border-color: $blue-primary;
  }
}

.collab_profile {
  width: 50px !important;
  height: 50px !important;
  border: 1px solid white !important;
  border-radius: 50% !important;
  box-shadow: 0px 3px 8px rgba($black, 0.2) !important;
  display: inline-block !important;
  //   background: white !important;
  cursor: pointer !important;
  background-size: cover !important;
  background-position: center center !important;
  transition: all 0.2s ease-in-out;
  &:nth-of-type(n + 2) {
    margin-left: 50px * -0.4 !important;
  }

  &:hover {
    transform: scale(1);
    // box-shadow: 0px 8px 20px rgba($black, 0.2);

    &:after {
      position: absolute;
      content: attr(id);
      background: rgba(white, 0.55);
      color: $black;
      text-transform: capitalize;
      font-size: 10px;
      padding: 4px;
      width: auto;
      bottom: -0.5rem;
      right: -0.5rem;
      box-shadow: 0px 5px 12px rgba(black, 0.12);
      opacity: 0;
      border-radius: 0.15rem;
      // delayed tooltip
      animation: fade 100ms ease 250ms forwards;
    }
  }

  @media (min-width: 540px) and (max-width: 768px) {
    width: 40px !important;
    height: 40px !important;

    &:nth-of-type(n + 2) {
      margin-left: 40px * -0.4 !important;
    }
  }
}

.collab_list {
  display: inline-flex;
  position: relative;
  width: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &:hover {
    .c-profile {
      &:nth-of-type(n + 2) {
        margin-left: 7px;
      }
    }
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.order_selected {
  background-color: #A5AB36;
  box-shadow: 0px 3px 4px rgba($blue-primary, 0.2);
}
.direct_order_collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 20px !important;
  font-family: $font;
}
