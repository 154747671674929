@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.sustainabiliy {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);

  & > .h2_subtitle {
    @media (max-width: 768px) {
     // font-size: 30px;
      font-size: 20px;
    }
  }
  .blue_line {
    margin-bottom: 20px;
  }
  h1 {
    color: $blue-primary;
    margin-bottom: 20px;
  }

  .sustainability_paragraph {
    font-family: $font;
    font-weight: 300;
    text-align: center;
    width: 50%;

    @media (max-width: 768px) {
      width: 80%;
      font-size: 12px;
    }
  }
}

.carousel3D_wrapper {
  width: 65%;
  height: 400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  .carousel_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #e2e9ff;
    padding: 35px;
    width: 500px;
    height: 320px;
    background: white;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 350px;
    }

    p {
      font-family: $font;
      font-size: 18px;
      margin-top: 20px;
    }
  }
}
