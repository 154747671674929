@import "./mixins.scss";
@import "./variables.scss";

.notif_page {
  padding-top: 109px;
  background-color: $whitish;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding-top: 88px;
  }
}

.notif_page_title {
  color: $blue-primary;
  font-size: 16px;
  font-weight: 600;
  font-family: $font;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.notif_item_page {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  @include align-content(center);
  // @media (max-width: 768px) {
  //   @include align-content(flex-end);
  // }
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: white;
  height: 80px;
  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 2px 5px rgba(black, 0.1);
  }
  .icon_notif_container {
    min-width: 45px !important;
  }
  div > div > svg {
    font-size: 25px;
    height: 25px;
    width: 25px;

    @media (max-width: 425px) {
      height: 25px;
      width: 25px;
    }
    // & > text {
    // //   height: 50px;
    // //   width: 50px;
    // }
  }

  & > div > h2 {
    color: #5c5c5c;
    font-size: 16px;

    @media (max-width: 768px) {
      font-weight: 500;
      font-size: 12px !important;
    }
  }
  &:hover > div > h2 {
    color: $blue-primary;
  }
  &:hover > div > div > h2 {
    color: $blue-primary;
  }

  .selection_date {
    color: rgba(#5c5c5c, 0.9);
    font-size: 15px;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 11px !important;
      margin-left: auto !important;
    }
  }
  .notif_name {
    color: $dark-gray;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px !important;
    margin-left: 10px;

    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }

  & > div > span {
    min-width: fit-content;
    & > .added_product_img {
      width: 42px;
      max-height: 68px;
      max-height: 56px;
      object-fit: contain;
    }
  }
}

.order_sent_notif_icon {
  color: $blue-primary;
  font-size: 30px !important;
  height: 30px !important;
  width: 30px !important;
}
.order_sent_notif_icon.contain img {
  object-fit: contain !important;
}

.load_more_notif_btn {
  width: 100%;
  height: 70px !important;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px !important;

  @media (max-width: 768px) {
    font-size: 12px !important;
  }
  &:hover,
  &:focus {
    border-color: $blue-primary !important;
    color: $blue-primary !important;
  }
}

.notif_page {
  & > .container {
    @media (min-width: 1200px) {
      max-width: 1300px;
    }
  }
}

.img_group_selection_notif_page {
  @include flexbox();
  @include align-items(flex-end);

  .notif_img_wrapper {
    min-width: fit-content;
    & > img {
      width: 35px;
      max-height: 60px;
    }
  }
  .notif_img_wrapper:first-child {
    margin-top: -6px;
  }
  .notif_img_wrapper:last-child {
    margin-left: -28px;
    margin-bottom: -10px;
  }
}

.img_group_selection_notif {
  @include flexbox();
  @include align-items(flex-end);
  .notif_img_wrapper {
    min-width: fit-content;
    & > img {
      width: 77px;
      max-height: 127px;
    }
  }

  .notif_img_wrapper:last-child {
    // margin-left: -47.5px;
    // margin-bottom: -50px;
    margin-left: -68.5px;
    margin-bottom: -35px;
  }
}
.selection_notif_drawer {
  // height: 190px;
  height: 140px;
  @include flexbox();
  @include align-items(flex-start);
}

.decline_request_btn,
.accept_request_btn {
  border-radius: 5px !important;
  font-family: $font;
  height: 50px !important;
  width: 100px;
}

.accept_request_btn {
  background: $blue-primary !important;
  color: white !important;
}

.decline_request_btn {
  background: $light-gray !important;
  color: $dark-gray !important;
}
.accept_request_btn:hover {
  border-color: $blue-primary !important;
}
.decline_request_btn:hover {
  border-color: $light-gray !important;
}
