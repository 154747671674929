@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #A5AB36;
  color: #fff;
  padding: 15px;
  cursor: pointer;
  border: none;
  border-radius: 32px;
  z-index: 999;
}

.chat-button-detail-product {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: #A5AB36;
  color: #fff;
  padding: 15px;
  cursor: pointer;
  border: none;
  border-radius: 32px;
  z-index: 999;
}
