@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.drawer_wrapper {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  color: $blue-primary;
  padding: 30px;
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  overflow-y: scroll;
  @media (max-width: 575px) {
    width: 100%;
    padding: 35px 20px;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: transparent;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  .close_btn {
    cursor: pointer;
    @include flexbox();
    @include align-items(center);
    @include align-self(flex-end);
    padding: 20px 50px 20px 20px;
    @media (max-width: 575px) {
      padding: 0px;
    }
    p {
      margin: 0 10px 0 0;
      font-weight: bold;
    }
    svg {
      font-size: 25px;
    }
  }
}
