@import "./variables.scss";
@import "./mixins.scss";

.tags-page {
  padding-top: 105px !important;
  @media (max-width: 768px) {
    padding-top: 87px !important;
  }
  @media (min-width: 1100px) {
    padding-top: 109px !important;
  }
}

.tag-btn {
  border-radius: 5px;
  border: 1px solid #d9e1f7;
  background-color: white;
  color: $blue-primary;
  padding: 5px 7px;
  font-size: 0.75rem;
  &.is-active {
    background-color: #d5dffc;
    border: 1px solid $blue-primary;
  }
  // &.load-tags {
  //   background: $blue-primary;
  //   color: white;
  // }
}
.result_search {
  .result_search_filter {
    &::-webkit-scrollbar {
      height: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
}
