@import "../sass/mixins.scss";
@import "../sass/variables.scss";

.footer_btns_form {
  display: flex;
  justify-content: start !important;
}
.footer_btns_form .save-changes {
  border-radius: 5px;
  background: $blue-primary;
  border-color: $blue-primary;
  color: white;
  height: 45px;
  width: 200px;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.action-comment{
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.table-progress{
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.pop-up-text{
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.footer_btns_form2 {
  display: flex;
  justify-content: space-between !important;
}
.footer_btns_form2 .save-changes {
  border-radius: 5px;
  background: $blue-primary;
  border-color: $blue-primary;
  color: white;
  height: 45px;
  width: 200px;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}

.footer_btns_form2 .confirm {
  border-radius: 5px;
  background: white;
  border-color: #00468c;
  color: #00468c;
  height: 45px;
  width: 200px;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.footer_btns_form2 .confirm_disabled {
color: rgba(0, 0, 0, 0.25);
background: #f5f5f5;
border-color: #d9d9d9;
text-shadow: none;
-webkit-box-shadow: none;
box-shadow: none;
height: 45px;
  width: 200px;
  font-size: 16px;}
.myorder_details {
  background: $whitish;
  padding: 109px 30px 0 30px;
  min-height: 100vh;
  & > .myorder_details_header {
    @include flexbox();
    @include align-items(center);
    font-family: $font;
    & > h1 {
      font-size: 20px;
      color: $blue-primary;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
      margin-left: 20px;
    }

    & > .myorder_name {
      font-size: 14px;
      color: $dark-gray;
      margin-left: 20px;
      font-weight: 600;
      text-transform: capitalize;

      & > span {
        font-weight: 400;
      }
    }
  }
}
.back_arrow_folder {
  cursor: pointer;
}
.add_input {
  border-radius: 5px;
  background: #e5ecf3;
  border-color: #e5ecf3;
  color: #03468c;
  height: 45px;
  width: 45px;
  font-size: 16px;
}
.remove_input {
  border-radius: 5px;
  background: #ffebdc;
  border-color: #ffebdc;
  color: #ff872a;
  height: 45px;
  width: 45px;
  font-size: 16px;
}

.grid_size {
  margin-bottom: 16px !important;
}

.articles_table > tbody > tr {
  height: 85px;
}

.myorder_details_header .ant-skeleton .ant-skeleton-avatar-circle {
  height: 50px !important;
  width: 50px !important;
}

.see_more_order_details_btn {
  height: 50px;
  width: 150px;
}

.myorder_details_header {
  @include flexbox();
  @include align-items(center);

  & > h1 {
    font-size: 1.6rem;
    text-transform: uppercase;
    color: $blue-primary;
    font-weight: 700;
    margin-bottom: 0px;
  }
}
