@import "./mixins.scss";

.button-icon {
  display: none;
}

.button-text {
  display:  block;
}
.order-btn-h-start-custom {
  background: #182844;
  border-radius: 5px;
  padding-top: 15px;
 width: 100%;
  cursor: pointer;
  padding-bottom: 15px;
  padding-right: 24px;
  padding-left: 24px;
  color: white;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  font-size: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  @include transition(all, 0.4s, ease);
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.7;
  }
  &.outlined-btn-h {
    color: #03468c;
    border: 1px solid #03468c;
    background: white;
  }


  @media screen and (max-width: 1366px) {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {

    padding: 20px ;
    width: 50px;
    height: 50px;

    .button-text {
      display: none;
    }
    .button-icon {
      display:  inline-block;
    }
  }
}


.order-btn-h-add-new-collection {
  background: white;
  border-radius: 5px;
  padding-top: 12px;
  cursor: pointer;
  padding-bottom: 12px;
  padding-right: 24px;
  padding-left: 24px;
  border: 1px solid #182844;
  color: #182844;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-size: 18px;

  @include transition(all, 0.4s, ease);
  margin: 10px;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.7;
  }
  &.outlined-btn-h {
    color: #03468c;
    border: 1px solid #03468c;
    background: white;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 768px) {

    padding: 20px ;
    width: 50px;
    height: 50px;
    display: flex;
    color:#182844 ;
    align-items: center;
    text-align: center;
    .button-text {
      display: none;
    }
    .button-icon {
      display:  inline-block;

    }
  }
}

.order-btn-h {
  background: #182844;
  border-radius: 5px;
  padding-top: 15px;
  cursor: pointer;
  padding-bottom: 15px;
  padding-right: 50px;
  padding-left: 50px;
  color: white;
  font-family: "Poppins", sans-serif;
  border: none;
  outline: none;
  font-size: 18px;
  @include transition(all, 0.4s, ease);
  margin: 10px;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.7;
  }
  &.outlined-btn-h {
    color: #03468c;
    border: 1px solid #03468c;
    background: white;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.order-btn-h-outlined {
  background: white;
  border-radius: 5px;
  padding-top: 20px;
  cursor: pointer;
  padding-bottom: 20px;
  padding-right: 50px;
  padding-left: 50px;
  color: #03468c;
  border: 1px solid #03468c;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-size: 20px;
  @include transition(all, 0.4s, ease);
  margin: 10px;
  &:focus {
    outline: none;
  }

  &:hover {
    color: white;
    border: 1px solid white;
    background: #03468c;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


.order-btn-h-outlined-custom {
  background: white;
  border-radius: 5px;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  color: #182844;
  border: 1px solid #182844;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-size: 16px;
  @include transition(all, 0.4s, ease);
  height: 60px;
  margin: 10px;
  &:focus {
    outline: none;
  }

  &:hover {
    color: white;
    border: 1px solid white;
    background: #182844;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }
}
::placeholder {
  font-size: 16px; /* Adjust the font size as needed */
}
.order-btn-h-outlined-add-to-collection-icon{
  background: white;
  border-radius: 5px;

  cursor: pointer;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 24px ;
  padding-left: 24px;
  color: #182844;
  border: 1px solid #182844;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-size: 16px;
  @include transition(all, 0.4s, ease);
  height: 60px;
  margin: 10px;
  &:focus {
    outline: none;
  }

  &:hover {
    color: white;
    border: 1px solid white;
    background: #182844;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }
}

.order-btn-h-order-custom {
  background: #182844;
  border-radius: 5px;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  color:  white;
  border: 1px solid #182844;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-size: 16px;
  @include transition(all, 0.4s, ease);
  height: 60px;
  margin: 10px;
  &:focus {
    outline: none;
  }

  &:hover {
    color: white;
    border: 1px solid white;
    background: #182844;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px !important;
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px !important;
  }
}


.order-btn-h-custom-prodcut {
  background: #182844;
  border-radius: 5px;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  padding-right: 30px;
  padding-left: 30px;
  color:  white;
  border: 1px solid #182844;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-size: 16px;
  @include transition(all, 0.4s, ease);
  height: 60px;
  margin: 10px;
  &:focus {
    outline: none;
  }

  &:hover {
    color: white;
    border: 1px solid white;
    background: #182844;
  }
  @media screen and (max-width: 1366px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px !important;
  }
  @media screen and (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px !important;
  }
}
.cancel_customization {
  color: black !important;
  background: white !important;
  border: none !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
}
