@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.modal_update_account {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .ant-btn-primary {
    background-color: $blue-primary !important;
    background: $blue-primary !important;
  }
  .field {
    position: relative;
    .icon_eye {
      position: absolute;
      right: 0;
      top: 20px;
    }
  }
}
