@import "../sass/variables.scss";
.container,
.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.bg_images_login {
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  background-position-x: center;
  background-repeat: no-repeat;
  @media screen and (min-width: 1921px) {
    background-size: cover;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    background-size: contain;
  }

  @media screen and (max-width: 450px) {
    background-size: auto 350px;
  }
  @media screen and (max-height: 700px) {
    background-size: cover;
  }
  @media screen and (max-width: 710px) {
    background-size: auto 450px;
  }
}
.loginPageContainer {
  transition: all 1s;
  position: relative;
  .row {
    @media screen and (max-width: 900px) {
      flex-wrap: nowrap;
    }
  }
}

.video-container {
  height: 300px;
  width: 300px;
  overflow: hidden;
  position: relative;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.slogan {
  font-size: 90px;
  font-weight: 800;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  @media screen and (max-width: 1550px) {
    font-size: 65px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 55px;
  }
  @media screen and (max-width: 1150px) {
    font-size: 45px;
  }
  @media screen and (max-width: 900px) {
    position: static;
    transform: translate(0, 0);
    font-size: 65px;
  }

  @media screen and (max-width: 650px) {
    font-size: 55px;
  }
  @media screen and (max-width: 520px) {
    font-size: 45px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 20px;
    font-size: 35px;
  }
}
.slogan,
.slogan_description {
  color: white;
  text-align: center;
  text-transform: uppercase;
}
.slogan_light_text {
  font-weight: 200;
}
.slogan_description {
  font-family: $font;
  font-weight: bolder;
  font-size: 40px;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;

  @media screen and (max-width: 1550px) {
    font-size: 35px;
  }
  // @media screen and (max-width: 1300px) {
  //   font-size: 55px;
  // }
  // @media screen and (max-width: 1150px) {
  //   font-size: 45px;
  // }
  @media screen and (max-width: 900px) {
    position: static;
    transform: translate(0, 0);
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (max-width: 450px) {
    font-size: 30px;
  }
  @media screen and (max-width: 400px) {
    font-size: 28px;
  }
}
.login_page_text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: min-content;
  position: relative !important;
  padding: 0 !important;
  @media screen and (max-width: 900px) {
    order: -1;
    min-height: 400px;
    max-width: 100% !important;
  }
  @media screen and (max-width: 450px) {
    order: -1;
    min-height: 350px;
    max-width: 100% !important;
  }
}
