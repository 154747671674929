/* .timeline {
  margin: 50px 60px;
  height: 4px;
  background-color: #ccc;
}

.timeline-progress {
  width: 0;
  height: 100%;
  background-color: orange;
}

.timeline-items {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -12px;
  display: flex;
  justify-content: space-between;
}

.timeline-item {
  position: relative;
}

.timeline-item::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #ccc;
  display: block;
  border-radius: 100%;
}

.timeline-content {
  position: absolute;
  top: -120px;
  left: 50%;
  transform: translateX(-50%);

  width: 100px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.timeline-content-botton {
  position: absolute;

  left: 10%;
  transform: translateX(-50%);

  width: 100px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.vl-botton {
  height: 60px;
  border-left: 4px dotted green;
  margin-left: 9px;
}

.vl-top {
  height: 60px;
  border-right: 4px dotted green;
  margin-right: 48px;
}

.timeline-item.active::before {
  background-color: orange;
}

.timeline-item.active .timeline-content {
  color: #fff;
  background-color: orange;
}

.timeline-item.active .timeline-content::before {
  background-color: orange;
}
.image-certif {
  margin-left: -35px;
}
.image {
  width: 40px;
  height: 40px;
}
 */
.image {
  height: 40px;
}

button {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

/* .section SECTION
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.section {
  background: #00468c;
  padding: 50px 0;
}

.section .container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.section h1 {
  font-size: 2.5rem;
}

.section h2 {
  font-size: 1.3rem;
}

/* TIMELINE
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline {
  white-space: nowrap;
  overflow-x: hidden;
}

.timeline ol {
  font-size: 0;
  width: 100vw;
  padding: 250px 0;
  transition: all 1s;
}

.timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 160px;
  height: 3px;
  background: #7D7E72;
}

.timeline ol li:last-child {
  width: 280px;
}

.timeline ol li:not(:first-child) {
  margin-left: 14px;
}

.timeline ol li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #00468c;
}

.timeline ol li div {
  position: absolute;
  left: calc(100% + 7px);
  width: 310px;
  padding: 15px;
  font-size: 15px;
  white-space: normal;
  color: black;
  /* container data */
  background: #e7f3ff;
}

.timeline ol li div::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ol li:nth-child(odd) div {
  top: -16px;
  transform: translateY(-100%);
  text-align: left;
}

.timeline ol li:nth-child(odd) div::before {
  top: 100%;
  border-width: 8px 8px 0 0;
  border-color: #e7f3ff transparent transparent transparent;
}

.timeline ol li:nth-child(even) div {
  margin-top: 0px;
  top: calc(100% + 16px);
  margin-left: 0px;
  text-align: left;
}

.timeline ol li:nth-child(even) div::before {
  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent #e7f3ff;
}

.timeline time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}

/* TIMELINE ARROWS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline .arrows {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.timeline .arrows .arrow__prev {
  margin-right: 20px;
}

.timeline .disabled {
  opacity: 0.5;
}

.timeline .arrows img {
  width: 45px;
  height: 45px;
}
/* 
  
  @media screen and (max-width: 599px) {
    .timeline ol,
    .timeline ol li {
      width: auto;
    }
  
    .timeline ol {
      padding: 0;
      transform: none !important;
    }
  
    .timeline ol li {
      display: block;
      height: auto;
      background: transparent;
    }
  
    .timeline ol li:first-child {
      margin-top: 25px;
    }
  
    .timeline ol li:not(:first-child) {
      margin-left: auto;
    }
  
    .timeline ol li div {
      width: 94%;
      height: auto !important;
      margin: 0 auto 25px;
    }
  
    .timeline ol li div {
      position: static;
    }
  
    .timeline ol li:nth-child(odd) div {
      transform: none;
    }
  
    .timeline ol li:nth-child(odd) div::before,
    .timeline ol li:nth-child(even) div::before {
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      border: none;
      border-left: 1px solid white;
      height: 25px;
    }
  
    .timeline ol li:last-child,
    .timeline ol li:nth-last-child(2) div::before,
    .timeline ol li:not(:last-child)::after,
    .timeline .arrows {
      display: none;
    }
  }
   */
#slider {
  overflow-x: auto;
}

#slider::-webkit-scrollbar {
  width: 0;
}
.content {
  min-height: 220px;
}

.flex-container {
  position: relative;

  /* Other styling stuff */
  width: 400px;
  height: 200px;
  background-color: #3498db;
}

.inner-element {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 5%;
  transform: translate(-50%, -50%);
  /* or 3d alternative if you will add animations (smoother transitions) */
  transform: translate3d(-50%, -50%, 0);

  /* Other styling stuff */
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 599px) {
  .inner-element {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 50%;
    transform: translate(-50%, -50%);
    /* or 3d alternative if you will add animations (smoother transitions) */
    transform: translate3d(-50%, -50%, 0);

    /* Other styling stuff */
    width: 100px;
    height: 100px;
  }
}
.space {
  width: 4px;
  height: auto;
  display: inline-block;
}

.timeline-wrapper {
  position: relative;
  width: 100%;
  margin: auto;
  height: 300px;
}

.timeline-wrapper .middle-line {
  position: absolute;
  width: 100%;
  height: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #d9d9d9;
}

.box {
  width: 17%;
  position: relative;
  min-height: 300px;
  float: right;
}

.box .date {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  border: 2px solid #d9d9d9;
}

.date p {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 0px;
}

.box .box-content {
  border-radius: 5px;
  background-color: #00b0bd;
  width: 180px;
  position: absolute;
  left: -77px;
  padding: 15px;
}

.box-content p {
  margin: 0;
  color: white;
}

.box-bottom .box-content {
  top: 65%;
}

.box-content::before {
  content: " ";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 10px solid transparent;
}

.box-bottom .box-content::before {
  border-bottom-color: #00b0bd;
  top: -20px;
}

.box-top .box-content::before {
  border-top-color: #00b0bd;
  bottom: -20px;
}

p {
  font-family: "Trebuchet MS", sans-serif;
}
