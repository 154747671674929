@import "../sass/mixins.scss";

.home_customization {
  // height: calc(100vh - -129px);
  margin-top: 170px !important;
  margin-bottom: 85px !important;

  @media (max-width: 768px) {
    margin-top: 99px !important;
  }

  .banner_info_shadow_block {
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (min-width: 992px) {
      width: 550px;
    }
    @media (min-width: 1024px) {
      width: 580px;
    }
    @media (min-width: 1024px) {
      min-width: 680px;
    }
  }
}
.home_banner_wrapper {
  // transform: translateX(80px);
  z-index: 2;

  .banner_container {
    .Banner_header {
      // @media (max-width: 768px) {
      //   @include flexbox();
      //   @include flex-direction(column);
      //   @include align-items(center);
      // }
      @media (max-width: 992px) {
        margin-bottom: 5px;
      }

      .blue_line {
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      & > h1 {
        @media (max-width: 768px) {
          margin-bottom: 20px;
          font-size: 25px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    transform: translateY(-70px);
  }
  @media (min-width: 992px) {
    transform: translateX(0px);
  }

  @media (min-width: 1366px) {
    transform: translateX(118px);
  }
  @media (min-width: 1920px) {
    transform: translateX(200px);
  }
  .banner_container {
    height: 100%;
    position: relative;
    @media (max-width: 768px) {
      @include flexbox();
      @include flex-direction(column);
      @include align-items(flex-start);
    }

    button {
      position: absolute;
      bottom: 50px;
      width: 50%;
      @media (max-width: 880px) {
        padding: 17px 25px;
        bottom: -30px !important;
      }
      @media (max-width: 992px) {
        width: 55%;
        padding: 20px;
        bottom: -30px !important;
      }
      @media (max-width: 1366px) {
        bottom: 10px;
        font-size: 1rem;
      }
      @media (max-height: 768px) {
        bottom: 10px;
      }
    }

    .banner_info_shadow_block {
      @media (max-width: 768px) {
        margin-bottom: 57px;
      }
      @media (max-width: 1366px) {
        padding: 27px 24px;
      }
    }
  }
  .banner_info_shadow_block {
    background: white;
  }
}
.home_customization .video_wrapper {
  width: 100%;
  video {
    width: 101%;
  }
}
.home_video {
  overflow: hidden;
  .video_wrapper {
    width: 116%;
  }
  // @media (max-width: 768px) {
  //   display: none;
  // }
}

#customization_tool {
  padding-left: 137px !important;
  padding-right: 59px !important;
  padding-top: 82px !important;
}

//
.home_difference {
  align-items: center;
}
.home_banner_wrapper_difference {
  z-index: 2;
  transform: translateX(-200px);
  @media screen and (max-width: 1300px) {
    transform: translate(-200px, 40px);
  }
  @media screen and (max-width: 768px) {
    transform: translateX(0px);
  }

  & > .banner_container {
    transform: translateY(-50px);

    .banner_info_shadow_block {
      background: white;
      width: 100%;

      .Banner_header {
        @include flexbox();
       /* @include flex-direction(row);
        @include align-items(flex-end);*/
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }


  .banner_container {
    .banner_info_shadow_block {
      padding: 10px 10px;
      transform: translateX(210px);
      @media screen and (max-width: 768px) {
        transform: translatex(0px);
        //transform: translatey(110px);
      }
    }
    .Banner_header {

      .section_home{
        font-size: 32px !important;
        font-weight: bold;
      }
      .h2_subtitle {
        @media screen and (max-width: 768px) {
        //  font-size: 25px;
          font-size: 20px;
        }
      }
      @media screen and (max-width: 768px) {
       // font-size: 25px;
        font-size: 20px;
        transform: translateX(0px);
        flex-direction: column;
        .blue_line {
          margin-bottom: 20px;
        }
      }
    }
  }
  .h2_subtitle_black,
  .h2_subtitle_light_black {
    //text-transform: uppercase;
  }
}
#videoBGCreativeStation{
 // padding: 25px;
}
.home_banner_wrapper_difference > .banner_container > .Banner_header {
  transform: translateX(220px);
}
.home_difference .video_wrapper {
  width: 100%;
  video {
    width: 101%;
  }
}
.home_difference_img {
  overflow: hidden;
  padding: 0 !important;
  // @media screen and (max-width: 768px) {
  //   display: none;
  // }
  .video_wrapper {
    img {
      width: 100%;
    }
  }
}
.home_product_list {
  height: 49vh;
  overflow: hidden;

  @media (min-width: 526px)  and (max-width: 790px) {
    height: 70vh;
    overflow: hidden;

  }

  @media (min-width: 425px)  and (max-width: 525px) {
    height: 60vh;
    overflow: hidden;

  }

}
.features {
  margin: 150px 0 !important;
  // height: 66vh;

  .grid_philosophy {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;

    @media (max-width: 768px) {
      align-items: stretch;
    }

    & img:first-child {
      margin-left: 20px;
      width: 50%;
    }
    div {
      display: flex;
      flex-direction: column-reverse;
      width: 50%;

      img {
        align-self: flex-end;
        width: 80%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      & img:first-child {
        margin-top: 20px;
        width: 55%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .alice-carousel__prev-btn {
    @media (max-width: 768px) {
      margin-top: 15px;
    }
  }
  .banner_container {
    @media (max-width: 768px) {
      @include flexbox();
      @include flex-direction(column);
      @include align-items(flex-start);
      margin-top: 20px;
    }

    button {
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }

    & > div > .h2_subtitle_black {
      @media (max-width: 768px) {
        //font-size: 40px;
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    & > div > .paragraphe_margin {
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    & > div > div > .h2_subtitle_black {
      @media (max-width: 768px) {
        font-size: 40px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      & > div > div > .paragraphe_margin {
        @media (max-width: 768px) {
          margin: 20px 0;
        }
      }
    }

    .Banner_header {
      @media (max-width: 768px) {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
      }

      & > .h2_subtitle {
        @media (max-width: 768px) {
        //  font-size: 35px;
          font-size: 20px;
          margin-top: 30px;
        }
      }

      & > h1 {
        @media (max-width: 768px) {
          font-size: 25px;
        }
      }
      .blue_line {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
.order_feature {
 // background: #f4f7ff;
//  padding: 80px 0 !important;

/*  .alice-carousel__stage-item {
    width: 100% !important;
  }*/
  .banner_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
  }


  @media (min-width: 425px) and (max-width: 1485px) {
    .banner_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 55%;
    }
  }

  .alice-carousel__stage-item {
    img {
      width: 100%;
    }
  }
}

.home > .justify-content-end .banner_container {
  // @media (max-width: 768px) {
  //   background-image: linear-gradient(
  //       to top,
  //       rgba(0, 0, 0, 0.9),
  //       rgba(211, 211, 211, 0.651)
  //     ),
  //     url("../img/philo3.png");
  //   background-size: cover;
  //   padding: 40px 20px;
  // }

  & div:nth-child(2) div h2 {
    @media (max-width: 768px) {
      color: white;
    }
  }
  & div:nth-child(2) p {
    @media (max-width: 768px) {
      color: white;
    }
  }

  button {
    @media (max-width: 1440px) {
      font-size: 1rem !important;
      padding: 1.6rem 1rem;
    }
  }
}
.home > .justify-content-end .grid_philosophy {
  // @media (max-width: 768px) {
  //   display: none;
  // }
}

.certif_img_wrapper {
  margin-top: 30px;
  // width: 100%;
  overflow-x: scroll;
  text-align: center;

  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: transparent;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

.onboarding-modal > .ant-modal-content {
  border-radius: 8px !important;
}

.img-onboarding::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, white 1%, transparent 50%);
}
