@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.menu {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  border-bottom: 1px solid $light-gray;
  padding: 0 37px;
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 100;

  .menu__leftSide {
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    @include flex(0.65);
   // flex: 0.65 !important;
    @media (max-width: 768px) {
     // justify-content: flex-start;
      //@include flex(0.6);
      flex: 0.7 !important;
    }
    @media (max-width: 570px) {
      // justify-content: flex-start;
      //@include flex(0.6);
      flex: 0.75 !important;
    }
    img {
      width: 154px;
      // @media (max-width: 768px) {
      //   margin-left: 15px;
      // }
      @media (max-width: 992px) {
        width: 115px;
      }
    }
    .menu__items {
      width: 100%;
      @include flexbox();
      @include justify-content(space-around);
      @include align-items(center);
      flex-wrap: nowrap;
      margin-left: 15px;
      @media (max-width: 992px) {
        @include justify-content(space-evenly);
      }
    }
    .menu__items a {
      white-space: nowrap;
      margin-right: 10px;
      text-decoration: none;
      color: $black;
     // font-weight: 900;
      padding-top: 40px;
      padding-bottom: 36px;
      border-bottom: 4px solid transparent;
      transition: all 0.2s ease-in-out;
     // font-size: 0.9rem;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      &:hover {
        border-color: black;
      }
      @media (min-width: 992px) {
        margin-right: 15px;

     //   font-size: 1rem;
        font-size: 16px;
      }
      @media (min-width: 1100px) {
        margin-right: 15px;

       // font-size: 1rem;
        font-size: 16px;
      }
    }
    @media (max-width: 992px) {
    //  @include flex(0.3);
      @include flex(0.65);
    }
    // @media (max-width: 992px) {
    //   @include flex(0.6);
    // }
    @media (min-width: 1024px) {
      @include flex(0.35);
    }
    // @media (min-width: 1024px) {
    //   @include flex(0.5);
    // }
  }
  .menu__rightSide {
    @include flexbox();
    @include justify-content(space-evenly);
    @include align-items(center);
    @include flex(0.45);
    @media (max-width: 992px) {
      //@include flex(0.6);
      @include flex(0);
    }
    @media (min-width: 1024px) {
    //  @include flex(0.3);
      @include flex(0);
    }
    @media (min-width: 1366px) {
    //  @include flex(0.35);
      @include flex(0);
    }
    @media (min-width: 1600px) {
     // @include flex(0.25);
      @include flex(0);
    }
    @media (min-width: 2048px) {
    //  @include flex(0.2);
      @include flex(0);
    }

    .menu__icons {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      @include flex(0.3);

      svg {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.904);
        cursor: pointer;
        margin-right: 10px;
      }
    }

    .menu__userInfo {
      @include flexbox();
     // @include justify-content(space-between);
      @include justify-content(right);
      @include align-items(center);
      // @include flex(0.5);
      padding-right: 10px;
      margin-left: 10px;
      @media (max-width: 992px) {
        @include flex(0.5);
      }
      @media (min-width: 1024px) {
        @include flex(0.6);
      }
      div {
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);

        @media (max-width: 1062px) {
          display: none;
        }
      }
      p,
      span {
        font-size: 14px;
        font-weight: 300;
        margin: 0;
        @media (max-width: 1920px) {
          font-size: 14px;
        }
        @media (min-width: 2048px) {
          font-size: 18px;
        }
      }
      span {
        cursor: pointer;
        font-weight: 700;
      }
      img {
        height: 40px;
        object-fit: cover;
        @include border-radius(50%);
        width: 40px;
        cursor: pointer;
        margin: 0 10px;
        @media (max-width: 992px) {
          width: 40px;
          height: 40px;
        }
      }
  /*    svg {
        height: 24px;
        width: 24px;
        cursor: pointer;
        @media (max-width: 992px) {
          height: 18px;
          width: 18px;
        }
      }*/
    }
  }
}
.active-link {
  border-bottom: 4px solid $blue-primary !important;
}
.navbar-font{
  font-family: $font;
}
.menu__white {
  background: transparent;
  position: absolute;
  top: 0;
  color: $white;
  .menu__leftSide .menu__items a {
    color: $white;
    &:hover {
      border-color: $white;
    }
  }
  .menu__rightSide .menu__icons svg {
    color: $white !important;
    fill: $white !important;
    cursor: pointer;
  }
  .menu__rightSide .menu__icons svg path {
    color: $white !important;
    fill: $white !important;
    cursor: pointer;
  }
  .menu__userInfo svg path {
    fill: $white !important;
  }
}
.menu {
  .menu__rightSide .menu__icons svg {
    color: black;
    fill: black;
    cursor: pointer;
  }
  .menu__rightSide .menu__icons svg path {
    color: black;
    fill: black;
    cursor: pointer;
  }
  .menu__userInfo svg {
    fill: white;
  }
}

.mobile_icons {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);

  & > svg {
    margin-right: 20px;
    @media (max-width: 400px) {
      margin-right: 10px;
    }
    font-size: 23px;
    // color: black;
  }
  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.mobile_menu {
  padding: 20px;
}

.mobile_leftSide > svg {
  margin-right: 15px;
}

.ant-badge-status {
  & > .ant-badge-status-processing {
    background: red;

    &::after {
      border: 1px solid red;
    }
    position: absolute;
    left: 8px;
    border: 1px solid white;
    width: 10px !important;
    height: 10px !important;
  }
}
.ant-badge-count {
  right: 15px !important;
}

.my-tooltip {
  margin-top: 40px;
  &::after {
    content: " ";
    position: absolute;
    right: 10px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid white;
    background: rgba(221, 221, 221, 0.486);
  }
}
.my-tooltip-right::after {
  content: " ";
  position: absolute;
  right: -10px;
  top: 10px;
  border-top: 10px solid transparent;
  border-right: none;
  border-left: 10px solid white;
  border-bottom: 10px solid transparent;
  background: rgba(221, 221, 221, 0.486);
}
