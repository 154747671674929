@import "./mixins.scss";
@import "./variables.scss";

.products_page_container {
  padding-top: 162px;
  .bread_crumb_list_products {
    position: fixed;
    z-index: 10;
    text-align: center;
    top: 107px;
    width: 100%;
    height: 55px;
    padding: 0 60px;
    background-color: white;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    .bread_crumb_item {
      color: black;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        color: $blue-primary;
      }
    }

    & > img {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      top: 88px;
    }
  }
}

.infinite-scroll-component {
  overflow: hidden !important;
}
.products_page {
  @include flexbox();
  .products_list {
    display: flex;
    flex-direction: column;
    /*     overflow-y: scroll;
 */
    padding-top: 35px;
    height: calc(100vh - 162px);
    width: 100%;

    @media (max-width: 1366px) {
      padding-top: 15px;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    .unavailable_products_container {
      font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      display: table;
      min-height: 25vh !important;
      margin: 0 auto;
      .unavailable_products {
        font-weight: 300;

        display: table-cell;
        vertical-align: middle;
      }
    }

    .submit_button_white {
      font-size: 20px;
      padding: 14px 60px;
      margin: 10px auto 20px;
    }
  }
}
.filter {
  min-width: 21%;
  overflow-y: scroll;
  height: calc(100vh - 106px);
  &::-webkit-scrollbar {
    display: none;
  }

  .ant-collapse-content-box {
    div span {
      font-weight: 300;
    }
  }

  @media (max-width: 850px) {
    width: 35%;
  }

  aside {
    @include flexbox();
    @include flex-direction(column);
    padding: 30px;

    .ant-collapse-header {
      font-weight: bold;
      @media (min-width: 1100px) {
        font-size: 12px;
      }
      @media (min-width: 1366px) {
        font-size: 14px;
      }
      @media (min-width: 1600px) {
        font-size: 16px;
      }
      @media (min-width: 1920px) {
        font-size: 18px;
      }
    }
    .ant-collapse-content {
      @media (min-width: 1100px) {
        font-size: 14px;
      }
      @media (min-width: 1366px) {
        font-size: 16px;
      }
      @media (min-width: 1600px) {
        font-size: 18px;
      }
      @media (min-width: 1920px) {
        font-size: 20px;
      }
    }

    .categories_links {
      cursor: pointer;
      text-underline-position: under;
      &:hover {
        color: $blue-primary;
      }
    }
  }
}
.filter_tags_wrapper {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  padding: 10px;
  width: 100%;

  & > div {
    @include flexbox();
    @include align-items(center);

    & > p {
      margin: 0;
      color: #5c5c5c;
      font-family: $font;
      font-size: 20px;
    }
  }

  & > p {
    @include flexbox();
    @include align-items(center);
    margin: 0;
    margin-right: 30px;
    font-size: 20px;
    text-transform: uppercase;
    font-family: $font;
    color: #5c5c5c;
    font-weight: 300;
  }
}
.filter_tag {
  @include flexbox();
  @include justify-content(space-between);
  background: $blue-sky-light;
  font-family: $font;
  width: fit-content;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 400;
  margin-right: 20px;

  p {
    margin: 0;
  }
  & p:first-child {
    margin-right: 5px;
  }
  & > span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.filter_tags {
  @include flexbox();
  @include align-items(center);
  margin-left: 10px;
}
.filter_checkbox {
  appearance: none;
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background: white;
  border: 1px solid $light-gray;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_checkbox::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f00c";
  font-size: 13px;
  color: white;
  display: none;
}

.filter_checkbox:hover {
  background: $blue-sky-light;
}
.filter_checkbox:checked {
  background: $blue-primary;
  border-color: $blue-primary;
}
.filter_checkbox:checked:after {
  display: block;
}

.ant-skeleton-avatar {
  width: 18vw !important;
  height: 45vh !important;
}

.price_range_wrapper {
  border: 1px solid $light-gray;
  border-radius: 10px;
  padding: 10px 30px;

  .prices_filter > p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .ant-slider-handle {
    background-color: $blue-primary;
    border: solid 2px $blue-primary;
    width: 20px;
    height: 20px;
  }
  .ant-slider-track {
    background: #e0f0f8;
  }
}
.ant-slider:hover .ant-slider-track {
  background: $blue-secondary;
}

.filter_checkbox:checked + label {
  color: black;
}
.filter_checkbox + label {
  color: gray;
}
.filter_checkbox + label {
  cursor: pointer;
}
.filter_checkbox + label:hover {
  color: black;
}

.one_product_link > a:hover {
  text-decoration: none;
}

.filter_product_button {
  background: white;
  border: none;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  @include flexbox();
  @include align-items(center);
  padding: 10px 10px 10px 20px;
  outline: none !important;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  padding: 0px 5px;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.load_more_btn {
  background: white;
  border: 1px solid $blue-primary;
  color: $blue-primary;
  border-radius: 5px;
  padding: 0.6rem 1rem;

  &:hover {
    background: $blue-primary;
    color: white;
  }
}
