@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.deleted_items_container {
  min-width: 100vw !important;
  height: calc(100vh - 86px);
  background-color: white;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 80px 120px;
  padding-bottom: 0;
  overflow: hidden;
  z-index: 3;

  @media screen and(max-width : 1366px) {
    padding: 80px 50px;
  }
  @media screen and(max-width : 1024px) {
    padding: 80px 20px;
  }
  @media screen and(max-width : 768px) {
    padding-top: 45px;
  }
  @media screen and(max-width : 575px) {
    padding-top: 20px;
  }
  .close_btn {
    top: 30px;
    right: 12px;
    @media screen and (max-width: 575px) {
      top: 8px;
      right: 0px;
    }
  }
  .deleted_items_title_container {
    .deleted_items_title {
      font-size: 42px;
      color: $blue-primary;
      @media screen and (max-width: 1366px) {
        font-size: 42px;
      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }
  }
  .list_items {
    overflow-y: scroll;
    height: 60vh;
    &::-webkit-scrollbar {
      width: 8px;
      // margin-left: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
  .items_titles {
    padding: 0 15px !important;
    padding-right: 20px !important;
    .item_col_title {
      margin-bottom: 10px;
      font-size: 18px;
      color: $dark-gray;
      font-weight: 500;
      padding: 0 !important;
    }
  }
  .item_container {
    &:hover {
      box-shadow: 0px 2px 8px 2px rgba(71, 71, 71, 0.1);
      transform: translateY(-1px);
    }
    transition: all 0.2s;
    border: 1px solid #ccc;
    font-weight: 500;
    border-radius: 6px;
    text-align: start;
    // margin-bottom: 10px !important;
    // margin-right: 10px !important;
    padding: 10px 15px !important;
    margin: 8px 0 !important;
    .item_element_name {
      svg {
        margin-right: 10px;
      }
    }
    .item_element {
      color: $dark-gray;
      font-size: 18px;
      display: flex;
      align-items: center;
      // padding: 18px 30px;
      padding-right: 0 !important;
      padding-left: 0 !important;
      p {
        margin-bottom: 0;
        font-weight: 600;
        color: black;
      }
      @media screen and (max-width: 768px) {
        padding: 12px 20px;
      }
      @media screen and (max-width: 575px) {
        padding: 2px 10px;
      }
    }
    .item_element_removed_on {
      color: #ff3a3a;
    }
    .item_element_archived_at,
    .item_element_removed_on {
      @media screen and (max-width: 575px) {
        font-size: 14px;
      }
    }
    .item_element_actions {
      @include flexbox();
      // @include align-items(center);
      @include justify-content(flex-start);
      @media screen and (max-width: 768px) {
        padding: 12px 10px;
      }
      button {
        background-color: white;
        padding: 10px 0px;
        margin: 0 10px;
        border-radius: 8px;
        border: 1px solid #ccc;
        font-weight: 500;
        min-width: 120px;
        @media screen and (max-width: 1024px) {
          min-width: 50px;
          margin: 0 4px;
        }
        .fa-redo-alt {
          transform: translateY(4px);
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-bottom: 3px;
          @media screen and (max-width: 1024px) {
            margin-right: 0;
          }
          path {
            fill: $blue-primary;
          }
        }
      }
    }
  }
}
