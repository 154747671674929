@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.for_you_container {
  width: 100%;
  background-color: $whitish;
  // min-height: 100vh;
  height: 100vh;
  padding-top: 125px;
  text-align: center;
  overflow-y: hidden;
  // padding-left: 50px;
  // padding-right: 50px;
  .filter {
    height: calc(100vh - 208px);
    aside {
      padding-top: 0 !important;
    }
  }
  .spinner {
    width: calc(100vw - 650px);
  }

  @media screen and (max-width: 1024px) {
    // padding-top: 115px !important;
    // height: calc(100vh - 87px);
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @media screen and (max-width: 768px) {
    padding-top: 115px !important;
    height: calc(100vh - 87px);
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @media screen and (max-width: 575px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .unavailable_products_container {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    display: table;
    min-height: 25vh !important;
    margin: 0 auto;
    .unavailable_products {
      font-weight: 300;

      display: table-cell;
      vertical-align: middle;
    }
  }
  .for_you_title {
    text-transform: uppercase !important;
    font-size: 32px !important;
    font-weight: 300 !important;

    margin-bottom: 26px;
    line-height: 30px;
    vertical-align: middle;
    @include flexbox();
    @include justify-content("center");
    @include flex-direction(column);
    // @include flexbox("flex");
    .for_you {
      font-weight: 700;
      margin-top: 15px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px !important;
    }
  }
  .list_for_you_products {
    overflow-y: scroll;
    height: calc(100vh - 208px);
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
  .list_products_for_you {
    @include flexbox();
    @include align-items(center);
    @include flex-wrap(wrap);
    // padding-top: 30px;

    .product_card {
      height: 470px !important;
      width: 340px !important;
      // margin: 0 20px;
      // margin-left: 0 !important;
      @media screen and (max-width: 1024px) {
        width: 310px !important;
      }
      @media screen and (max-width: 768px) {
        width: 280px !important;
      }
      .product_card_page {
        height: 395px !important;
        width: 100% !important;
        @media screen and (max-width: 1024px) {
          width: 310px !important;
        }
        @media screen and (max-width: 768px) {
          width: 280px !important;
        }
      }
    }
  }
  header {
    @include flexbox();
    @include align-items(center);
    width: 100%;
    margin-bottom: 20px !important;

    .h1_title {
      font-weight: bold;
      margin-right: 50px;
      font-size: 25px;
    }

    .blue_line {
      width: 100%;
      max-width: none;
      background: #7a9ec5;
    }
  }
}
// .filter {
//   & > aside > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
//     @include flexbox();
//     svg {
//       padding: 0px !important;
//       transform: translateY(11px);
//     }
//   }
// }
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  height: 89% !important;
}
.ant-drawer-bottom.ant-drawer-open
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: $blue-primary;
}
.ant-drawer-bottom.ant-drawer-open
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-title {
  padding: 7px;
}
.submitButton {
  align-items: center;
  border: none;
  color: white;
  background: $blue-primary;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0 10px;
  outline: none;
}
.submitButtonWaiting {
  align-items: center;
  border: none;
  pointer-events: none;
  color: #aaa;
  background: #f5f5f5;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0 10px;
  outline: none;
}
aside > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  @include flexbox();
  svg {
    padding: 0px !important;
    transform: translateY(15px);
    @media (max-width: 1599px) {
      transform: translateY(12px);
    }
  }
}
.filter_checkbox2 {
  appearance: none;
  -webkit-appearance: none;
  height: 26px;
  width: 26px;
  background: white;
  border: 1px solid $light-gray;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_checkbox2::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f111";
  font-size: 8px;
  color: white;
  display: none;
}

.filter_checkbox2:hover {
  background: $blue-sky-light;
}
.filter_checkbox2:checked {
  background: $blue-primary;
  border-color: $blue-primary;
}
.filter_checkbox2:checked:after {
  display: block;
}
.filter_checkbox2:checked + label {
  color: black;
}
.filter_checkbox2 + label {
  color: gray;
}
.filter_checkbox2 + label {
  cursor: pointer;
}
.filter_checkbox2 + label:hover {
  color: black;
}

.column1 {
  float: left;
  width: 50%;
  padding: 10px;
  height: 80vh; /* Should be removed. Only for demonstration */
  flex-grow: 1;
}

/* Clear floats after the columns */
.row1 {
  flex-grow: 1;
  /*   display: flex;
 */
  padding: 15px;
}
.active {
  color: #00468c;
  font-weight: 700 !important;
}
.active_moodboard {
  color: #00468c;
  font-weight: 700 !important;
}
.active_moodboard_foryou {
  color: #00468c;
  font-weight: 700 !important;
}

.btn-add-new-moodboard {
  align-items: center;
  color: white;
  border: none;
  background: #182844;
  border-radius: 5px;
  margin: 0 10px !important;
  outline: none !important;
  font-size: 16px;
  padding: 10px;
  @media (max-width: 1365px) {
    font-size: 12px;
  }
}
.mt-foryou-1 {
  margin-top: -55px;
}
.mt-foryou-2 {
  margin-top: -100px;
}

@media (max-width: 1366px) {
  .filter .aside {
    padding: 50px 10px 10px 10px;
    margin-bottom: -35px;
  }
}
