@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.folder_miniature {
  margin: 5px 0;
  // height: 90px;
  padding: 18px;
  padding-bottom: 0px;
  width: 100%;
  border: 2px solid #c8d4f0;
  border-radius: 8px;
  background-color: #f4f7ff;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    -webkit-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
  }
  @media screen and (max-width: 1366px) {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  //  @media screen and (max-width: 1024px) {
  //   width: 300px;
  //  }
  //  @media screen and (max-width: 575px) {
  //   width: 100%;
  //  }
  .popover_archive_folder {
    p {
      margin-bottom: 5px;
      font-family: $font;
      color: $dark-gray;
      font-weight: 600;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background: rgba(194, 194, 194, 0.24);
      }
    }
  }
  .folder_miniature_header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    .article_popover_btn {
      transform: translateY(-12px) !important;
    }
    .heart_outline,
    .folders_icon {
      transform: translateY(-4px);
    }

    .folder_name {
      font-size: 16px;
      font-weight: 700;
      margin-left: 15px;
    }
  }

  .folder_miniature_description {
    font-size: 14px;
    // @media screen and (max-width: 768px) {
    //   font-size: 12px;
    // }
  }
}

.myorder_collection{
  font-size: 24px !important;
  text-transform: uppercase;

}
.folder_my_selection {
  border-style: dashed;
  margin: 10px auto;
}
.folder_miniature_selected {
  background-color: white;
  // -webkit-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
  // box-shadow: 0px 5px 11px -10px rgba(0, 0, 0, 0.75);
}
.popover_archive_folder {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  padding: 2px 6px;
  // padding-right: 8px;
  // padding-bottom: 0px;

  p {
    margin-bottom: 0px;
    font-family: $font;
    color: $dark-gray;
    font-weight: 600;
    padding: 0 10px;
    cursor: pointer;

    &:hover {
      background: rgba(194, 194, 194, 0.24);
    }
    svg {
      transform: translateY(-2px);
    }
  }
}
