@import "./mixins.scss";
@import "./variables.scss";

.add_order {
  padding-top: 105px !important;

  @media (max-width: 768px) {
    padding-top: 87px !important;
  }
  @media (min-width: 1100px) {
    padding-top: 108px !important;
  }
}

.add_order_header {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  background-color: $blue-sky-light;
  padding: 10px 40px;
  border-bottom: 1px solid rgba($dark-gray, 0.2);

  @media (max-width: 575px) {
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  @media (max-width: 800px) {
    padding: 10px 40px;
  }

  .add_order_name {
    @include flexbox();
    @include align-items(center);
    width: 60%;
    & > button {
      border: none;
      background: transparent;
      border-radius: 5px;
      padding: 10px 15px;
      outline: none !important;
      @include transition(all, 0.2s, ease-in-out);

      &:hover {
        background: rgba($light-gray, 0.4);
      }
    }

    & > p {
      margin: 0;
      font-family: $font;
      font-weight: 400;
      color: $blue-primary;
      margin-right: 15px;
      font-size: 32px;

      @media (max-width: 800px) {
        font-size: 20px;
        width: 80% !important;
      }
    }

    & > svg:last-child {
      color: $blue-primary;
      font-size: 20px;
      cursor: pointer;
    }

    & > svg:first-child {
      cursor: pointer;
    }
  }
  & > svg {
    color: $blue-primary !important;
    width: 20px;
  }
}
.collab_img_container {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  cursor: pointer;
  background: white;
  padding-right: 20px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;

  & > div {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
  }
  & > div > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  & > svg {
    color: $dark-gray;
    opacity: 0;
    transition: 0.2s all ease-in-out;
  }

  &:hover > svg {
    opacity: 1;
  }

  &:hover {
    background: rgba(245, 244, 244, 0.25);
  }
}
.new_collab_img_container {
  width: 50px;
  height: 50px;
  position: relative;
  margin-left: 10px !important;

  @media (max-width: 800px) {
    width: 35px;
    height: 35px;
  }

  .new_collab {
    border: none !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    @media (max-width: 800px) {
      width: 35px;
      height: 35px;
    }
  }
  &:hover .minus_div {
    opacity: 1;
  }

  & > .minus_div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(255, 0, 0, 0.425);
    font-size: 20px;
    color: white;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    opacity: 0;
    cursor: pointer;
  }
}
.add_order_actions {
  @include flexbox();
  @include align-items(center);
}
.add_order_collabs {
  @include flexbox();
  @include align-items(center);

  .collabs_list {
    @include flexbox();
    @include align-items(center);
    @media (max-width: 575px) {
      overflow-x: auto;
      max-width: 250px;
      scrollbar-width: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .add_order_new_collab {
    position: relative;
    background-color: $blue-sky;
    border: 1px dashed $blue-primary;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 10px !important;
    color: $blue-primary;
    outline: none;

    @media (max-width: 800px) {
      width: 35px;
      height: 35px;
    }
  }
}

.add_order_save {
  background: white;
  border: 1px solid $light-gray;
  //   padding: 10px 15px;
  color: $dark-gray;
  font-size: 20px;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  margin: 0 10px !important;
  outline: none;

  @media (max-width: 800px) {
    margin: 0 5px !important;
  }
}

.add_new_order {
  @include flexbox();
  @include align-items(center);
  color: white;
  border: none;
  background: $blue-primary;
  font-family: $font;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0 10px !important;
  outline: none !important;
  font-size: 16px;
  & > svg {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    padding: 10px;
    margin: 0 5px !important;
  }
}
.delete_order {
  background: none;
  color: $blue-primary;
  border: none;
  margin: 0 10px !important;
  outline: none !important;

  @media (max-width: 800px) {
    margin: 0 5px !important;
  }
}

.mobile_order{
  background: none;
  color: $blue-primary;
  border: none;
  margin: 0 10px !important;
  outline: none !important;

  @media (max-width: 800px) {
    margin: 0 5px !important;
  }
}
.menu_item_dropdown {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);

  font-family: $font;
  font-size: 16px;
  color: $blue-primary;
  & > svg {
    color: $blue-primary;
  }
}

.add_order_details_left_side {
  background: white;
  border-right: 5px solid $blue-sky-light;
  height: calc(100vh - 179px);
  overflow-y: auto;

  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $blue-sky-light;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgba($blue-primary, 0.3);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  @media (max-width: 575px) {
    margin-top: 50px !important;
  }
  @media (max-width: 768px) {
    height: calc(100vh - 87px);
    padding: 15px !important;
  }
  @media (min-width: 540px) and (max-width: 780px) {
    min-width: 300px;
    padding: 15px !important;
    // margin-top: 50px !important;
  }
  @media (min-width: 769px) {
    min-width: 400px;
    padding: 1.5rem 3rem 3rem 3rem !important;
  }
}
.add_order_details_right_side {
  height: calc(100vh - 179px);
  overflow-y: auto;
  max-width: calc(100vw - 420px) !important;

  @media (max-width: 768px) {
    max-width: calc(100vw - 317px) !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $blue-sky-light;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgba($blue-primary, 0.3);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

.order_option_details {
  margin-left: auto;
  padding: 30px;

  @media (max-width: 580px) {
    width: 100%;
  }
  @media (min-width: 767px) {
    max-width: calc(100vw - 400px);
  }
  @media (min-width: 581px) and (max-width: 768px) {
    max-width: calc(100vw - 300px);
    padding: 20px;
  }
}

.order_details_text {
  width: 100%;
  & > textarea {
    border: 1px dashed rgba($blue-primary, 0.7);
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    resize: none;
  }
  & > .order_details_text_content {
    @include flexbox();
    @include align-items(center);

    & > h3 {
      font-family: $font;
      color: black;
      font-size: 16px;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    & > svg {
      color: $blue-primary;
      cursor: pointer;
    }
  }
  & > p,
  & > textarea {
    font-family: $font;
    color: $dark-gray;
    font-weight: 300;
    font-size: 14px;
  }
}

.articles_list_header {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;

  & > button {
    background: $blue-primary;
    border-radius: 5px;
    border: none;
    color: white;
    font-family: $font;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
  }

  & > h3 {
    font-family: $font;
    color: black;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.articles_list {
  & > .ant-collapse {
    background: white;
    border-radius: 5px;
    margin: 10px 0;
  }

  & > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600 !important;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.article_option_item {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  cursor: pointer;

  background: $blue-sky-light;
  border-radius: 5px;
  padding: 20px 10px;
  width: 100%;
  margin: 10px 0;

  & > div > h2 {
    color: $blue-primary;
    font-size: 14px;
    margin: 0;
  }
}

.add_new_option_text {
  color: #bcbcbd;
  font-weight: bold;
  font-size: 16px;
  font-family: $font;
  text-align: center;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.edit_order_name_input {
  background: transparent;
  font-weight: 100;
  font-family: $font;
  color: black;
  margin-right: 15px;
  font-size: 30px;
  border: 1px dashed rgba($blue-primary, 0.6);
  border-radius: 5px;
  outline: none;
  width: 100%;
  @media (max-width: 575px) {
    font-size: 20px;
  }
}

.popover_edit_order {
  & > p {
    margin-bottom: 5px;
    font-family: $font;
    color: $dark-gray;
    font-weight: 600;
    padding: 0 10px;
    cursor: pointer;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    &:hover {
      background: rgba(194, 194, 194, 0.24);
    }
  }
}
.ant-popover-inner {
  border-radius: 6px !important;
}
.ant-popover-inner-content {
  padding: 5px 0 !important;
  border-radius: 10px !important;
}
.ant-popover-message,
.ant-popover-buttons {
  padding: 5px 10px !important;
}
.option_input_update_wrapper {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  width: 90%;

  & > svg {
    margin-left: 15px;
    color: $blue-primary;

    &:hover {
      background: rgba($blue-primary, 0.1);
      border-radius: 50%;
    }
  }

  .option_input_update {
    background: transparent;
    border: none;
    font-family: $font;
    color: $blue-primary;
    outline: none;
    width: 100%;
  }
}

// .add_collab_popover {
//   @include flexbox();
//   @include align-items(center);
//   position: absolute;
//   background: white;
//   box-shadow: 0px 2px 6px rgba(#000000, 0.2);
//   width: 300px;
//   height: 40px;
//   top: 125%;
//   right: 0%;
//   border-bottom-right-radius: 5px;
//   border-bottom-left-radius: 5px;
//   padding: 0 0 0 5px;
//   overflow: hidden;

//   & > input {
//     width: 80%;
//     height: 100%;
//     border: none;
//     outline: none;
//     font-family: $font;
//     &::placeholder {
//       opacity: 0.5;
//       text-transform: capitalize;
//     }
//   }
//   & > button {
//     height: 100%;
//     width: 20%;
//     outline: none;
//     border: none;
//     transition: all 0.2s ease-in-out;
//     cursor: pointer;
//     &:focus {
//       outline: none;
//     }
//     &:hover {
//       background: $light-gray;
//     }
//   }
// }

.add_collab_popover {
  // width: 325px !important;
  @include flexbox();
  @include align-items(center);

  background: transparent;
  width: 100% !important;
  height: 50px;
  padding: 0 0 0 5px;
  overflow: hidden;
//  border: 1px solid rgba($blue-primary, 0.1);
  margin: 0 0px 0 10px;
  border-radius: 5px;
  & > input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-family: $font;
    background: transparent;
    border-radius: 10px;
    padding-left: 10px;
    &::placeholder {
      opacity: 0.5;
      text-transform: capitalize;
    }
  }
  & > button {
    width: 50px;
    height: 32px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    background: $blue-primary;
    color: white;
    // border-radius: 50%;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:hover {
      background: $blue-sky;
      color: $blue-primary;
    }
  }
}

.see_more_collab_container {
  margin-right: 5px;
  margin-left: 5px !important;
  .see_more_collab {
    margin-left: 5px !important;
    border-style: solid !important;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    height: 100%;
    & > span {
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 20px;
      font-family: $font;
    }
  }
}

.ant-drawer-body {
  padding: 0 !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $blue-sky-light;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgba($blue-primary, 0.3);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  & > .collab_drawer_header {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(flex-start);
    background: white;
    padding: 20px;
    // position: sticky;
    position: fixed;
    bottom: 0;
    z-index: 100;
     width: 100%;
  //  width: 24%;
  }
  // & > .add_collab_popover {
  //   width: 100%;
  // }
  & > .add_collab_popover {
    align-self: stretch !important;
  }
  & > .collab_full_list {
    padding: 20px 20px 0 20px;
    margin-bottom: 140px;
  }
  & > div > h2 {
    text-transform: capitalize;
    font-size: 15px;
    font-family: $font;
  }
  // & > h2 {
  //   text-transform: capitalize;
  //   font-size: 20px;
  //   font-family: $font;
  // }
}
.ant-drawer-mask {
  background: transparent;
}

.add_collab_popover_drawer {
  width: 100%;

  & > input {
    width: 100%;
  }
}

.new_collab_loading {
  border: none !important;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  background: $light-gray;

  @media (max-width: 800px) {
    width: 35px;
    height: 35px;
  }
}

.new_collab {
  object-fit: cover;
}

.back_btn_order_details {
  border: none;
  background: transparent;
  border-radius: 5px;
  padding: 10px 15px;
  outline: none !important;
  @include transition(all, 0.2s, ease-in-out);

  &:hover {
    background: rgba($light-gray, 0.4);
  }
}
