@import "../../sass/variables.scss";
.footer {
  width: 100%;
  margin-top: 150px;
}
.footer_nav {
  min-height: 274px;
  background-color: $blue-primary;
  padding: 40px 3%;

  @media (min-width: 450px) {
    // flex-direction: row-reverse;
    min-height: 274px;
    background-color: $blue-primary;
    padding: 40px 3%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }

  .nav_item_container {
    margin-top: 10px;
    color: white;
    margin: 0 10px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    .title_container > h3 {
      color: white;
      font-size: 24px;
      @media (max-width: 560px) {
        font-size: 16px;

      }
    }
  }
  .nav_item_container_contact {
    @media (max-width: 616px) {
      order: 10;
    }
  }
}
.nav_item_container_menu {
  .menu_nav_item {
    @media (max-width: 1152px) {
      flex-direction: column;
    }

    .mmenu_nav_items_second {
      @media (max-width: 1152px) {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 968px) {
  }
}
.link_footer {
  color: white;
  &:hover {
    color: white;
  }
}
.nav_items {
  padding-left: 83px;
  font-size: 16px;
  margin-top: 15px;
  @media (max-width: 1200px) {
    padding-left: 63px;
  }
  @media (max-width: 992px) {
    padding-left: 43px;
  }
  @media (max-width: 560px) {
    font-size: 14px;

  }
}
@media (max-width: 560px) {
  .menu-footer-responsive{
    display: flex;
    gap: 8px;
  }
}
.nav_item {
  margin-bottom: 0 !important;
  font-weight: 600;
  line-height: 30px;
}
.title_container {
  display: flex;
  align-items: center;
  h3 {
    @media (max-width: 616px) {
      font-size: 1.4rem;
    }
  }
}
.white_line {
  min-width: 50px;
  max-width: 50px;
  min-height: 5px;
  background-color: white;
  margin-right: 30px;
  // display: inline;
  @media (max-width: 1200px) {
    margin-right: 20px;
    min-height: 3px;
    min-width: 40px;
    max-width: 40px;
  }
  @media (max-width: 992px) {
    margin-right: 10px;
    min-height: 3px;
    min-width: 30px;
    max-width: 30px;
  }
}

.footer_all_right_reserved {
  height: 57px;
  padding: 0 3%;
  background-color: #f3f6ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    @media (max-width: 640px) {
      margin: 10px 0;
    }
  }
  @media (max-width: 640px) {
    flex-direction: column;
    height: 100px;
    justify-content: space-around;
    align-items: center;
    .reserved {
      order: 2;
      // margin-top: 10px;
    }
  }
}
.menu_nav_item {
  display: flex;
}
.moon_link {
  text-decoration: none;
  color: black;
  &:hover {
    text-decoration: none;
  }
}
