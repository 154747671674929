@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.notifications_aside {
  @include flexbox();
  @include flex-direction(column);
  height: 100%;
  width: 100%;
  header {
    @include align-self(flex-end);
  }
  h1 {
    text-transform: uppercase;
  }
  .h2_subtitle_black_notification_title{
    font-size: 24px !important;
    font-weight: 600;
    @media (max-width: 786px) {
      font-size: 16px !important;
      font-weight: 600;
    }
  }

  main {
    height: 85%;
    overflow-y: scroll;

    @media (max-height: 800px) {
      min-height: 60%;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
}

.text_notification{
  font-size: 16px !important;
  font-weight: 600;
  color: #7D7E72 !important;
  @media (max-width: 786px) {
    font-size: 12px !important;
    font-weight: 600;
  }
}
//  SELECTION NOTIFICATION
.notif_selection {
  @include flexbox();



  .notif_imgs {
    position: relative;

    img {
      width: 95px;
      border-radius: 5px;
    }
    & img:last-child {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .notif_details {
    margin-left: 1.4rem;
    font-family: $font;
    .h4_medium {
      font-weight: 600;
    }
    .selection_date {
      font-weight: 200;
      color: $dark-gray;
      font-size: 14px;
    }
  }
}
.notif_selection_check {
  svg {
    font-size: 40px;
    width: 40px;
    height: 40px;
    fill: #00468c;

    @media (max-width: 450px) {
      width: 25px;
      height: 25px;
    }
  }
}

// COLLECTION NOTIFICATION
.notif_collection {
  @include flexbox();
  @include align-items(center);
  height: 177px;
  margin-top: 35px;
  .notif_icon {
    height: 100%;
    @include flexbox();
    @include align-items(center);
    padding: 0px 70px 0 70px;
  }
}

// ARTICLE NOTIFICATION
.notif_article {
  @include flexbox();
  margin-bottom: 20px;
  font-family: $font;

  .notif_img img {
    width: 138px;
  }
  .notif_details {
    @include flexbox();
    @include flex-direction(column);
    margin-left: 20px;

    h3 {
      font-size: 15px;
    }

    .selection_date {
      color: $dark-gray;
      font-size: 12px;
    }
  }
}

.myselection_see_all > a > button {
  padding: 10px !important;
  width: 100%;
}
.notif_selection {
  & > .lazy-load-image-background {
    min-width: fit-content !important;
  }
}
.notif_added_prod_container:hover > .notif_selection > div:last-child > h2 {
  color: $blue-primary;
}
.notif_added_prod_container:hover > .notif_selection > .notif_details > h2 {
  color: $blue-primary;
}
.notif_added_prod_container:hover {
  text-decoration: none;
}
.added_product_img {
  // height: 185px;
  width: 5.5rem;
  border-radius: 5px;
  max-height: 155px;
  object-fit: contain;
}

.order_changes {
  & > svg {
    width: 32px;
    height: 32px;
  }
}

.btns_access_drawer {
  opacity: 0;
  transform: translateY(-20px);
  height: 0px;
  transition: all 0.2s ease-in-out;
}
.notif_added_prod_container:hover .btns_access_drawer {
  transform: translateY(0px);
  height: auto;
  opacity: 1;
}
