@import "./mixins.scss";
@import "./variables.scss";

.notification_settings {
  padding-top: 110px;

  width: 85%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }

  .account_header {
    @include flexbox();
    @include align-items(center);
    margin: 50px 0;

    .account_header_text {
      & > p {
        margin: 0;
        margin-left: 20px;
      }

      & > .user_info {
        font-family: $font;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 5px;
        color: $blue-primary;
        @media (max-width: 782px) {
          font-size: 20px;
        }
      }

      & > .user_info > .personnal_info {
        color: $blue-primary;
      }

      .notif_header_msg {
        font-family: $font;
        font-weight: 300;
        opacity: 0.9;
        font-size: 16px;

        @media (max-width: 782px) {
          font-size: 12px;
        }
      }
    }
  }
  .company_logo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.settings_wrapper_header {
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  padding-bottom: 10px;
  color: $blue-primary;

  .h2_subtitle_blue {
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  /*  @media (min-width: 1024px) {
      font-size: 16px;
    }
    @media (min-width: 1200px) {
      font-size: 16px;
    }*/
  }
}

.header_table_notification{
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.body_table_notification{
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.settings_wrapper {
  @include flexbox();
  @include flex-direction(column);

  .settings_wrapper_body {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);
    margin-top: 30px;

    & > h3 {
      font-size: 20px;
      font-weight: 600;
      font-family: $font;
    }

    & > .settings_wrapper_body_title {
      @include flexbox();
      @include justify-content(space-between);

      & > p {
        margin: 0;
        cursor: pointer;
        color: $blue-primary;
        white-space: nowrap;
      }
    }
    & > .settings_wrapper_body_title > h3 {
      font-size: 20px;
      font-weight: 600;
      font-family: $font;
    }

    & > .settings_wrapper_option {
      @include flexbox();
      @include justify-content(space-between);

      & > label {
        font-family: $font;
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}

.settings_wrapper_body {
  .ant-switch-checked {
    background: $blue-primary;
  }
}
