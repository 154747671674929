@import "./mixins.scss";

.btn-action {
  color: #03468c;
  border: 1px solid #03468c;
  background: white;
  border-radius: 5px;
}
.btn-action-active {
  color: white;
  border: 1px solid #182844;
  background: #182844;
  border-radius: 5px;
}

.active-border {
  border: 2px solid #182844;
  border-radius: 16px;
}

.ant-drawer-bottom.ant-drawer-open.height-drawer-custom
  .ant-drawer-content-wrapper {
  height: 100% !important;
}
.inactive {
  border: 2px solid #cbcbcb;
  border-radius: 16px;
}

.side-customization {
  height: 100vh;
  overflow-y: scroll;
  top: -8px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    // margin-left: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgb(161, 161, 161);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

.tool-btn {
  width: fit-content;
  cursor: pointer !important;
  background: #ffffff !important;
  padding: 7px 10px !important;
  border: 1px solid #00468c !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  color: #00468c !important;
  font-family: "Poppins" !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
  outline: none !important;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  border-radius: 37px !important;
}

.tool-btn:hover {
  width: fit-content;
  cursor: pointer !important;
  background: #00468c !important;
  padding: 7px 10px !important;
  border: 1px solid #00468c !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  color: #ffffff !important;
  font-family: "Poppins" !important;
  font-weight: 300 !important;
  font-size: 1rem !important;
  outline: none !important;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  border-radius: 37px !important;
}

.input-color-container {
  width: 3px;
  height: 30px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #d4d9e2;
  // border: solid 2px #ddd;
  // border-radius: 40px;
}
.input-color {
  appearance: none;
  background: transparent;
  border: none;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.icons {
  font-size: 20px;
  color: #00468c;
}
.rangeColor {
  accent-color: #182844;
}

.btn-tool-bar {
  border: 1px solid #eef2f6;
  padding: 10px;
  border-radius: 100%;
  background: white;
  color: #00468c;
}

.btn-tool-bar-active {
  border: 2px solid #09101B;
  padding: 10px;
  border-radius: 100%;
  background: white ;
  color: #00468c;
}


.turntable {
  display: inline-block;
  margin: 0px;
}
.turntable ul {
  padding: 0px;
  margin: 0px;
}
.turntable ul li {
  list-style-type: none;
  display: none;
}
.turntable ul li img {
  width: 100%;
}
.turntable ul li.active {
  display: block;
}
.select-opacity#xyz {
  border: none;
}

.option-hidden{
  display: none;
}

.option-display{
  display: inline;
}
.faceCustom{



  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    width:130px !important;
  }


  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    width:150px;  }

  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    width:200px;  }

  @media only screen and (min-width: 1366px) and (max-width: 1599px) {
    width:200px;  }

  @media only screen and (min-width: 1600px) {
    width:200px;  }



}
#text-input-front {
  padding: 8px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
#text-input-back {
  padding: 8px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
#custom-select > .ant-select > .ant-select-selector {
  border-radius: 5px;
}
