@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.divCol {
  background: #f7f7f7;
  height: 78vh;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  overflow: hidden;
}

.divCol2 {
  background: #f7f7f7;
  height: 72vh;
  padding: 18px;
}

.divCol2-closed {
  background: #f7f7f7;
  height: 0vh;
  padding: 18px;
}
.size_title{
  font-size: 16px;
  @media (max-width: 800px) {
    font-size: 12px !important;
  }
}
.divColHead {
  height: 50px;
  background-color: #e4e4e4;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.2px;
}
.title_model_mycollecion{
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
  @media (max-width: 800px) {
    font-size: 16px !important;
  }
}
.discard-mycollecion{
  padding-top: 13px !important;
  padding-bottom: 12px !important;
}
.label_drawer_mycollection{
  font-size: 16px !important;
  font-weight: 400 !important;
  @media (max-width: 800px) {
    font-size: 12px !important;
  }
}
.divCol_0{
  background: #f7f7f7;
  height: 7vh !important;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  overflow: hidden;
  cursor: pointer;
}
.btndivColHead {
  width: 32px;
  height: 32px;
  background-color: $blue-primary;
  border-radius: 8px;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}
.btndivColHead:hover{
   width: 32px;
  height: 32px; 
  color:  $blue-primary !important;
  background-color: white;
 border-radius: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid $blue-primary;
}
