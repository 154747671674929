@import "../sass/mixins.scss";
@import "../sass/variables.scss";
.account_info {
  height: 100vh !important;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  .alerts_position {
    position: absolute;
    top: 120px;
    right: 10px;
  }
}
.margin_acount_filed{
  margin-bottom: 92px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px !important;
  }
}
.account_info_container {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  width: 100%;
  min-height: 90vh;
  background-color: white;
  padding: 0;
  padding-top: 175px;
  // padding-bottom: 110px;
  @include flexbox();
  @include align-items(flex-start);
  @include justify-content(space-between);
  @include flex-direction(column);
  @media screen and (max-width: 767px) {
   /* padding-top: 280px;*/
  }
  @media screen and (max-width: 575px) {
  /*  padding-top: 380px;*/
    padding-right: 6px;
    padding-left: 6px;
   justify-content: center;

  }
  button {
    margin: 30px auto;
    margin-bottom: 30px;
  }
  .account_header {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    font-size: 20px;
    font-weight: 700;
    @media screen and (max-height: 700px) {
      font-size: 16px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    .account_header_img {
      height: 70px;
      width: 70px;
      overflow: hidden;
      margin-right: 22px;
      background-size: cover;
      background-position: center;
      @include border-radius(50%);
      @media screen and (max-height: 700px) {
        height: 55px;
        width: 55px;
      }
    }
    p {
      margin-bottom: 0;
      .personnal_info {
        font-size:32px !important;
        color: $blue_primary;
        @media screen and (max-width: 700px) {
          font-size: 20px !important;
        }
      }
    }
  }
  .account_infos_title{
    font-size: 24px;
    text-transform: uppercase;
    @media screen and (max-width: 700px) {
      font-size: 16px !important;
    }
  }
  .account_form_container {
    @include flexbox();
    @include align-items(flex-start);
    @include justify-content(space-between);
    @media screen and (max-height: 700px) {
      font-size: 16px;
    }
    .account_infos > h2,
    .account_login_location > .account_login_infos > h2,
    .account_login_location > .location_shipping > h2 {
      font-size: 16px;
      font-weight: 700;
      color: $blue_primary;
      margin-bottom: 27px;
      @media screen and (max-height: 700px) {
        font-size: 22px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 12px;
        margin-bottom: 15px;
      }
    }
    .col-6 {
      padding: 0;
    }
    label {
      color: grey;
    }
    input {
      letter-spacing: 1.4px;
      font-weight: 400;
      font-size: 16px;
      @media screen and (max-width: 786px) {
        font-size: 12px;
      }

    }
    .account_infos {
    }
    .account_login_location {
      .account_login_infos {
        margin-bottom: 33px;
        @media screen and (max-height: 700px) {
          margin-bottom: 64px;
        }
        .reset_password_button {
          text-align: end;
          @media screen and (max-width: 767px) {
            text-align: left;
          }
          .reset_password {
            text-decoration: underline;
            font-size: 16px;
            color: #7D7E72;
            font-weight: normal;
            padding-left: 0;
            padding-right: 0;
            cursor: pointer;
            margin-bottom: 46px;
            margin-top: 23px;
            @media screen and (max-width: 767px) {
              font-size: 12px;
              margin-bottom: 35px;
            }
          }
          .submit_button_white_reset_password {
            padding: 12px 49px;
            @media screen and (max-height: 700px) {
              font-size: 16px;
              padding-left: 0;
              padding-right: 0;
              width: 180px;
              padding-top: 14px;
              padding-bottom: 14px;
            }
          }
        }
      }
    }
    // .account_login_location {
    //   padding-left: 60px;
    // }

    .account_infos {
      padding-right: 60px;
      @media screen and (max-width: 767px) {
        padding-right: 16px;
      }
    }
  }
  .submit_button_blue_save {
    font-size: 20px;
    padding-left: 0;
    padding-right: 0;
    width: 260px;
    align-self: flex-end;
    @media screen and (max-height: 700px) {
      font-size: 16px;
      width: 180px;
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  .confirm_update_account_btn {
    border-radius: 5px;
    background: $blue-primary;
    border-color: $blue-primary;
    color: white;
    height: 45px;
    width: 200px;
    font-family: $font;
    font-size: 16px;
    margin-left: 20px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      height: 35px;
      width: 180px;
    }
  }

  .confirm_update_account_btn:hover {
    border-color: $blue-primary;
    color: $blue-primary;
  }
}

.upload_avatar_update_profil {
  display: none;
  border-bottom: 1px solid gray;
}
.label_avatar_update_profil {
  border-bottom: 1px solid #ccc;
  display: inline-block;
  padding: 2px 12px;
  cursor: pointer;
  padding-left: 0;
  font-size: 16px;
  font-weight: normal;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    font-weight: normal;
  }
}

.field {
  & > .select_division > .ant-select-selector {
    padding-left: 0 !important;
    border: none !important;
    border-bottom: 1px solid #ccc !important;
    font-size: 1.1rem;
    font-family: $font;

    box-shadow: none !important;
  }
}
