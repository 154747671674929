@import "./mixins.scss";
@import "./variables.scss";

.h1-blue-hj {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  color: #03468c;
  text-transform: uppercase;
}
.h2-blue-hj {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  color: #03468c;
  margin-top: 20px;
  margin-bottom: 20px;
}
.title-drawer-booking
{
  font-size:24px  !important;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }

}
.label-booking{
  font-size:16px  !important;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }

}
.title-schedule-meeting{
  display: flex;
  text-align: center;
  align-items: center;
flex-direction: column;
  padding: 25px;
    span {
      font-weight: 400;
      text-transform: capitalize;
      font-size: 32px;
      color: $blue-primary;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
}
.bookings-page {
  padding-top: 109px;

  background-color: #fcfdff;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding-top: 88px;
  }
  .ant-picker-calendar-header {
    @include justify-content(flex-start !important);
    .ant-select-selector {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      border: none !important;
    }
    .ant-radio-button-wrapper {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      border: none !important;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $blue-primary !important;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      border: none !important;
    }
  }
  .bookings-container {
    background: "white";
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    border-radius: 5px;
    border: 1px solid $light-gray;
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    .right-calendar-container {
      flex: 0.75;
      padding: 20px;
    }
  }
}
.submit-booking-btn {
  margin: 5%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  background: $blue-primary;
  color: white;
  border-radius: 5px;
  min-width: 90%;
  font-size: 16px;
  padding: 10px;
  width: auto;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  border: 1px solid $blue-primary;
  &:focus {
    outline: none;
  }
}

.submit-booking-white-btn {
  margin: 5%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  background: white;
  color: $blue-primary;
  border-radius: 5px;
  min-width: 90%;
  font-size: 16px;
  padding: 10px;
  width: auto;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  border: 1px solid $blue-primary;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: $blue-primary;
    color: white;
  }
  &:focus {
    outline: none;
  }
}

.left-calendar-container {
  flex: 0.25;
  padding: 20px;
  padding-top: 0;
  border-right: 1px solid $light-gray;
  background-color: #fcfdff;
}

.hj-max-width-container {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  @media (min-width: 992px) {
    max-width: 990px;
  }
  @media (min-width: 1100px) {
    max-width: 1100px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
  @media (min-width: 1366px) {
    max-width: 1366px;
  }
  @media (min-width: 1600px) {
    max-width: 1600px;
  }
  @media (min-width: 1920px) {
    max-width: 1900px;
  }
}

.reserved-cell-date {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  background: #ffeaea;
  flex: 1;
  width: 100%;
  height: 100%;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  color: red;
  font-family: "Poppins", sans-serif;
  &.foryou {
    background: #eaffee;
    color: #009322;
  }
  &.small {
    background: #269a41;
    color: white;
    &.foryou {
      background: #de8f8f;
      color: black;
    }
  }
}

.rbc-day-bg:hover {
  background: rgba($blue-primary, 0.2);
  cursor: pointer;
}
.rbc-day-bg {
  transition: all 0.2s ease-in-out;
}

.booking-drawer > .ant-drawer-mask {
  background: rgba(0, 0, 0, 0.227) !important;
}
.booking-drawer .ant-drawer-body {
  background: #fcfdff !important;
}

.rbc-day-bg:hover {
  background: rgba($blue-primary, 0.05) !important;
}
.proccessing_dot > .ant-badge-status-processing {
  left: unset !important;
  right: -15px !important;
  top: 5px !important;
}

.ant-badge-status-text {
  margin-left: 0px !important;
}

.table_bookings_admin
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table-pagination-right {
  padding-right: 15px;
}

.goto_btn {
  color: $blue-primary;
  font: $font;
  font-weight: 400;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: $blue-primary;
  }
}

.meeting_notes > .ant-comment-inner > .ant-comment-content {
  @include flex-direction(column);
}
.meeting_notes > .ant-comment-inner {
  @include align-items(flex-start);
}
.simple-blue-btn {
  background: #00468c;
  color: white;
  border-radius: 5px;
  &:disabled {
    background: gray;
  }
}

.demco_drawer_confirmation_logo > img {
  object-fit: contain;
}

.reschedule-btn-drawer {
  @include flex(1);
  border-radius: 5px;
  border-color: $blue-primary;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.cancel-btn-drawer{
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.reschedule-btn-drawer.confirm {
  background: $blue-primary;
  color: white;
}
.reschedule-btn-drawer.discard {
  color: $blue-primary;
  background: white;
}
.reschedule-btn-drawer.discard:hover {
  border-color: $blue-primary;
}
.reschedule-btn-drawer.confirm:hover {
  background: #01315e;
  border-color: $blue-primary;
}
