@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.search {
  background: white;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1000;

  main {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    height: 100vh;
  }

  .search_input {
    border: none;
    font-family: $font;
    font-weight: 300;
    outline: none;
    font-size: 50px;
    width: 100%;
    text-align: center;
    &::placeholder {
      color: $light-gray;
    }
    @media (max-width: 425px) {
      font-size: 25px;
    }
    @media (min-width: 426px) {
      font-size: 30px;
    }
    @media (min-width: 768px) {
      font-size: 40px;
    }
    @media (min-width: 992px) {
      font-size: 45px;
    }
    @media (min-width: 1100px) {
      font-size: 50px;
    }
  }

  & > main > .search_list {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include align-items(center);
    width: 100%;
    & > a {
      color: black;
    }
    & > a:hover {
      text-decoration: none;
    }

    a,
    h2 {
      font-size: 18px;
    }
    a {
      margin-top: 30px;
    }
  }

  .search_list_item {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-start);
    overflow: hidden;
    padding: 0px;
    padding-left: 0;
    background: white;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 390px;
    height: 55px;
    // max-width: 75%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    & > p {
      margin-bottom: 0;
      font-family: $font;
      margin-left: 15px;
    }
    & > img {
      object-fit: cover;
      height: 55px;
      width: 55px;
    }

    &:hover {
      opacity: 0.8;
      transform: translateY(-2px);
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    }
  }
  .msg_zero_result {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    .zero_result {
      font-size: 50px !important;
      color: $blue-primary;
      text-transform: capitalize;
      text-align: center;
      @media (max-width: 425px) {
        font-size: 30px !important;
      }
      @media (min-width: 768px) {
        font-size: 40px !important;
      }
      @media (min-width: 992px) {
        font-size: 45px !important;
      }
      @media (min-width: 1100px) {
        font-size: 50px !important;
      }
    }

    & > p {
      font-size: 35px !important;
      color: $blue-primary;
      text-transform: capitalize;
      text-align: center;
      @media (max-width: 425px) {
        font-size: 20px !important;
      }
      @media (min-width: 768px) {
        font-size: 25px !important;
      }
      @media (min-width: 992px) {
        font-size: 30px !important;
      }
      @media (min-width: 1100px) {
        font-size: 35px !important;
      }
    }
  }
}
