@import "../../sass/typography.scss";
@import "../../sass/mixins.scss";

.dropzone {
  background: #0972ce12;
  border: 1px dashed $blue-primary;
  width: 100%;
  height: 100% !important;
  min-height: 52px;
  // position: absolute;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(5px);

  & > p {
    margin: 0;
  }
  outline: none !important;
}
.dropzone_wrapper {
  background: #0972ce12;
  border: 1px dashed $blue-primary;
  width: 100%;
  height: 52px !important;
  position: relative;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(5px);

  & > p {
    margin: 0;
  }
}

.dropzones {
  width: 100%;
  height: 100% !important;
  position: absolute;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(5px);

  & > p {
    margin: 0;
  }
  outline: none !important;
}
