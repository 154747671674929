@import "./variables.scss";
@import "./mixins.scss";
//typo
h2,
h1,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.h1_title {
  font-family: $font;
  @include heading-one-style($h1_font_weight_light);
  color: $blue-primary;
  // line-height: 3.3rem;
  @media (max-width: 768px) {
    font-size: $h1-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 992px) {
    font-size: $h1-font-xs;
    // line-height: 4.2rem;
  }
  @media (min-width: 1100px) {
    font-size: $h1-font-s;
    // line-height: 4.3rem;
  }
  @media (min-width: 1200px) {
    font-size: $h1-font-sm;
    // line-height: 4.6rem;
  }
  @media (min-width: 1366px) {
    font-size: $h1-font-m;
    // line-height: 4.8rem;
  }
  @media (min-width: 1600px) {
    font-size: $h1-font-l;
    // line-height: 5rem;
  }
  @media (min-width: 1920px) {
    font-size: $h1-font-xl;
    // line-height: 5.1rem;
  }
}
.h1_title_bold {
  font-weight: bold;
}
.h1_huge {
  font-family: $font;
  font-weight: bold;
  color: $blue-primary;
  // line-height: 3.3rem;
  @media (min-width: 992px) {
    font-size: $huge-h1-font-xs;
    // line-height: 4.2rem;
  }
  @media (min-width: 1100px) {
    font-size: $huge-h1-font-s;
    // line-height: 4.3rem;
  }
  @media (min-width: 1200px) {
    font-size: $huge-h1-font-sm;
    // line-height: 4.6rem;
  }
  @media (min-width: 1366px) {
    font-size: $huge-h1-font-m;
    // line-height: 4.8rem;
  }
  @media (min-width: 1600px) {
    font-size: $huge-h1-font-l;
    // line-height: 5rem;
  }
  @media (min-width: 1920px) {
    font-size: $huge-h1-font-xl;
    // line-height: 5.1rem;
  }
}
.h1_huge_light {
  font-family: $font;
  font-weight: 300;
  color: $white;
  // line-height: 3.3rem;
  @media (min-width: 992px) {
    font-size: $huge-h1-font-xs;
    // line-height: 4.2rem;
  }
  @media (min-width: 1100px) {
    font-size: $huge-h1-font-s;
    // line-height: 4.3rem;
  }
  @media (min-width: 1200px) {
    font-size: $huge-h1-font-sm;
    // line-height: 4.6rem;
  }
  @media (min-width: 1366px) {
    font-size: $huge-h1-font-m;
    // line-height: 4.8rem;
  }
  @media (min-width: 1600px) {
    font-size: $huge-h1-font-l;
    // line-height: 5rem;
  }
  @media (min-width: 1920px) {
    font-size: $huge-h1-font-xl;
    // line-height: 5.1rem;
  }
}

.h2_subtitle {
  font-family: $font;
  font-weight: bold;
  color: $dark-gray;
  font-size: 1.2rem;
  // line-height: 1.4rem;
  @media (max-width: 768px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 992px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 1100px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (min-width: 1200px) {
    font-size: $h2-font-sm;
    // line-height: 1.6rem;
  }
  @media (min-width: 1366px) {
    font-size: $h2-font-m;
    // line-height: 1.6rem;
  }
  @media (min-width: 1600px) {
    font-size: $h2-font-l;
    // line-height: 1.7rem;
  }
  @media (min-width: 1920px) {
    font-size: $h2-font-xl;
    // line-height: 1.8rem;
  }
}

.h2_subtitle_black {
  font-family: $font;
  font-weight: bold;
  color: $black;
  font-size: 1.2rem;
  // line-height: 1.4rem;
  @media (max-width: 768px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 992px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 1100px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (min-width: 1200px) {
    font-size: $h2-font-sm;
    // line-height: 1.6rem;
  }
  @media (min-width: 1366px) {
    font-size: $h2-font-m;
    // line-height: 1.6rem;
  }
  @media (min-width: 1600px) {
    font-size: $h2-font-l;
    // line-height: 1.7rem;
  }
  @media (min-width: 1920px) {
    font-size: $h2-font-xl;
    // line-height: 1.8rem;
  }
}

.h2_subtitle_light_black {
  display: inline-block;
  -webkit-transform: scale(1, 1.05);
  -moz-transform: scale(1, 1.05);
  -ms-transform: scale(1, 1.05);
  -o-transform: scale(1, 1.05);
  transform: scale(1, 1.05);
  font-family: $font;
  font-weight: 300;
  color: $black;
  font-size: 1.1rem;
  // line-height: 1.4rem;
  @media (max-width: 768px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 992px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (min-width: 1100px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (min-width: 1200px) {
    font-size: $h2-font-sm;
    // line-height: 1.6rem;
  }
  @media (min-width: 1366px) {
    font-size: $h2-font-m;
    // line-height: 1.6rem;
  }
  @media (min-width: 1600px) {
    font-size: $h2-font-l;
    // line-height: 1.7rem;
  }
  @media (min-width: 1920px) {
    font-size: $h2-font-xl;
    // line-height: 1.8rem;
  }
}
.h2_subtitle_blue_login_2 {
  font-family: $font;
  font-weight: 600;
  color: $blue-primary;
  font-size: 24px;

  // line-height: 1.4rem;
  @media (max-width: 768px) {
    font-size: 16px !important;
    // line-height: 1.5rem;
  }

}


.h2_subtitle_blue {
  font-family: $font;
  font-weight: bold;
  color: $blue-primary;
  font-size: 3.375rem;

  // line-height: 1.4rem;
  @media (max-width: 768px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (max-width: 992px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (max-width: 1100px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (max-width: 1200px) {
    font-size: $h2-font-sm;
    // line-height: 1.6rem;
  }
  @media (max-width: 1423px) {
    font-size: $h2-font-m;
    // line-height: 1.6rem;
  }
}
.h2_subtitle_light_blue {
  text-transform: uppercase;
  font-family: $font;
  font-weight: 300;
  color: $blue-primary;
  font-size: 3.375rem;
  // line-height: 1.4rem;
  @media (max-width: 768px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (max-width: 992px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (max-width: 1100px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (max-width: 1200px) {
    font-size: $h2-font-sm;
    // line-height: 1.6rem;
  }
  @media (max-width: 1423px) {
    font-size: $h2-font-m;
    // line-height: 1.6rem;
  }
}

.h2_subtitle_light_blue_login {
  text-transform: capitalize;
  font-family: $font;
  font-weight: bold;
  color: $blue-primary;
  font-size: 32px;
  // line-height: 1.4rem;
  @media (max-width: 768px) {
    font-size: 20px !important;
    // line-height: 1.5rem;
  }

}



.our_label {
  display: block;
  font-family: $font;
  font-weight: 700;
  color: $black;
  font-size: 32px;
  @media (max-width: 768px) {
    font-size: 20px !important;
    // line-height: 2.9rem;
  }

}

.our_label_title_2 {
  display: block;
  font-family: $font;
  font-weight: 700;
  color: $black;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    // line-height: 2.9rem;
  }

}

.h2_huge {
  display: block;
  font-family: $font;
  font-weight: 700;
  color: $black;
  font-size: 1.8rem;
  // line-height: 2.8rem;
  @media (max-width: 768px) {
    font-size: $huge-h2-font-xs;
    // line-height: 2.9rem;
  }
  @media (min-width: 992px) {
    font-size: $huge-h2-font-xs;
    // line-height: 3rem;
  }
  @media (min-width: 1100px) {
    font-size: $huge-h2-font-s;
    // line-height: 3.1rem;
  }
  @media (min-width: 1200px) {
    font-size: $huge-h2-font-sm;
    // line-height: 3.2rem;
  }
  @media (min-width: 1366px) {
    font-size: $huge-h2-font-m;
    // line-height: 3.3rem;
  }
  @media (min-width: 1440px) {
    font-size: $huge-h2-font-l;
    // line-height: 3.3rem;
  }
  @media (min-width: 1600px) {
    font-size: $huge-h2-font-xl;
    // line-height: 3.4rem;
  }
  @media (min-width: 1920px) {
    font-size: $huge-h2-font-xl + 0.2rem;
    // line-height: 3.5rem;
  }
}

.blue_moon {
  color: #0082ff;
}

.paragraphe {
  font-family: $font;
  // line-height: 1.4rem;
  color: $dark-gray;
  font-weight: 400;
  margin: 0;
  padding: 0;
  white-space: pre-line;
  // @media (min-width: 992px) {
  //   font-size: 1rem;
  //   // line-height: 1.6rem;
  // }
  @media (min-width: 1366px) {
    font-size: 1rem;
    // line-height: 1.8rem;
  }
  @media (min-width: 1600px) {
    font-size: 1.125rem;
    // line-height: 2.2rem;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.h3_huge {
  font-family: $font;
  font-weight: bold;
  color: $dark-gray;
  font-size: 2rem;
  // line-height: 2.5rem;
  @media (max-width: 768px) {
    font-size: 2rem;
    // line-height: 2.5rem;
  }
  @media (min-width: 992px) {
    font-size: 2rem;
    // line-height: 2.5rem;
  }
  @media (min-width: 1100px) {
    font-size: 2.1rem;
    // line-height: 2.6rem;
  }
  @media (min-width: 1200px) {
    font-size: 2.2rem;
    // line-height: 2.7rem;
  }
  @media (min-width: 1366px) {
    font-size: 2.3rem;
    // line-height: 2.8rem;
  }
  @media (min-width: 1600px) {
    font-size: 2.3rem;
    // line-height: 2.9rem;
  }
  @media (min-width: 1920px) {
    font-size: 2.4rem;
    // line-height: 3rem;
  }
}

.h4_medium {
  font-family: $font;
  font-weight: bold;
  color: $dark-gray;
  font-size: 1rem;

  // line-height: 2.2rem;
  @media (max-width: 992px) {
    font-size: 1rem;
    // line-height: 2.2rem;
  }
  @media (min-width: 992px) {
    font-size: 1rem;
    // line-height: 2.3rem;
  }
  @media (min-width: 1100px) {
    font-size: 1rem;
    // line-height: 2.4rem;
  }
  @media (min-width: 1200px) {
    font-size: 1rem;
    // line-height: 2.5rem;
  }
  @media (min-width: 1366px) {
    font-size: 1rem;
    // line-height: 2.6rem;
  }
  @media (min-width: 1600px) {
    font-size: 1.2rem;
    // line-height: 2.7rem;
  }
  @media (min-width: 1920px) {
    font-size: 1.2rem;
    // line-height: 2.8rem;
  }
}
