@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.myselection_aside {
  @include flexbox();
  @include flex-direction(column);
  height: 100%;
  width: 100%;
  header {
    @include align-self(flex-end);
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .h2_subtitle_black_wish_liste{
    font-size: 24px !important;
    font-weight: 600;
    @media (max-width: 786px) {
      font-size: 16px !important;
      font-weight: 600;
    }
  }

  .wish_list_link_code{
    font-size: 16px !important;
    font-weight: 600;
    @media (max-width: 786px) {
      font-size: 12px !important;
      font-weight: 600;

    }
  }

  .wish_list_link_name{
    font-size: 16px !important;
    font-weight: 400;
    @media (max-width: 786px) {
      font-size: 12px !important;
      font-weight: 400;
    }
  }

  main {
    // max-height: 85%;
    overflow-y: scroll;

    // @media (max-height: 800px) {
    //   max-height: 82% !important;
    //   // min-height: 100%;
    // }

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    .empty_selection_msg {
      color: $dark-gray;
      font-size: 22px;
      font-weight: 500;
    }
  }
}
.myselection_see_all {
  background: $white;
  position: sticky;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 20px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  a {
    // width: 50%;
    .btn_see_all {
      width: 100%;
      padding: 10px !important;
      font-size: 16px !important;
      @media (max-width: 786px) {
        padding: 10px 15px;
        font-size: 12px !important;
      }
    }
  }
}

.details_mysection {
  background: white;
  border: 1px solid #00468c;
  color: #00468c;
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
  transition: all 0.125s ease-in-out;
  margin-right: 15px;
}
.custom_mysection {
  background: #00468c;
  border: 1px solid #00468c;
  color: white;
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
  transition: all 0.125s ease-in-out;
}
.custom_mysection:hover {
  background: #00468c;
  border: 1px solid #00468c;
  color: white;
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
  transition: all 0.125s ease-in-out;
}
.myselection-img-product {
  width: 100px !important;
}
.selection_item {
  @include flexbox();
  margin-bottom: 20px;
  font-family: $font;
  & > span {
    min-width: fit-content;
  }
  & > div > div > span > .img_product_selection {
    max-width: 100%;
    height: auto;
    // min-width: 200px;
    // border-radius: 5px;
    @media (max-width: 575px) {
      max-width: 110px !important;
      min-width: 110px !important;
    }
  }
  & > span > .img_product_selection {
    max-width: 170px;
    min-width: 120px;
    height: auto;
    border-radius: 5px;
    @media (max-width: 575px) {
      max-width: 110px !important;
      min-width: 110px !important;
    }
  }

  .selection_details {
    @include flexbox();
    @include flex-direction(column);
    margin-left: 20px;
    .selection_btns {
      margin-top: auto;
    }

    .selection_btns {
      width: 100%;
      @include flexbox();
      @include align-items(center);

      button {
        // width: 50%;
        background: white;
        border: 1px solid $light-gray;
        padding: 0.8rem;
        outline: none;
        @include flexbox();
        @include justify-content(center);
        @include align-items(baseline);
        @media (max-width: 575px) {
          padding: 0.4rem;
        }

        p {
          margin: 0;
          margin-left: 5px;
        }
        svg {
          color: $blue-primary;
          height: 18px;
          width: 18px;
          transform: translateY(2px);
        }
        transition: all 0.125s ease-in-out;
        &:hover {
          background: rgba(233, 233, 233, 0.5);
        }
      }

      & button:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        // border-right: none;
      }
      & button:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .selection_date {
      font-size: 14px;
      font-weight: 200;
      color: $dark-gray;
    }
  }
}

.msg_order_sent {
  // background: #80808021;
  transform: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.delete-selection-btn-drawer {
  background: rgba(235, 235, 235, 0.575);
  border: none;
  border-radius: 8px;
  height: 35px;
  width: 35px;
  color: rgb(173, 1, 1);
}
.delete-selection-btn-drawer:hover {
  background: rgba(235, 235, 235, 0.9);
}
