@import "../sass/variables.scss";
@import "../sass/mixins.scss";

@media (min-width: 992px) {
  .menu-icon {
    display: none;
  }
  .side-content {
    padding: 15px 40px 20px 40px;
  }
  #side {
    width: 20%;
  }
  #pop:target + #side {
    width: 20%;
  }
  .container {
    width: 700px;
  }
  .navbar {
    width: 20%;
  }
  #side:target {
    width: 20%;
  }
  .container {
    width: 80%;
  }
  #main-doc {
    margin: 0 0 0 20%;
  }
}
@media (max-width: 992px) {
  .menu-icon {
    display: block;
  }
  .side-content {
    padding: 39px 20px 20px 20px;
  }
  #side {
    width: 0%;
  }
  #pop:target + #side {
    width: 0;
  }

  #side:target {
    width: 50%;
  }
  .container {
    margin: 0 0 0 0;
    width: 100%;
  }
}
.sidebar-ul {
  color: #2d3238;
  margin: 0 0 30px 0;
}
.sidebar-ul {
  padding: 0;
  margin-top: 0px;
  list-style-type: none;
}
nav {
  list-style: none;
}
a {
  color: #2d3238;
  text-decoration: none;
  font-weight: 400;
}
#main-doc h1 {
  color: #2d3238;
  font-weight: 600;
}
#main-doc {
  padding: 4px 20px 10px 20px;
}
#main-doc .sidebar-ul {
  padding-left: 30px;
}
#main-doc p,
#main-doc .sidebar-ul {
  line-height: 25px;
  letter-spacing: 0.03em;
}
.header-content {
  display: flex;
}
.navbar {
  width: 24px;
  position: relative;
  height: 24px;
}
.material-icons-sharp {
  color: #212427;
  font-family: $font;
}

.menu-icon {
  margin-top: 10px;
  margin-left: 17px;
  font-size: 28px;
  height: 28px;
  width: 28px;
}
#side {
  position: fixed;
  top: 100px;
  height: 90%;
  background: #00468c1a;
  border-right: solid #e4e6e9 1px;
  overflow: scroll;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }
}
#nav-header {
  margin: 0 0 20px;
  font-family: $font;
  font-weight: bold;
  color: $blue-primary;
  font-size: 1.375rem;
}

.underlined-item {
  font-weight: 300;
  font-family: $font;
  text-decoration: underline;
  margin: 0;
}

.library-btn {
  background: rgba(204, 204, 204, 0.212);

  & > img {
    filter: grayscale(1);
  }
}
.library-btn:hover,
.library-btn:focus,
.library-btn:active {
  border-color: $blue-primary !important;
  color: $blue-primary !important;
  & > img {
    filter: grayscale(0);
  }
}
.library-active {
  border-color: $blue-primary;
  color: $blue-primary;
  background: white;
  & > img {
    filter: grayscale(0);
  }
}

.ant-card-actions {
  border: 1px solid #f0f0f0;

  & > li {
    padding-bottom: 0 !important;
  }
}

.input-items-update {
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
  @include transition(all, 0.3s);
  &:focus {
    border-color: $blue-primary;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
  }
  &:hover {
    border-color: $blue-secondary;
  }
}

.library-pagination {
  background: #fcfdff;
  & > .ant-pagination-next > button,
  & > .ant-pagination-prev > button {
    background: $blue-primary !important;
    color: white;

    &:hover {
      border-color: $blue-primary !important;
    }
  }

  & > .ant-pagination-disabled > button {
    background: white !important;
    color: rgb(207, 207, 207);
  }

  & > .ant-pagination-item-active {
    border-color: $blue-primary !important;
    border-width: 2px;
  }
}

.fabric-details-container {
  background: #f4f7ff;
  border-top: 10px solid white;
  margin-top: -125px;
  min-height: 100vh;
  align-items: flex-start;
  flex-wrap: wrap;
}
.fabric-details-wrapper {
  @include flexbox();
  @include justify-content(space-around);
  @include flex-wrap(wrap);
  @media (max-width: 1130px) {
    @include flex-direction(column);
    @include align-items(center);
  }
}

.fabric-details-table {
  background: white;
  th,
  td {
    border: 1px solid #dcdbdc;
    font-family: $font;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  th {
    padding: 12px;
    // min-width: 185px !important;
    // width: 30%;
    // vertical-align: top;
    // white-space: nowrap;
  }
  td {
    padding: 12px;
    width: 20vw !important;

    @media (max-width: 1130px) {
      width: 400px;
    }
  }
  td {
    font-weight: 500;
  }
  table {
    border-collapse: collapse;
    margin-bottom: 30px;
  }

}

.fabric-details-table_2 {
  background: white;
  td {
    border: 1px solid #dcdbdc;
    font-family: $font;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }




}

.fabric-name-link {
  font-size: 16px;
  color: rgb(34, 33, 33);
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.fabric-item-name {
  font-family: $font;
  font-weight: 600;
  color: #5c5c5c;
  font-size: 2rem;
}

.fabric-item-reference {
  font-family: $font;
  color: #5c5c5c;
  font-weight: 200;
}

.cancel-update-drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  @include flexbox();
  @include align-items(center);
}

.library-card {
  // &:hover > .ant-card-actions {
  //   opacity: 1;
  // }
  & > .ant-card-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    background: rgba(0, 0, 0, 0.24);
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    border-color: rgba(255, 255, 255, 0.473);
    border-radius: 5px;
    @include transition(all, 0.2s, ease-in-out);
    // opacity: 0;
    &:hover {
      background: rgba(0, 0, 0, 0.603);
    }
    & > li > span > span {
      vertical-align: 1px;
      color: white !important;
    }
  }
}

.image-view {
  width: 100%;
  height: 100%;
  max-height: 50rem;
  @include flexbox();
  @include justify-content(center);
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.tab-img {
  max-width: 20rem;
  @include transform(scale(0.9));
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    @include transition(all, 0.1s);
    &:after {
      content: "";
      width: 100%;
      height: 20px;
      background: $blue-primary;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    &:hover {
      @include transform(scale(1.2));
    }
  }
}
.footer_library{
  position: fixed;
  bottom: 0;
  width: 420px;
  @media (max-width: 768px) {
    width: 220px;

  }
}
.library-drawer-footer {


  .cancel_btn {
    border-radius: 5px;
    color: $blue-primary;
    background: white;
    border-color: $light-gray;
    height: 45px;
    width: 200px;
    font-family: $font;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;

    }
  }


  .denim_knit_seperator {
    min-height: 26px;
    min-width: 1px;
    background-color: #dcdbdc;
  }
  .approve_btn {
    border-radius: 5px;
    background: $blue-primary;
    border-color: $blue-primary;
    color: white;
    height: 45px;
    width: 200px;
    font-family: $font;
    font-size: 16px;
    margin-left: 20px;
    @media (max-width: 768px) {
      font-size: 12px;

    }
  }
  .cancel_btn:hover,
  .approve_btn:hover {
    border-color: $blue-primary;
  }
}
.item {
  width: 200px;
  flex-shrink: 0;
}
.product_in_that_fabric {
  padding: 0px !important;
}
.width-image-fabric{
  width: 38%;
  @media (max-width: 1131px) {
    width: 100%;

  }
}

.width-description-fabric{
  width: 50%;

  @media (max-width: 1131px) {

    margin-top: 25px;
    width: 100%;
    table{
      width: 100%;
    }

  }
}