@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";
.selection_container {
  overflow-y: scroll;
  height: calc(100vh - 109px);
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgb(161, 161, 161);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
  padding: 12px 14px !important;
  // width: calc(100vw - 450px);
  // @media screen and (max-width: 1024px) {
  //   width: calc(100vw - 340px);
  // }
  // @media screen and (max-width: 575px) {
  //   min-width: 100%;
  // }
  .empty_collection {
    @include flexbox();
    @include align-items(center);
    @include flex-direction(column);
    @include justify-content(center);
    height: 100%;
    @media screen and (max-width: 1024px) {
    }
    img {
      height: 300px;
      @media screen and (max-width: 1024px) {
        height: 200px;
      }
    }
    .collection_welcome {
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
    .submit_button_blue_add_folder {
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 450px;
      padding: 18px 25px;
      svg {
        height: 24px;
        width: 24px !important;
      }
    }
    .collection_welcome_description {
      text-align: center;
      font-size: 16px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
  .list_products {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 420px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 420px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 1700px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @media (min-width: 2200px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .grid-item-product {
      width: 100% !important;
    }
    // .product_card {
    //   display: flex;
    //   flex-direction: column;
    //   @media screen and (max-width: 575px) {
    //     align-items: center;
    //   }
    //   align-items: center;
    // }
    .product_card {
      height: 470px !important;
      width: 330px !important;
      margin: 0 10px;
      padding: 0 !important;
      @media screen and (max-width: 1024px) {
        // width: 310px !important;
      }
      @media screen and (max-width: 768px) {
        // width: 280px !important;
      }
      @media screen and (max-width: 575px) {
        align-items: center;
      }
      .product_card_page {
        height: 395px !important;
        width: 100% !important;
        @media screen and (max-width: 1024px) {
          // width: 310px !important;
        }
        @media screen and (max-width: 768px) {
          // width: 280px !important;
        }
      }
    }
  }
  .list_products_header {
    padding-bottom: 20px;

    .product_card {
      display: flex;
      flex-direction: column;
    }
  }
  h6 {
    color: $blue-primary;
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0;
  }

  width: 100%;
}

.selectAll {
  margin: 0 5px;
  height: 52px;
  padding: 0 30px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 18px;
  font-weight: 500;
  color: #7c7c7c;
  cursor: pointer;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  transition: all 0.2s;
}

.addToCollection {
  margin: 0 5px;
  height: 52px;
  padding: 0 30px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 18px;
  font-weight: 500;
  color: white;
  background-color: $blue-primary;
  cursor: pointer;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  transition: all 0.2s;
}

.addToCollectionMySelection_2 {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: $blue-primary;
  color: white;
  @media screen and (max-width: 575px) {
    font-size: 12px;
    justify-content: center;
    width: 100%;
    span {
      display: none;
    }
  }
  svg {
    height: 24px;
    width: 24px !important;
  }
}

.addToCollectionMySelection {
  font-size: 16px;
  border: 1px solid $blue-primary;
  color: white;
  background-color: $blue-primary;
  padding: 12px;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    padding: 5px !important;
    .icon_send {
      padding: 8px !important;
    }
    .text_button {
      display: none;
    }
  }
}

.title_myselection {
  font-size: 32px;
  text-transform: capitalize;
  font-weight: 400;
  color: $blue-primary;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

.myselection_product_name {
  font-size: 16px;
  text-transform: capitalize;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.titledrawerConfirmlaunchorder {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;

  color: #333333;
  margin-bottom: 8px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}
.label_myselection {
  font-size: 12px !important;
}
.basic-multi-select-my-selection {
  font-size: 12px;
}
.titledrawerConfirmlaunchorderDiscription {
  font-size: 16px;

  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.steps_launch_order
  > .ant-steps-horizontal
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 12px !important;
}
.titleorderContainerH1myselection {
  font-family: $font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
  color: $blue-primary;
}

.titleorderContainerH2myselection {
  font-family: $font;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
  color: #697586;
}

.next_back_mycollection {
  font-size: 12px;
  border: 1px solid $blue-primary;
  color: $blue-primary;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
}

.confirm_order_collection {
  border-radius: 5px;
  background: $blue-primary;
  border-color: #00468c;
  color: white;
  height: 45px;
  width: 100%;
  font-family: $font;
  font-size: 12px;
  @media screen and (min-width: 768px) {
    font-size: 12px !important;
  }
}
