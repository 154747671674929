@import "./mixins.scss";
@import "./variables.scss";

.result_search {
  padding-top: 109px;
  height: 100vh;
  @include flexbox();

  @media (max-width: 768px) {
    padding-top: 88px;
  }

  .result_search_content {
    @include flexbox();
    @include flex-direction(column);
    padding: 20px;
    overflow-y: scroll;
    width: 100%;
    margin-left: 23rem;

    @media (max-width: 1000px) {
      margin-left: 0px;
    }

    @media (max-width: 575px) {
      padding: 10px;
    }

    .result_search_content_header {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      & > p {
        color: $dark-gray;
        font-family: $font;
        font-family: 18px;
        font-weight: 300;
        @media (max-width: 575px) {
          font-size: 15px;
        }
        span {
          text-transform: capitalize;
          font-weight: 500;
          color: black;
          font-family: 20px;
          @media (max-width: 575px) {
            font-size: 15px;
          }
        }
      }
      & > span {
        color: $dark-gray;
        font-family: $font;
        font-family: 18px;
        @media (max-width: 575px) {
          font-size: 15px;
        }
      }
    }

    .result_search_collections {
      margin-bottom: 40px;
    }

    .result_search_title {
      @include flexbox();
      @include justify-content(space-between);
      font-family: $font;
      margin-bottom: 30px;
      & > h6 {
        color: $blue-primary;
        font-size: 18px;
        font-weight: 700;
      }
      & > a {
        font-size: 16px;
        color: $blue-primary;
      }
    }

    .result_search_data {
      @include flexbox();
      & > a:hover {
        text-decoration: none;
      }
      & > .one_folder {
        margin-right: 20px;
      }
    }
  }
}

.result_search_filter {
  @include flexbox();
  @include flex-direction(column);
  background: $blue-sky-light;
  height: calc(100vh - 109px);
  min-width: 350px;
  padding: 30px;
  max-width: 23rem;
  overflow: scroll;
  position: fixed;

  .h2_subtitle_blue {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 40px;
  }
  & > .result_search_filter_item:nth-child(2) > div > img {
    margin-right: 11px;
    width: 25px;
  }

  .result_search_filter_item {
    @include flexbox();
    @include justify-content(space-between);
    margin-bottom: 25px;
    @include align-items(center);

    & > div {
      @include flexbox();
      @include align-items(center);
      & > img {
        margin-right: 10px;
        width: 26px;
      }
      & > label {
        font-size: 18px;
        font-weight: 700;
        font-family: $font;
        margin: 0;
      }
    }

    .ant-switch-checked {
      background: $blue-primary;
    }
  }
}

.result_search_filter_drawer_content {
  width: 100% !important;
  min-width: auto;
}

.result_search_filter_drawer
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body
  > .ant-drawer-body {
  background: $blue-sky-light;
}

.result_search_content_header > {
  .search_header_text {
    color: $dark-gray;
    font-family: $font;
    font-size: 18px;
    font-weight: 300;
    @media (max-width: 575px) {
      font-size: 15px;
    }
    span {
      text-transform: capitalize;
      font-weight: 500;
      color: black;
      font-size: 20px;
      @media (max-width: 575px) {
        font-size: 15px;
      }
    }
  }
  // & > div > span {
  //   color: $dark-gray;
  //   font-family: $font;
  //   font-family: 18px;
  // }
}
.result_text {
  color: $dark-gray;
  font-family: $font;
  font-size: 18px;
  font-weight: 300;
  & > .show_all_result {
    font-size: 16px;
    color: $blue-primary;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 575px) {
    font-size: 15px;
  }
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  padding: 0px 5px;

  @media (max-width: 1920px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 1680px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.load_more_btn {
  background: white;
  border: 1px solid $blue-primary;
  color: $blue-primary;
  border-radius: 5px;
  padding: 0.6rem 1rem;

  &:hover {
    background: $blue-primary;
    color: white;
  }
}
