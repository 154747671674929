@import "./sass/mixins.scss";
@import "./sass/variables.scss";
@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "~antd/dist/antd.css";

body {
  margin: 0px;
 /* font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;*/
  font-family: $font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.row {
  margin: 0 !important;
  padding: 0 !important;
}
input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
  // override ant noification style
}
.success_notif,
.danger_notif,
.info_notif,
.warning_info {
  @include flexbox();
  @include justify-content(space-between);
  border-radius: 5px !important;
  font-weight: 600 !important;
  font-family: $font;
  min-width: 300px;

  & > .ant-notification-notice-content {
    @include flexbox();
    margin-right: 20px !important;

    & > div {
      @include flexbox();
      @include align-items(flex-end);

      & > .ant-notification-notice-message {
        margin: 0 !important;
      }
    }
  }
}

.success_notif {
  color: #06c170 !important;
  background: #f2fcf8 !important;
  width: fit-content !important;
  &
    > .ant-notification-notice-content
    > div
    > .ant-notification-notice-message {
    color: #06c170 !important;
    margin: 0 !important;
  }
}

.danger_notif {
  color: #ff5b5b !important;
  background: #fff6f6 !important;

  &
    > .ant-notification-notice-content
    > div
    > .ant-notification-notice-message {
    color: #ff5b5b !important;
    margin: 0 !important;
  }
}

.warning_notif {
  color: #fdab41 !important;
  background: #fffbf5 !important;

  &
    > .ant-notification-notice-content
    > div
    > .ant-notification-notice-message {
    color: #fdab41 !important;
    margin: 0 !important;
  }
}

.info_notif {
  color: #00468c !important;
  background: #e5f1fa !important;

  &
    > .ant-notification-notice-content
    > div
    > .ant-notification-notice-message {
    color: #00468c !important;
    margin: 0 !important;
  }
}

.ant-notification-bottomLeft {
  left: 50% !important;
  transform: translateX(-50%);
  min-width: 300px !important;
}
.ant-notification-notice {
  @include flexbox();
  @include align-items(baseline);
}

.ant-notification-notice-message {
  display: none !important;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 40px !important;
}

.loading_logo_demco {
  width: 500px;
  height: 500px;
  transition: all ease-in-out 0.2s;
  animation: flash ease-out infinite;
  @media screen and (max-width: 575px) {
    width: 300px;
    height: 300px;
  }
}

@keyframes flash {
  from {
    opacity: 0;
  }
  92% {
    opacity: 0.25;
  }
  93% {
    opacity: 0.5;
  }
  94% {
    opacity: 0.75;
  }
  to {
    opacity: 1;
  }
}
@media (min-aspect-ratio: 16/9) {
  #videoBG {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  #videoBG {
    width: auto;
    height: 100%;
  }
}
// @media (max-width: 767px) {
//   #videoBG {
//       display: none;
//   }
//   body {
//       background: url('poster.jpg');
//       background-size: cover;
//   }
// }
