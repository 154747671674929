@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";
.reset_password_container {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  width: 100vw;
  height: calc(100vh - 108px);
  background-color: white;
  position: absolute;
  bottom: 0px;
  left: 0;
  // padding: 150px 220px;
  overflow: unset;
  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);
  @include justify-content(center);
  @media screen and (max-height: 700px) {
    padding: 46px 130px;
  }
  @media screen and (max-width: 1099px) {
    height: calc(100vh - 105px);
  }
  @media screen and (max-width: 768px) {
    height: calc(100vh - 87px);
  }
  .reset_password_container_block {
    padding: 0 28px !important;
    max-width: 100% !important;
    height: 100%;
    // transform: translateY(-50px);
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      height: 100%;
      // transform: translateY(-50px);
      display: flex;
      justify-content: center;
    }
  }
  .buttons_form {
    @media screen and (max-width: 768px) {
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100%;
    }
    button {
      @media screen and (max-width: 767px) {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
      }
    }
  }

  .buttons_form_reset {
    @media screen and (max-width: 768px) {

      gap:16px;
      width: 100%;
    }
    button {
      @media screen and (max-width: 767px) {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: 8px;
      }
    }
  }
  .field {
    position: relative;
    .icon_eye {
      position: absolute;
      right: 0;
      top: 20px;
    }
  }
  .reset_header {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: $blue-primary;
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
      font-size: 16px;
      transform: translateY(-50px);
    }
  }
  .col-6 {
    padding: 0;
  }
  label {
    color: grey;
  }
  input {
    letter-spacing: 1.4px;
    font-weight: 400;
  }
  .padding_change_password{
    padding-left: 25%;
    padding-right: 25%;

    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .reset_form_container {
    @media screen and (max-width: 768px) {
      transform: translateY(-50px);
    }
    .reset_form_left {
      padding-right: 60px;
      .forgot_password {
        text-align: end;
        p {
          font-weight: 500;
        }
      }
    }
    .reset_form_right {
      padding-left: 60px;
      .new_password_contanier {
        margin-bottom: 60px;

        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .submit_button_white_cancel,
  .submit_button_blue_confirm {
    width: 260px;
    height: 80px;
    padding: 0 !important;
    font-size: 20px;
    font-weight: 500;
  }
  .submit_button_white_cancel {
    margin-right: 20px;
  }
  .submit_button_blue_confirm {
    margin-left: 20px;
  }

  & > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cancel_reset_pwd_btn {
    border-radius: 5px;
    color: $blue-primary;
    background: white;
    border-color: $light-gray;
    height: 45px;
    width: 200px;
    font-family: $font;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size:12px;
    }
  }

  .confirm_reset_pwd_btn {
    border-radius: 5px;
    background: $blue-primary;
    border-color: $blue-primary;
    color: white;
    height: 45px;
    width: 200px;
    font-family: $font;
    font-size: 16px;
    margin-left: 20px;
    @media (max-width: 768px) {
      font-size:12px;
    }
  }

  .cancel_reset_pwd_btn:hover,
  .confirm_reset_pwd_btn:hover {
    border-color: $blue-primary;
    color: $blue-primary;
  }
}
