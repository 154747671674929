.btn-remove {
  position: absolute;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 16px;
  margin-top: 4px;
  margin-right: 6px;
  padding: 8px;
  border-radius: 5px;
}

.aside_fabric > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 16px;
  font-weight: bold;
}

.title_new_fabric >     .ant-drawer-content-wrapper >  .ant-drawer-content  > .ant-drawer-wrapper-body >   .ant-drawer-header >  .ant-drawer-title {
  font-size: 24px !important;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}

.title_fabric{
  font-size: 32px;
  color: #182844;
  text-transform: capitalize;
  padding-bottom: 8px;
  @media (max-width: 768px) {
    font-size: 20px !important;
  }
}
.title_fabric_sup{
  font-size: 24px;
  color: #182844;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}
.title_details{
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}

.prod_that_fabric
{
  font-size: 24px;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }

}
.denim_knit_library_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  color: #858686;
  .denim_knit_seperator {
    min-height: 26px;
    min-width: 1px;
    background-color: #dcdbdc;
  }
  .denim_knit_text {
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 24px;
  }

  .denim_knit_text_mobile {
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 16px;
  }
}

.denim_knit_library{
  font-size: 24px !important;
  cursor: pointer;
  text-transform: uppercase;
  font-weight:bold ;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
}

.text_size_filter{
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}

.label_fabric{
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }

}
#tab-image >.ant-tabs-nav > .ant-tabs-nav-operations >   .ant-tabs-nav-more{
  display: none;
}
