@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.submit_button_white {
  width: fit-content;
  cursor: pointer !important;
  background: $white !important;
  // padding from xd
  padding: 1rem 4rem !important;
  border: 1px solid $light-gray !important;
  @include border-radius(5px);
  color: $blue-primary !important;
  font-family: $font !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  outline: none !important;
  @media (min-width: 992px) {
    font-size: 1.3rem !important;
  }
  @media (max-width: 780px) {
    font-size: 12px !important;
  }
  @include transition(all, 0.1s, ease-in-out);
  &:hover {
    background: $blue-primary !important;
    color: $white !important;
    border: 1px solid $blue-primary !important;
  }
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51) !important;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
  border-radius: 5px !important;
}


.submit_button_add_to_collection {
  width: fit-content;
  cursor: pointer !important;
  background: $blue-primary !important;
  // padding from xd
  padding: 1rem 4rem !important;
  border: 1px solid $light-gray !important;
  @include border-radius(5px);
  color: $white !important;
  font-family: $font !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  outline: none !important;
  @media (min-width: 992px) {
    font-size: 16px !important;
  }
  @media (max-width: 600px) {
    font-size: 12px !important;
    padding: 12px 24px !important;
  }
  @include transition(all, 0.1s, ease-in-out);
  &:hover {
    background: $white !important;
    color:  $blue-primary !important;
    border: 1px solid $blue-primary !important;
  }
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51) !important;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
  border-radius: 5px !important;
}

.submit_button_add_to_collection_mobile{

  width: fit-content;
  cursor: pointer !important;
  background:  $blue-primary !important;
  // padding from xd
  padding: 1rem 4rem !important;
  color: white;
  font-family: $font !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  outline: none !important;
  @media (min-width: 992px) {
    font-size: 16px !important;
  }
  @media (max-width: 600px) {
    font-size: 12px !important;
    padding: 12px 24px !important;
  }
  @include transition(all, 0.1s, ease-in-out);
  /*  &:hover {
      background:  $blue-primary !important;
      color:  $white!important;
      border: 1px solid $blue-primary !important;
    }*/
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51) !important;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }

}
.submit_button_blue-cs {
  background: #00468c !important;
  color: white !important;
  border-radius: 5px !important;
}
.submit_button_white_full_width {
  width: inherit;
  cursor: pointer;
  background: $white;
  margin-top: 79px;
  padding: 1.6rem 9rem;
  border: 1px solid $light-gray;
  @include border-radius(5px);
  color: $blue-primary;
  font-family: $font;
  font-weight: 300;
  font-size: 1.25rem;
  outline: none !important;
  @media (max-width: 992px) {
    font-size: 1.2rem;
  }
  @include transition(all, 0.1s, ease-in-out);
  &:hover {
    background: $blue-primary;
    color: $white;
  }
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51);
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
}

.submit_button_blue {
  width: fit-content;
  cursor: pointer;
  background: $blue-primary;
  // padding from xd
  padding: 1rem 4rem;
  @include border-radius(5px);
  border: none;
  color: $white;
  font-family: $font;
  font-size: 1.4rem;
  outline: none !important;
  @media (min-width: 992px) {
    font-size: 1.4rem;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51);
  }
}

.submit_button_add_product_white {
  width: fit-content;
  cursor: pointer;
  background: #F4F7FF;
  // padding from xd
  padding: 1rem 4rem;
  @include border-radius(5px);
  border: 1px solid  $blue-primary;
  color: $blue-primary;
  font-family: $font;
  font-size: 1.4rem;
  outline: none !important;
  margin-top: 5px;
  @media (min-width: 992px) {
    font-size: 1.4rem;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51);
  }
}


.add_folder_button_blue {
  cursor: pointer;
  background: $blue-primary;
  width: 360px;

  padding: 24px auto;
  @include border-radius(5px);
  font-size: 1.4rem;
  font-family: $font;
  color: $white;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none !important;
  padding: 12px 25px;
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51);
  }
}

.light_button {
  color: $blue-primary;
  background: transparent;
  border: none;
  text-decoration: underline;
  font-family: $font;
  font-size: $h2-font-s;
  outline: none;
  cursor: pointer;
}
