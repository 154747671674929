@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.home_header {
  position: relative;
  height: 100vh;
  margin-bottom: 85px;
  overflow: hidden;

  @media (max-width: 768px) {
    overflow: unset;
  }

  .video_wrapper {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(5, 16, 41);
    overflow: hidden;
    @media (min-width: 768px) {
      width: 205%;
    }
    @media (min-width: 992px) {
      width: 159%;
    }
    @media (min-width: 1100px) {
      width: 143%;
    }
    @media (min-width: 1200px) {
      width: 131%;
    }
    @media (min-width: 1366px) {
      width: 116%;
    }
    @media (min-width: 1600px) {
      width: 100%;
    }
  }
  video {
    width: 100%;
    background-color: rgb(5, 16, 41);
    @media (max-width: 768px) {
      width: auto;
      height: 100vh;
    }
  }
  .mouse_scroll {
    position: absolute;
    bottom: 40px;
    left: 20px;
    opacity: 0.7;

    & > p {
      color: white;
    }
  }
  .header__content {
    width: 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include flexbox();
    @include justify-content(space-around);
    @include flex-wrap(wrap);

    // @media (max-height: 800px) {
    //   top: 60%;
    // }
    @media (max-width: 540px) {
      top: 50%;
    }
    @media (max-width: 768px) {
      top: 50%;
    }
    @media (min-width: 768px) and (max-width: 1192px) {
      top: 50%;
    }
    @media (max-height: 660px) {
      top: 50%;
    }

    .header__titles {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(space-evenly);
      margin-right: 10px;
      margin-left: 10px;
      @media (max-width: 1192px) {
        margin-bottom: 20px;
      }
      @media (max-width: 768px) {
        margin-bottom: 0;
      }

      .header_btns_wrapper {
        @include flexbox();
        @include justify-content(space-between);
        @include flex-wrap(wrap);


        @media (max-width: 1071px) {
          @include flex-direction(column);
          @include align-items(center);
          & > button {
            margin-bottom: 30px;
          }
        }

        @media (max-width: 768px) {
          margin: 40px 0;
        }

        & > .submit_button_white {
          @media (max-width: 425px) {
            padding: 1.6rem 1rem;
            margin-top: 10px;
          }
        }
      }

      & > div:first-child > h1 {
        @media (max-width: 1250px) {
          font-size: 40px;
        }
        @media (max-width: 1024px) {
          font-size: 40px;
        }
        @media (max-width: 540px) {
          font-size: 55px;
        }
        @media (min-width: 320px) and (max-width: 539px) {
          font-size: 40px;
        }
      }
    }

    .header__order {
      @include flex(0.3);

      @media (max-width: 425px) {
        min-width: 90%;
      }

      .header__order_card {
        background: $white;
        padding: 30px 65px;
        @include border-radius(5px);
        min-height: 650px;

        @media (max-width: 540px) {
          width: 95%;
          margin: 0 auto;
        }

        @media (max-height: 800px) {
          // min-height: fit-content;
          min-height: 500px;
          padding: 20px 35px;
        }
        @media (max-width: 768px) {
          box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.16);
        }
        // @media (min-height: 800px) {
        //   min-height: 585px;
        //   padding: 30px 40px;
        // }
        // @media (max-height: 900px) {
        //   min-height: 650px;
        // }
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(space-around);

        .h1_title_bold,
        .h2_title {
          margin: 0;
        }
        button {
          padding: 1.1rem 9rem;
          font-size: 20px;
          margin-top: 15px;
        }
        .dropzone {
          margin: 15px 0;

          @media (max-height: 800px) {
            height: 80px;
          }
        }
        button,
        .dropzone {
          width: 100%;
        }

        button {
          @media (max-width: 425px) {
            padding: 1rem 2rem;
          }
        }

        @media (max-width: 1024px) {
          min-height: 512px;
          padding: 30px;
        }
      }
    }
    .header__titles {
      @include flex(0.4);
      gap: 48px;
    }
  }
  .h1_huge_light {
    //font-size: 85px;
    font-size: 64px;
    @media (max-width: 425px) {
      font-size: 32px;
    }
    @media (min-width: 425px) and (max-width: 768px) {
    //  font-size: 63px;

      font-size: 32px;
    }
  }
  .h1_huge_light span {
    font-weight: bold;
  }
}

.header__play_btn {
  background: none;
  border: none;
  color: $white;
  font-weight: 700;
  font-size: 20px;
  outline: none !important;
}

.or {
  width: 100%;
  text-align: center;
  color: $dark-gray;
  font-weight: bold;
  border-bottom: 1px solid $light-gray;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.or span {
  background: #fff;
  padding: 10px;
  border: 1px solid $light-gray;
  border-radius: 50%;
}

.textarea_direct_order:hover,
.header__order_card > .ant-select-multiple > .ant-select-selector:hover {
  border: 1px solid $light-gray !important;
}

.textarea_direct_order:focus,
.header__order_card > .ant-select-multiple > .ant-select-selector:hover {
  border: 1px solid $light-gray !important;
}
.textarea_direct_order:focus-within,
.header__order_card > .ant-select-multiple > .ant-select-selector:focus-within {
  border: 1px solid $light-gray !important;
  outline: none !important;
  box-shadow: none !important;
}
.textarea_direct_order {
  font-family: $font;
  margin-bottom: 10px !important;
}

.react-select-placeholder-input {
  font-family: $font;
  color: #7c7c7c;
  //   font-size: 16px;
}

.submit_button_white_hero {
  width: fit-content;
  cursor: pointer !important;
  background: $white !important;
  // padding from xd
  padding: 1rem 1rem !important;
  border: 1px solid $light-gray !important;
  @include border-radius(5px);
  color: $blue-primary !important;
  font-family: $font !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  outline: none !important;
  @media (min-width: 992px) {
    font-size: 16px !important;
  }
  @include transition(all, 0.1s, ease-in-out);
  &:hover {
    background: $blue-primary !important;
    color: $white !important;
    border: 1px solid $blue-primary !important;
  }
  &:active {
    box-shadow: 0 3px 14px rgba(20, 71, 135, 0.51) !important;
  }
  &:disabled {
    background: $light-gray;
    color: $dark-gray;
  }
  border-radius: 5px !important;
}
.action-hero{
  align-items: center;

  @media (max-width: 574px) {
    gap: 16px;
  }

}

// .col-6
//   > .field
//   .field_article
//   > .css-2b097c-container
//   > .css-p3h5vj-control
//   > .css-1hb7zxy-IndicatorsContainer
//   > .css-tlfecz-indicatorContainer {
//   margin-bottom: -10px !important;
// }
