@import "../../sass/mixins.scss";

.label_card_container {
  position: relative;
  text-align: center;
  height: 395px;
  width: 300px;
  margin-bottom: 30px;
  @media (max-width: 1366px) {
    max-height: 300px;
    max-width: 205px;
  }
  @media (max-width: 1700px) {
    max-height: 345px;
    max-width: 250px;
  }
  .label_img_container {
    height: 190px;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    margin-bottom: 5px;
    img {
      @media (max-width: 768px) {
        transform: scale(0.5);
      }
    }
    @media (max-width: 1366px) {
      transform: scale(0.55);
    }
    @media (max-width: 1600px) {
      transform: scale(0.8);
    }
  }
  .label_card {
    height: 400px;
    width: 290px;
    background-color: white;
    @include box-shadow-rgba(0px, 0px, 17px, -6px, 0, 0, 0, 0.35);
    padding: 45px 10px;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in;
    @media (max-width: 1360px) {
      max-width: 260px !important;
    }
    @media (max-width: 1466px) {
      max-width: 210px;
      padding: 20px 10px;
    }
    @media (max-width: 1700px) {
      width: 240px;
      padding: 25px 10px;
    }
  }
}
.text_container {
  min-height: 250px;
  padding: 0 10px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  .label_text {
    font-size: 18px;
    @media (max-width: 1466px) {
      font-size: 14px;
    }
    @media (max-width: 1700px) {
      font-size: 16px;
    }
  }
}

.label_card_container:hover > .label_img {
  opacity: 0;
  transform: scale(0.9);
}
.label_card_container:hover > .label_card {
  z-index: 2;
  opacity: 1;
}
.label_img_colored {
  transform: scale(1.1);
  @media (max-width: 1466px) {
    transform: scale(0.7);
  }
  @media (max-width: 1700px) {
    transform: scale(0.8);
  }
}
.label_card_container:hover > .label_img_colored {
  opacity: 1;
  transform: scale(1);
  @media (max-width: 1466px) {
    transform: scale(0.6);
  }
  @media (max-width: 1700px) {
    transform: scale(0.7);
  }
}
