@import "./mixins.scss";

.choose-cursor-btn {
  border: 1px solid #809ec2;
  width: 42px;
  height: 42px;
  margin-bottom: 10px;
  margin-left: 4px;
  cursor: pointer;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include transition(all, 0.3s, ease);
  svg {
    width: 24px;
    height: 34px;
    path {
      fill: #000000;
      @include transition(all, 0.3s, ease);
    }
  }
  border-radius: 100%;
  background: white;
  &:focus {
    outline: none;
  }
  &:hover {
    background: #00468c;
    svg {
      path {
        fill: #ffffff;
        @include transition(all, 0.3s, ease);
      }
    }
  }
}

.choose-cursor-btn-active {
  border: 1px solid #809ec2;
  width: 42px;
  height: 42px;
  margin-bottom: 10px;
  margin-left: 4px;
  cursor: pointer;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include transition(all, 0.3s, ease);
  svg {
    width: 24px;
    height: 34px;
    path {
      fill: #ffffff;
      @include transition(all, 0.3s, ease);
    }
  }
  border-radius: 100%;
  background: #00468c;
}

.vertical-slider-configurator {
  @media screen and (max-width: 769px) {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    height: 0.1rem;
    width: 6rem;
  }
  padding: 0;
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  height: 10rem;
  .thumb {
    @media screen and (max-width: 769px) {
      width: 9px;
      height: 19px;
    }
    margin: 0;
    color: white;
    background: #00468c;
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    font-size: 12px;
    text-align: center;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  .track {
    background: #e0f0f8;
    width: 2px;
    border-radius: 4px;
  }
}

.title-config {
  color: #000000;
  font-weight: bold;
  margin-left: 5px;
}
.sub-title-config {
  //color: #7c7c7c;
  font-weight: normal;
  font-size: 12px;
  margin-right: 20px;
}

.circle-color {
  background: #7a9ec5;
  width: 29px;
  height: 29px;
  border-radius: 100%;
  &.small {
    width: 7px;
    height: 7px;
  }
}
.conf-responsive {
  @media screen and (max-width: 769px) {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
  }
}
.size-col {
  @media screen and (max-width: 769px) {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    margin-right: 7rem;
  }
}
.intensity-col {
  @media screen and (max-width: 769px) {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    margin-right: 9rem;
  }
}
.configurator-responsive {
  @media screen and (max-width: 769px) {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    max-width: 18rem;
  }
}
.size-col-resp {
  @media screen and (max-width: 769px) {
    margin-top: 0;
  }
}
.intensity-row {
  @media screen and (max-width: 769px) {
    margin-bottom: 0;
  }
}
.cirlcle-size {
  @media screen and (max-width: 769px) {
    margin-bottom: 0;
    margin-left: 5px;
  }
}
.circle-strong {
  @media screen and (max-width: 769px) {
    margin-top: 0;
    margin-left: 5px;
  }
}
