@import "../../sass/mixins.scss";

.discover_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 20px;
  padding: 0 35px;
  margin-top: 150px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    //margin-top: 370px;
    margin-top: 59px;
  }

  @media (min-width: 425px) and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .dicover_card {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 1920px) {
      height: 750px;
    }

    .discover_card_footer {
      p {
        font-size: 25px;
      }
    }
  }
  // @include flexbox();
  // @include justify-content(space-around);
  // @include align-items(center);
  // @include flex-wrap(wrap);
  // margin: 85px 0;

  // .dicover_card {
  //   margin-top: 20px;
  // }

  // @media (max-width: 900px) {
  //   display: flex;
  //   overflow-x: scroll;
  //   width: 100%;
  //   flex-wrap: nowrap;
  // }
}

.discover_item_list,
.discover_card_title {
  cursor: pointer;
}
.discover_item_list {
  font-size: 1.2rem !important;
  line-height: 1.4rem !important;
  padding-bottom: 2px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.924) 0 0);
  background-position: 0 100%;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.5s, background-position 0s 0.5s;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
  }
  @media (min-width: 992px) {
    font-size: 1.3rem !important;
    line-height: 2rem !important;
  }
  @media (min-width: 1100px) {
    font-size: 1.1rem !important;
    line-height: 2rem !important;
  }
  @media (min-width: 1200px) {
    font-size: 1.1rem !important;
    // line-height: 2.7rem!important;
  }
  @media (min-width: 1366px) {
    font-size: 1.3rem !important;
    // line-height: 2.8rem!important;
  }
  @media (min-width: 1600px) {
    font-size: 1.5rem !important;
    // line-height: 2.9rem!important;
  }
  @media (min-width: 1920px) {
    font-size: 1.6rem !important;
    // line-height: 3rem!important;
  }
}
.discover_item_list:hover {
  background-position: 100% 100%;
  background-size: 100% 2px;
}
