ul,
#myUL {
  list-style-type: none;
}

#myUL {
  margin: 4;
  padding: 0;
  font-size: 18px;
  color: #1c1c1c;
}

.caret {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.caret::before {
  content: ">";
  color: #00468c;
  font-weight: 500;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.nestedli::before {
  content: "⤷";
  color: #00468c;
  display: inline-block;
  margin-right: 6px;
}
.nested {
  display: none;
  user-select: none;
  cursor: pointer;
}

.active {
  display: block;
}

.active_moodboard {
  display: block;
}

.scrol_order::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrol_order {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrol_order {
  // background-color: #eee !important;
  //padding: "25px" !important;
  overflow-y: scroll; /* Add the ability to scroll */
}

.delete_button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff0000; /* Set your desired background color */
  color: #fff; /* Set your desired text color */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}

.delete_button:hover {
  background-color: #cc0000; /* Set your desired background color on hover */
}
.delete_button_collection {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff; /* Set your desired background color */
  color: #fff; /* Set your desired text color */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
}

.delete_button_collection:hover {
  background-color: white; /* Set your desired background color on hover */
}
.season_position{
  padding-left: 40px !important;
}