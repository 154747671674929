@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.all_clients {
  padding-top: 105px !important;
  background: $whitish;
  min-height: calc(100vh - 109px);
  @media (max-width: 768px) {
    padding-top: 87px !important;
  }
  @media (min-width: 1100px) {
    padding-top: 109px !important;
  }
}
.ant-pagination-item,
.ant-pagination-item-link {
  border-radius: 5px !important;
}
.ant-pagination-item-link {
  & > .anticon-right,
  & > .anticon-left {
    vertical-align: 0.1em !important;
  }
}
.select_filter_client > .ant-select-selector {
  border-radius: 5px !important;
}
.ant-pagination-options {
  display: none;
}
.ant-descriptions-item-container {
  @include align-items(center);
}

.lightbox_cust_preview {
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.548);
    border-radius: 5px;
    opacity: 0;
    z-index: 10;
    transition: all 0.3s ease-in-out;
  }
  &::after {
    content: "\f06e";
    font-family: "Font Awesome 5 Free";
    font-size: 15px;
    color: $blue-primary;
    padding: 20px;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.438);
    z-index: 11;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  &:hover::before {
    opacity: 1;
  }
  &:hover::after {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.preview_imgs_wrapper {
  &::-webkit-scrollbar {
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgb(161, 161, 161);
  }
}

.see_details_option_preview {
  background: $blue-primary;
  color: white;
  border: 1px solid #ddd;
  padding: 5px 15px;
  & > span {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    background: white;
    color: $blue-primary;
  }
  &:hover span {
    transform: translateX(8px);
  }
}
.options_collapse_details > .ant-collapse-item > .ant-collapse-header {
  background: white;
  color: rgb(0, 70, 140);
  font-weight: bold;
  text-transform: uppercase;
  & > .ant-collapse-arrow {
    vertical-align: 0 !important;
  }
}
.ant-switch-checked {
  background-color: $blue-primary;
}
.moodboard-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  padding: 0px 5px;

  @media (max-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.preview-container {
  padding: 10px;
  width: 100%;
  /* Set a fixed aspect ratio */
  aspect-ratio: 1/1;
  flex: 1 1 calc(25% - 20px); /* Adjust the width based on your desired number of columns */
}

.preview-inner {
  border: 1px solid black;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.preview-inner img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.errorInput {
  border: 1px solid red;
}
