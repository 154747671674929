@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.add_to_collection_container {
  width: 100vw;
  height: calc(100vh - 109px);
  background-color: white;
  position: absolute;
  bottom: 0px;
  padding: 40px 100px;
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden !important;
  z-index: 11;
  transition: all 0s;
  @media (max-width: 1400px) {
    overflow-y: scroll !important;
  }
  @media (max-width: 1024px) {
    padding: 30px 50px;
  }
  @media (max-width: 900px) {
    padding: 30px 30px;
  }
  @media (max-width: 768px) {
    padding: 0;
    padding-top: 30px;
    height: calc(100vh - 87px);
  }
  .alerts_position {
    position: absolute;
    right: 15px;
    top: 127px;
  }
  .add_to_collection_top {
    margin-bottom: 20px;
    @include flexbox();
    @include align-items(center);
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      padding: 0 15px;
      text-align: center;
    }

    .add_to_collection_top_right {
      // margin-left: 30px;
      // width: 100%;
      @media (max-width: 768px) {
        margin: 0 auto;
      }
      p {
        @media (max-width: 768px) {
          width: 70%;
          margin: 0 auto;
        }
      }
      .add_to_collection_title {
        font-size: 50px;
        @media (max-width: 1366px) {
          font-size: 32px;
        }
        @media (max-width: 768px) {
          font-size: 28px;
          margin: 0 auto;
        }
      }
    }
    img {
      margin-right: 30px;
    }
  }
  .list_folders {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      // margin-left: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }
    @media (max-width: 600px) {
      display: inline;
      padding: 0 15px;
    }
    .one_folder {
      @media screen and (max-width: 1400px) {
        margin-right: 30px;
        width: 348px;
        height: 198px;
      }
      @media screen and (max-width: 1024px) {
        margin-right: 20px;
      }
      @media screen and (max-width: 900px) {
        width: 330px;
      }
      @media screen and (max-width: 768px) {
        margin-right: 10px;
        width: 320px;
      }
      @media screen and (max-width: 600px) {
        margin-right: 0px;
        width: 97%;
        min-height: 210px;
      }
    }
    .add_new_folder {
      cursor: pointer;
      margin: 15px 0;
      margin-right: 40px;
      width: 360px;
      height: 210px;
      background-color: rgba(9, 115, 206, 0.1);
      border: 2px solid #2b74c8;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 1400px) {
        margin-right: 30px;
      }
      @media screen and (max-width: 900px) {
        margin-right: 20px;
        width: 330px;
      }
      @media screen and (max-width: 768px) {
        margin-right: 10px;
        width: 320px;
      }
      @media screen and (max-width: 600px) {
        height: 70px;
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
      }
      .add_new_folder_img {
        height: 35px;
      }
      .add_new_folder_text {
        font-weight: 500;
        font-size: 20px;
        color: $blue_primary;
      }
      .add_new_folder_img,
      .add_new_folder_text {
        margin: 6px 0;
      }
    }
  }
  .buttons {
    align-self: center;
    @media (max-width: 768px) {
      display: flex;
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: white;
    }
    .submit_button_white_confirm {
      background-color: $blue_primary;
      color: white;
    }
    .bg_confirm{
      background-color: $blue_primary !important;
      color: white!important;
      font-size: 16px !important;
    }
    .submit_button_white_confirm,
    .submit_button_white_cancel {
      // padding-left: 100px;
      // padding-right: 100px;
      font-size: 16px !important;
      margin: 0 25px;
      @media (max-width: 768px) {
        margin: 0 10px;
        width: 50%;
        margin: 0;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }

  .header_add_to_collection {
    align-self: center;

  }
}
