@import "../../sass/mixins.scss";
@import "../../sass/variables.scss";

.collection-folder-on-drag {
 @include flexbox();
 padding-top: $padding-l;
 padding-left: $padding-s;
 padding-right: $padding-xs;
 position: absolute;
 background: white;
 min-height: 100vh;
 width: 100%;
 left: 0;
 top: 0;
 right: 0;
 z-index: 9;
 left: 0;
 pointer-events: all;
 opacity: 1;
 @include transition(all, 0.2s, ease);
 .folder_miniature {
  width: 100%;
 }
 &.closed {
  opacity: 0;
  pointer-events: none;
 }
}
