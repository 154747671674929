@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.tool_bar_container {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  height: 54px;
  width: calc(100vw - 550px);
  border-radius: 8px;
  border: 2px solid #809ec2;
  background-color: #f4f7ff;
  padding: 12px 20px;
  color: $blue-primary;
  z-index: 3;
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);
  .left_side,
  .functionality_container {
    p {
      margin-bottom: 0 !important;
    }
  }
  @media (max-width: 1366px) {
    bottom: 0px;
  }
  @media (max-width: 1024px) {
    width: 100.6%;
    border-radius: 0;
    border-bottom: 0;
  }
  .right_side {
    @include flexbox();
    @include align-items(center);
    .functionality_container {
      cursor: pointer;
      margin: 0 14px;
      @include flexbox();
      @include align-items(center);
      svg {
        margin-right: 6px;
        transform: translateY(-2px);
        height: 22px;
        width: 22px;
      }
    }
  }
}
