@import "./mixins.scss";
@import "./variables.scss";

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-gap: 10px;
  padding: 10px;
}


.title-chart{
  font-family: $font;
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  color: $blue-primary;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}
.titleReport{
  text-transform: capitalize;
  font-size: 32px;
  font-family: $font;
  text-align: center;
  padding-top: 10px;
  font-weight: 600;

}
.title-2-chart{
  font-family: $font;
  font-weight: 400;
  font-size: 20px;
  text-transform: capitalize;
  color: $blue-primary;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.season-collection{
  text-transform: uppercase;
}