@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.blue_line {
  min-width: 50px;
  max-width: 50px;
  min-height: 5px;
  background-color: $blue-primary;
  margin-right: 30px;
  @media (max-width: 1200px) {
    margin-right: 20px;
    min-height: 3px;
    min-width: 40px;
    max-width: 40px;
  }
  @media (max-width: 992px) {
    margin-right: 10px;
    min-height: 3px;
    min-width: 30px;
    max-width: 30px;
  }
}

.Banner_header {
  display: inline-flex;
  align-items: center;
  margin-bottom: 30px;
}

.banner_info_shadow_block {
 // padding: 58px 48px;
//  @include box-shadow-rgba(0px, 0px, 17px, -6px, 0, 0, 0, 0.35);

  @media (max-width: 1024px) {
    width: 500px;
  }
}

.paragraphe_margin {
  margin-bottom: 30px;
}

.submit_button_white_margin {
  margin-top: 40px;
}
