@import "./mixins.scss";
@import "./variables.scss";


.h1_huge_creative_station {
  font-family: $font;
  color: $blue-primary !important;
  font-size: 32px;
  margin-bottom: 0px;
  // line-height: 3.3rem;
  @media (max-width: 786px) {
    font-size: 20px;
    // line-height: 4.2rem;
  }


}


.h1_huge_creative_station_2 {
  font-family: $font;
  font-weight: bold;
  color: $blue-primary !important;
  font-size: 32px;
  margin-bottom: 0px;
  // line-height: 3.3rem;
  @media (max-width: 786px) {
    font-size: 20px;
  }

}


.h2_subtitle_black_creative_station {
  font-family: $font;
  font-weight: bold;
  color: $black;
  font-size: 24px;
  // line-height: 1.4rem;
  @media (max-width: 430px) {
    font-size: 14px;
    // line-height: 4.2rem;
  }
  @media (max-width: 768px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 992px) {
    font-size: $h2-font-xs;
    // line-height: 1.5rem;
  }
  @media (min-width: 1100px) {
    font-size: $h2-font-s;
    // line-height: 1.5rem;
  }
  @media (min-width: 1200px) {
    font-size: $h2-font-sm;
    // line-height: 1.6rem;
  }
  @media (min-width: 1366px) {
    font-size: $h2-font-m;
    // line-height: 1.6rem;
  }
  @media (min-width: 1600px) {
    font-size: $h2-font-l;
    // line-height: 1.7rem;
  }
  @media (min-width: 1920px) {
    font-size: $h2-font-xl;
    // line-height: 1.8rem;
  }
}
.post_list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 20px;
  padding: 0 35px;
  margin-top: 32px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    //margin-top: 370px;
    margin-top: 59px;
  }

  @media (min-width: 425px) and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .post_card {
    width: 100%;
/*    background-repeat: no-repeat;
    background-size: cover;*/



    .discover_card_footer {
      p {
        font-size: 25px;
      }
    }
  }

}

.btn-show-more{
  height: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 16px;

  border-radius: 4px;
  border: 1px solid  #7D7E72;
}
.card-post{
  max-width: 470px;
}
.card-post-trending{
  max-width: 470px;
  @media (max-width: 360px) {
    max-width: 250px !important;
  }
  @media (max-width: 430px) {
    max-width: 320px !important;
  }
 @media (min-width: 570px) {
    max-width: 450px !important;
  }
}

.creative_station {
  padding-top: 130px;
  background: $whitish;
  min-height: 100vh;
  margin-bottom: 150px;
  @media (max-width: 575px) {
    padding-top: 90px;
  }

  .cs_header {
    @include flexbox();
    @include justify-content(center);
    @include flex-direction(column);
    @include align-items(center);
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
    h1 {
      color: black;
    }
    h2 {
      font-weight: 200;
    }
  }

  section {
    margin-right: 40px;
    margin-left: 40px;
    @media (max-width: 575px) {
      margin: 0 20px;
    }
    header {
      @include flexbox();
      @include align-items(center);
      width: 100%;

      .h1_title {
        font-weight: bold;
        margin-right: 50px;
        @media (max-width: 575px) {
          margin-right: 30px;
          margin-bottom: 0;
        }
      }

      .blue_line {
        width: 100%;
        max-width: none;
        background: #182844;
      }
    }
  }
}

// skeleton size
.masonry > .col-12 > .d-flex > .ant-skeleton > .ant-skeleton-avatar,
.trending_masnory > .col-12 > .d-flex > .ant-skeleton > .ant-skeleton-avatar {
  width: 100% !important;
  border-radius: 5px;
}

.box {
  max-height: 200px;
  width: 20%;
  // border: 3px solid red;
  margin: 25px;
  margin-bottom: 150px;

  box-sizing: border-box;
  float: left;
}
.box2 {
  /*  max-height: 500px;
  width: 20%; */
  // border: 3px solid red;
  // margin: 25px;
  // margin-bottom: 150px;

  // box-sizing: border-box;
  float: left;
}
.box_width{
  width: 400px;
  margin:25px !important;
  height: 235px;
  /*
  @media only screen and (max-width: 979px) {
    width: 900px;
    margin:25px !important;
  }
  */



}

.imgCover {
  height: 225px;
  width: 100%;
  object-fit: cover;
}

.imgCover2 {
  height: 500px;
  width: 100%;
  // object-fit: cover;
}

.parent {
  overflow: hidden; /* Clear float */
}

.masonry {
  /* Masonry container */
  column-count: 4;
  column-gap: 1em;
  margin-top: 50px;
}
.item {
  /* Masonry bricks or child elements */
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
  @media (max-width: 575px) {
    width: 100%;
  }
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .masonry {
    column-count: 4;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .masonry {
    column-count: 3;
  }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .masonry {
    column-count: 2;
  }
}
@media only screen and (max-width: 540px) {
  .masonry {
    column-count: 1;
  }
}
.item:hover {
  text-decoration: none;
}
.masonry_item_with_description {
  background: white;
  // span {
  //   width: 100%;
  img {
    width: 100%;
    @include border-radius-with-sides(5px, 5px, 0, 0);
  }
  // }

  video {
    width: 100%;
    @include border-radius-with-sides(5px, 5px, 0, 0);
  }

  .masonry_item_description {
    box-shadow: rgba(0, 0, 0, 0.2) -1px 5px 9px;
    @include border-radius-with-sides(0, 0, 5px, 5px);
    padding: 18px;
  font-size: 16px;

    p {
      font-family: $font !important;
      color: black !important;
      font-weight: 200 !important;
      font-size: 0.9rem;
      // @media (max-width: 1366px) {
      //   font-size: 0.7rem;
      // }
    }
    .item_title {
      font-weight: bold;
      font-size: 0.9rem;
      // @media (max-width: 1366px) {
      //   font-size: 0.7rem;
      // }
    }
  }
}
.masonry_item_without_description {
  img {
    @include border-radius(5px);
  }

}
.theme {
  font-size: 16px ;
  @media only screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.link_moodboard{
  text-decoration: none;
  color: black;
}
.link_moodboard:hover{
  text-decoration: none;
  color: $blue-primary;
}
.masnory_block {
  margin-top: 50px;

  & > header > h1 {
    text-transform: uppercase;
  }
}
.masnory_block:first-child {
  margin-top: 10px;

  & > header > h1 {
    text-transform: uppercase;
  }
}

.see_more_btn_masnoary {
  margin-left: auto;
  margin-right: 30px;
  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);
  background: transparent;
  border: none;
  font-family: $font;
  color: $blue-primary;
  outline: none !important;

  & > svg {
    transition: all 0.3s ease-in-out;
  }
  &:hover > svg {
    transform: translateY(10px);
  }
}

.video_post {
  height: 225px;
}
video::-webkit-media-controls-current-time-display {
 // display: none;
}


.marginR {
  margin-right: 30px;
}
.trending_masnory {
  column-count: 3;
  column-gap: 0;
  margin-top: 50px;

  .item {
    break-inside: avoid;
    padding: 10px;
    width: 100%;
  }
  .video_post {
    border-radius: 5px;
    padding: 0;
  }

  // .item:nth-child(odd) {
  //   width: 50%;
  // }
  .item:nth-child(2n + 3) {
    width: 50%;
    vertical-align: top;
  }
  .item:nth-child(3n + 4) {
    width: 50%;
    vertical-align: top;
  }
  .item:nth-child(4n + 5) {
    width: 100%;
    vertical-align: top;
  }
  .item:nth-child(5n + 6) {
    width: 50%;
    vertical-align: top;
  }

  // Masonry on large screens
  @media only screen and (min-width: 1024px) {
    column-count: 3;
  }

  /* Masonry on medium-sized screens */
  @media only screen and (max-width: 1023px) and (min-width: 768px) {
    column-count: 2;
  }

  /* Masonry on small screens */
  @media only screen and (max-width: 767px) and (min-width: 540px) {
    column-count: 2;
  }
  @media only screen and (max-width: 570px) {
    column-count: 1;
  }
}

.add_post {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 45px;
  font-family: $font;
  font-weight: 500;
  white-space: nowrap;
  background: white;
  color: $blue-primary;
  padding: 10px;
  border-color: $blue-primary;
  font-size: 16px;

  @media (max-width: 786px) {
    font-size: 12px !important;
  }

  &:hover {
    color: white;
    background: $blue-primary;
    border-color: $blue-primary;
  }
}

.btn_cr {
  border: 1px solid #00468c;
  border-radius: 5px;
  font-weight: 500;
  @include transition(all, 0.2s);
}
.btn_cr_white {
  background: white;
  color: #00468c;
  &:hover {
    background: #00468c;
    color: white;
  }
}
.btn_cr_blue {
  background: #00468c;
  color: white;
  &:hover {
    background: #01366b;
  }
}

.btn_cr_grey {
  background: transparent;
  border: none;
  color: $blue-primary;
  &:hover {
    background: #e5e8ec;
  }
}

.rounded_select_antd {
  & > .ant-select-selector {
    border-radius: 2px !important;
  }
  border-radius: 2px !important;
}

.btn_cr_blue {
  & > button {
    height: 100%;
    background: $blue-primary;
    border-color: $blue-primary;
    color: white;
    border-radius: 5px;
    font-weight: 500;
    padding: 1.01rem 0;
    &:hover,
    &:focus {
      background: #01366b;
      border-color: $blue-primary;
      color: white;
    }
  }
  & > button:first-child {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  & > button:last-child {
    padding-left: 1rem !important;
    padding-right: 2rem !important;
    border-left-color: white;
  }
}

.delete_post_small_btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  z-index: 5;
  &:hover {
    background: rgb(0, 0, 0);
  }
}

/* .content {
  position: absolute;
  display: inline-block;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #fff;
  width: 100%;
  text-align: center;
  z-index: 1;
} */
.play {
  background: rgba(0, 70, 140, 0.7);
  font-size: 40px;
  cursor: pointer;
  display: inline-block;
  height: 70px;
  width: 70px;
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 50%;

  &:hover {
    background: rgba(0, 70, 140, 1);
  }

  & > .anticon-play-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.video_card_link:hover {
  text-decoration: none;
}
.preview_mode_modal > .ant-modal-content > .ant-modal-header {
  background: rgb(26, 26, 26) !important;
  & > .ant-modal-title {
    color: white !important;
  }
}
.preview_mode_modal
  > .ant-modal-content
  > .ant-modal-close
  > .ant-modal-close-x {
  color: white !important;
}
.preview_mode_modal > .ant-modal-content > .ant-modal-body {
  border-radius: 15px !important;
}
