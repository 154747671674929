@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.label_container {
  margin: 60px 0;
}
.label_elements_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
  @media (max-width: 1366px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    justify-content: space-between;
  }
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: transparent;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}
.label_title {
  @include flexbox();
  @include flex-direction(column);
  // @include justify-content(space-between);
  @include align-items(center);
  h2 {
    @media (max-width: 768px) {
      font-size: 20px;
    }
    margin-top: 25px;
    color: $blue-primary;
  }
  .blue_line {
    min-width: 50px;
    max-width: 50px;
    min-height: 5px;
    background-color: $blue-primary;
    margin-right: 0px;
    @media (max-width: 1200px) {
      margin-right: 20px;
      min-height: 3px;
      min-width: 40px;
      max-width: 40px;
    }
    @media (max-width: 992px) {
      margin-right: 10px;
      min-height: 3px;
      min-width: 30px;
      max-width: 30px;
    }
  }
}
