@import "./mixins.scss";
.go_back{
  color: #182844;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #182844;
  align-items: center;
  padding: 10px;
  display: flex;
  border-radius: 8px;
  cursor: pointer;

}

.iframe-view{
  position: relative;
  width: 100%;
  height: 89.7vh;
  object-fit: contain;
  border: none;
  @media (max-width: 991px) {
    //padding-top: 88px;
    height: 50vh;
  }
}
.product-view-right{

  @media (max-width: 991px) {
    top:100px !important;
}
}
.product-view-container {
  display: grid;
  width: 100%;
  //  navbar
  padding-top: 100px;
  grid-template-columns: 1fr;
  grid-template-rows: 50% 50%;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  @media (max-width: 991px) {
    padding-top: 102px;
  }
  @media (max-width: 768px) {
    padding-top: 88px;
  }
}

canvas {
  cursor: grab;
}
