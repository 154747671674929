@import "./mixins.scss";
@import "./variables.scss";

.single_post {
  padding-top: 109px;

  @media (max-width: 768px) {
    padding-top: 122px;
  }

  & > .container {
    margin-top: 30px;
    @media (max-width: 768px) {
      margin-top: 0px;
    }
    @media (min-width: 1200px) {
      max-width: 1300px;
    }
  }

  .img_container_post {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: -5px;
      left: 80%;
      right: 0px;
      margin: 0px auto;
      background: $blue-primary;
      height: 72px;
      width: 184px;
      mix-blend-mode: multiply;
      transform: rotate(38deg);
      @media  (max-width: 655px) {
        height: 0px !important;
        width: 104px;
        top: -15px;
        left: 80%;
      }
      @media only screen and (max-width: 979px) {
        height: 45px;
        width: 104px;
        top: -15px;
        left: 80%;
      }
    }
  }
  .img_container_post img {
    display: block;
    object-fit: cover;
    object-position: center top;
    opacity: 1;
    transition-duration: 0.15s;
    width: 100%;
  }
}

.divider_post {
  &::before {
    content: "";
    width: 40px;
    height: 4px;
    background-color: $blue-primary;
    display: block;
    margin: 0px auto 16px;
  }
}

.title_container_post {
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(center);

  .post_header {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    padding: 20px;
    text-align: center;
    font-family: $font;

    .post_title {
      font-family: $font;
      @media (max-width: 768px) {
        font-size: 32px;
      }
      @media (min-width: 1200px) {
        font-size: 40px;
      }
      @media (min-width: 1400px) {
        font-size: 48px;
      }
    }

    .post_type_title {
      font-weight: bolder;
      color: $dark-gray;
    }

    .post_date {
      font-size: 12px;
      letter-spacing: 3px;
      font-family: $font;
    }
    .post_by {
      font-weight: 500;
    }
  }
}

.divider_posts {
  background: gray;
  opacity: 0.3;
}

.back_cs {
  @include flexbox();
  @include align-items(center);
  cursor: pointer;

  &:hover > div > h1 {
    color: $blue-primary;
  }
  &:hover > img {
    box-shadow: 0px 2px 3px rgba(black, 0.1) !important;
    border-radius: 50%;
  }

  & > div > h1 {
    font-size: 16px;
    margin-bottom: 0;
  }
}
.back_cs > img {
  width: 42px;
  height: 42px;
  transition: all 0.2s ease-in-out;
}

.loading_article {
  height: 100vh;
  @media (max-width: 768px) {
    height: calc(100vh - 88px);
  }
  width: 100vw;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  & > .ant-spin-spinning > span > svg {
    color: $blue-primary !important;
  }
}

.pinterest_btn {
  background: #e60023;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px 10px;
  @include transition(all, 0.2s, ease-in-out);
  &:hover {
    background: #ad081b;
    text-decoration: none;
    color: white;
  }
}

.linkedin_btn {
  background: #0a66c2;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  @include transition(all, 0.2s, ease-in-out);
  &:hover {
    background: #004182;
    color: white;
    text-decoration: none;
  }
}



#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0 auto 20px;
  z-index: -1
}

.notfound .notfound-404 h1 {
  font-family: montserrat,sans-serif;
  font-size: 236px;
  font-weight: 200;
  margin: 0;
  color: #211b19;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%)
}

.notfound .notfound-404 h2 {
  font-family: montserrat,sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #211b19;
  background: #fff;
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0
}

.notfound a {
  font-family: montserrat,sans-serif;
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 13px 23px;
  background:#00468c;
  font-size: 18px;
  -webkit-transition: .2s all;
  transition: .2s all
}

.notfound a:hover {
  color: #00468c ;
  background: white;
  border: solid 1px #00468c ;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size:148px
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height:148px;
    margin: 0 auto 10px
  }

  .notfound .notfound-404 h1 {
    font-size: 86px
  }

  .notfound .notfound-404 h2 {
    font-size: 16px
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px
  }
}

