@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.sub_menu_extension {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .close_btn {
    color: $blue-primary;
    cursor: pointer;
    @include flexbox();
    @include align-items(center);
    @include align-self(flex-end);
    padding: 20px 50px 20px 20px;
    position: fixed;
    top: 20px;
    right: 40px;
    font-size: 16px !important;
    p {
      margin: 0 10px 0 0;
      font-weight: 500 !important;
    }
    svg {
      font-size: 25px;
    }
    @media (max-width: 1700px) {
      top: 10px;
      right: 20px;

    }
    @media (max-width: 1500px) {
      top: 15px;
      right: 30px;
    }
  }
  .sub_sub_menu_elements {
    position: fixed;
    top: 225px;
    left: 38%;
    @media (max-width: 1700px) {
      left: 40%;
    }
    @media (max-width: 1500px) {
      left: 42%;
    }
    .sub_sub_menu_element {
      display: block;
      padding-bottom: 18px;
      text-transform: uppercase;
      color: $black;
      font-weight: 400;
      font-size: 20px;
      position: relative;
      overflow: hidden;
      width: fit-content;
      text-decoration: none;
      @media (max-width: 1366px) {
        font-size: 16px;
      }
      &:hover {
        color: $blue-primary;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        display: block;
        margin-top: -2px;
        right: 0;
        background: #fff;
        transition: width 0.2s ease;
        -webkit-transition: width 0.2s ease;
      }

      &:hover:after {
        width: 100%;
        left: 0;
        background: $blue-primary;
      }
    }
  }
  .sub_menu_elements {
    position: fixed;
    min-height: 300px;
    min-width: 300px;
    top: 225px;
    left: 22%;

    .sub_menu_element {
      display: block;
      padding-bottom: 20px;
      text-transform: uppercase;
      color: $black;
      font-weight: 400;
      font-size: 20px;
      position: relative;
      overflow: hidden;
      width: fit-content;
      @media (max-width: 1366px) {
        font-size: 16px;
      }
      svg {
        height: 13px;
        width: 13px;
        margin-left: 5px;
        margin-bottom: 4px;
      }

      .sub_menu_element_val {
        text-decoration: none;
        color: $black;
      }
      &:hover .sub_menu_element_val {
        color: $blue-primary;
      }
      &:hover .plus {
        fill: $blue-primary;
        color: $blue-primary;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        display: block;
        margin-top: -2px;
        right: 0;
        background: #fff;
        transition: width 0.2s ease;
        -webkit-transition: width 0.2s ease;
      }

      &:hover:after {
        width: 100%;
        left: 0;
        background: $blue-primary;
      }
    }
  }
  .get_inspired_container {
    height: 760px;
    width: 600px;
    position: fixed;
    top: 11%;
    right: 8%;
    overflow: hidden;
    border-radius: 6px;
    @media (max-width: 1700px) {
      height: 690px;
      width: 620px;
    }
    @media (max-width: 1500px) {
      height: 550px;
      width: 450px;
      top: 16%;
      right: 6%;
    }
    @media (max-width: 1366px) {
      height: 550px;
      width: 400px;
      top: 16%;
      right: 6%;
    }

    .get_inspired {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 78px;
      font-weight: 800;
      text-transform: uppercase;
      color: white;
      @media (max-width: 1366px) {
        font-size: 60px;
      }
      span {
        font-weight: 200;
      }
    }
  }
}

.sub_menu_element:hover {
  text-decoration: none;
  color: $black;
}

.sub_menu_element_child {
  display: block;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  width: -moz-fit-content;
  width: fit-content;
  @media (max-width: 1366px) {
    font-size: 16px;
  }
  .sub_sub_menu_element {
    display: inline !important;
  }
  svg {
    height: 13px;
    width: 13px;
    margin-left: 5px;
    margin-bottom: 4px;
  }
}

.sub_menu_elements_childrens {
  @include flexbox();
  @include flex-direction(column);
  margin-left: 20px;
  margin-top: 18px;
  .sub_menu_child {
    display: block;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: $black;
    font-weight: 400;
    font-size: 20px;
    position: relative;
    overflow: hidden;
    width: fit-content;
    text-decoration: none;
    @media (max-width: 1366px) {
      font-size: 16px;
    }
    &:hover {
      color: $blue-primary;
    }
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      display: block;
      margin-top: -2px;
      right: 0;
      background: #fff;
      transition: width 0.2s ease;
      -webkit-transition: width 0.2s ease;
    }

    &:hover:after {
      width: 100%;
      left: 0;
      background: $blue-primary;
    }
  }
}
.plusIcon {
  margin-left: 10px;
  margin-bottom: 8px;
}
