@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.login_form_container {
  height: 810px;
  max-width: 624px !important;
  padding: 56px 68px !important;
  background-color: white;
  transform: translateX(50px);
/*  @media screen and (max-width: 1150px) {
    height: 100vh;
    max-width: 100% !important;
    // transform: translateX(0px);
    // margin-left: -65px;
  }

 */
  @media screen and (max-width: 900px) {
    min-width: 500px !important;
    padding: 56px 20% !important;
    transform: translateX(25px);
  }
  @media screen and (max-width: 750px) {
    min-width: 300px!important;
    padding: 56px 15% !important;
  }
 /* @media screen and (max-width: 500px) {
    width: 100% !important;
    padding: 56px 10% !important;
  }
  @media screen and (max-height: 800px) {
    padding: 30px 40px !important;
    height: 700px;
    // max-width: 550px !important;
  }
  @media screen and (max-height: 700px) {
    padding: 26px 58px !important;
    height: 610px;
    // max-width: 550px !important;
  }*/
}
.login_form_container_initial {
  width: 100%;
  .title_header {
    h2 {
      @media screen and (max-height: 700px) {
        font-size: 28px;
      }
    }
  }
  .submit_button_white_full_width {
    width: 80%;
    margin: 0 auto;
    margin-top: 55px;
    white-space: nowrap;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;

    @media screen and (max-width: 700px) {

      font-size: 12px !important;
    }

  }
  button {
    @media screen and (max-height: 700px) {
      margin-top: 20px;
      padding: 15px 0;
    }
  }
}
.login_form_logo {
  height: 70px;
  @media screen and (max-width: 900px) {
    height: 35px;
  }
}
.field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 1em;
  position: relative;
}
.login_password_label,
.login_email_label {
  color: $dark-gray;

  font-size: 16px !important;
  @media screen and (max-width: 700px) {
    font-size: 12px !important;
  }
}
.h2_subtitle_blue_login {
  margin-bottom: 56px;
  @media screen and (max-height: 700px) {
    margin-bottom: 12px;
  }
}
.field {
  label,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
  }
  input {
    font-size: 1.1em;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    padding-bottom: 3px;
    cursor: text;
  }

  input:focus {
    outline: 0;
    border-bottom: 1px solid #666;
  }
  label {
    letter-spacing: 0.06em;
  }
  input:placeholder-shown + label {
    font-size: 16px  !important;

    margin-bottom: 0;
    font-family: $font;
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    //transform: translate(0, 1.6rem) scale(1.25);
    transform: translate(0, 1.6rem) scale(1);
    @media screen and (max-width: 786px) {
      font-size: 12px !important;
    }
  }
  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }
  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }
  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, 0) scale(1);
    font-size: 14px !important;
    cursor: pointer;
    margin-bottom: 0;
  }
}

.submit_button_white_full_width_login {
  margin-top: 40px !important;
  @media screen and (max-width: 1200px) {
    padding: 1.6rem 0;
  }
}
.forget_password,
.back_sign_in {
  text-align: end;
  cursor: pointer;
}
.forget_password {
  text-decoration: underline;
  color: $blue-primary;
  font-size: 16px;
  @media screen and (max-width: 786px) {
    font-size: 12px;
  }
}

.login_form_forgot_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media screen and (max-height: 800px) {
    margin-bottom: 40px;
  }
  @media screen and (max-height: 700px) {
    margin-bottom: 26px;
  }
}
.login_form_forgot_password {
  margin-bottom: 80px;
  margin-top: 35px;
}
.parag_rest_password,
.parag_code {
  margin-bottom: 48px;
  font-size: 16px;

  @media screen and (max-width: 786px) {
    font-size: 12px !important;
  }
}
.h2_subtitle_blue_reset {
  margin-bottom: 34px;
/*  text-transform: uppercase;*/
  font-size: 32px;
  font-weight: bold;
  @media screen and (max-width: 786px) {
    font-size: 20px !important;
    font-weight: bold;
  }
}

.login_go_back{
  color: #182844;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #182844;
  align-items: center;
  padding: 10px;
  display: flex;
  border-radius: 8px;
  @media screen and (max-width: 900px) {
    .go_back_text{
      display: none;
    }
  }
}

.back_arrow_back_link {
  @include flexbox();
  @include align-items(center);
  height: fit-content;
}
.back_sign_in {
  margin-left: 20px;
  margin-bottom: 0 !important;
}
.confirm_button_white_full_width {
  margin-top: 80px;
  width: -webkit-fill-available;
  font-size: 20px;
}

.fa-exclamation-triangle {
  position: absolute;
  right: 10px;
  top: 50%;
  color: red;
  font-size: 18px;
}
.verif_button {
  margin-top: 40px;
  width: -webkit-fill-available;
  font-size: 20px;
}
.parag_code {
  font-size: 16px;
  .email_code {
    color: $black;
    font-weight: 500;
  }
}
.expire_text,
.resend_question,
.join_question {
  color: $dark-gray;
  text-align: center;
  /*font-weight: 600;*/

}
.join_question {
  margin-top: 20px;
  font-size: 16px;
  @media screen and (max-width: 786px) {
    font-size: 12px !important;
  }
}
.resend_question {
  margin-top: 20px;
}
.time_expiration {
  color: #7a9ec5;
}
.resend_text {
  color: $blue-primary;
  cursor: pointer;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.pin_code_container {
  width: 100%;
  height: 50px;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  @include flexbox();
  @include justify-content(space-around);
}
.pin_code_input {
  width: 4.5rem !important;
  padding-bottom: 10px !important;
  font-size: 32px;
  color: $blue-primary;
  margin: 0 20px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none !important;

  @media (max-width: 786px) {
    font-size: 24px !important;
    width: 2.5rem !important;
  }
}
.pin_code_input_error {
  border-color: red;
  color: red;
}
.pin_code_input::-webkit-input-placeholder::before {
  color: $blue-primary;
  content: ".";
}
.seperator {
  color: white;
}

.h2_subtitle_blue_join {
  font-size: 32px !important;
  @media (max-width: 768px) {
    font-size: 20px !important;
  }
}
.login_form_forgot_top_join {
  margin-bottom: 25px;
}

.join_demco_btns > .submit_button_white_full_width {
  padding: 1rem 2rem;
}
.join_demco_personal {
  display: flex;
  flex-direction: column;
}

.join_demco_personal > .submit_button_white_full_width {
  align-self: flex-end;
  padding: 1rem 2rem;
}

.join_demco_btns > .submit_button_white_full_width:first-child {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }
}

.join_form_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.main_login_form {
  @include flexbox();
  @include flex-direction(column);

  .submit_button_white_full_width {
    @media (max-width: 768px) {
    /*  padding: 1.6rem 5rem;*/
    }
  }
}

.company_join_btn {
  margin: 0 !important;
  margin-top: 55px !important;
  width: inherit !important;
}
