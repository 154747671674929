@import "../../sass/variables.scss";
@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.dicover_card {
  height: 650px;
  width: 430px;
  position: relative;
  overflow-y: hidden;
  @include flexbox();
  @include align-items(center);
  @include flex-direction(column);
  @media (max-width: 1366px) {
    height: 490px !important;
  }
  @media (max-width: 768px) {
    max-width: 440px !important;
    margin: 0 auto !important;
  }
  .discover_card_submenu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: translateY(60%);
    @include gradient(rgba(100, 100, 100, 0.111), rgba(0, 0, 0, 0.837));
    @include transition(all, 0.3s, ease-in-out);
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @include flex-direction(column);
    .discover_card_list {
      // height: 46.5%;
      height: 64.5%;
      // height: 300px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-start);
      @include flex-direction(column);
      color: $white;
      div {
        text-align: center;
        overflow-y: auto;

        /* width */
        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          border-radius: 50px;
          background: transparent;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: transparent;
        }
      }
      .h2_subtitle {
        color: $white;
      }

      .section_home{
        font-size: 32px !important;
        font-weight: bold;
      }
      .paragraphe {
        color: $white;
        line-height: 3rem;
        padding: 10.5px;
        font-size: 1.563rem;
        font-weight: 200;
        font-family: $font;
      }
    }
    .discover_card_footer {
      position: absolute;
      bottom: 0px;
      width: 100%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-around);
      border-top: 0.5px solid #ffffff54;
      padding: 15px 0;
      opacity: 0;
      transform: scale(0.7);
      transition: all 0.3s ease-in-out 0.05s;
      p {
        color: $white;
        font-size: 1.563rem;
      }
    }
  }
  .discover_card_title {
    position: absolute;
    bottom: 20px;
    opacity: 1;
    color: $white;
    white-space: nowrap;
     font-size: 24px ;
    font-weight: 600;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 20px;
      // line-height: 2.5rem;
    }

  }
  &:hover .discover_card_submenu {
    opacity: 1;
    transform: translateY(0%);
  }
  &:hover .discover_card_footer {
    transform: scale(1);
    opacity: 1;
  }
  &:hover .discover_card_title {
    opacity: 0;
    transform: translateY(0%);
  }
}

.arrows {
  position: absolute;
  bottom: -50px;
}

.discover_item_name {
  font-size: 1.4rem !important;
  line-height: 1.4rem !important;

  @media (max-width: 768px) {
    font-size: 1.3rem !important;
    line-height: 1.2rem !important;
  }
  @media (min-width: 992px) {
    font-size: 1.3rem !important;
    line-height: 2rem !important;
  }
  @media (min-width: 1100px) {
    font-size: 1.4rem !important;
    line-height: 2rem !important;
  }
  @media (min-width: 1200px) {
    font-size: 1.5rem !important;
    // line-height: 2.7rem!important;
  }
  @media (min-width: 1366px) {
    font-size: 1.7rem !important;
    // line-height: 2.8rem!important;
  }
  @media (min-width: 1600px) {
    font-size: 1.5rem !important;
    // line-height: 2.9rem!important;
  }
  @media (min-width: 1920px) {
    font-size: 1.6rem !important;
    // line-height: 3rem!important;
  }
}
