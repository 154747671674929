@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.custom_left_arrow,
.custom_right_arrow {
  color: $blue-primary;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}
.custom_left_arrow {
  right: 50px;
  font-size: 40px;
}
.custom_right_arrow {
  right: 0;
  font-size: 40px;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  padding: 15px 5px !important;
  position: absolute;
  bottom: 10%;
}
.alice-carousel__stage-item {
  width: 100%;
}
.alice-carousel__stage-item > div {
  width: 100%;
  height: calc(100vh - 300px);

  @media (max-height: 850px) {
    height: calc(100vh - 220px);
  }
}
.alice-carousel__stage-item > div > .product_card {
  width: 100%;

  @media (max-height: 850px) {
    background-size: contain;
    background-position: center center;
  }
}
.product_card {
  @media (max-width: 767px) {
    background-position: center;
  }
}
.alice-carousel__prev-btn {
  .custom_left_arrow {
    @media (max-width: 767px) {
      right: 55%;
    }
  }
}
.alice-carousel__next-btn {
  .custom_right_arrow {
    @media (max-width: 767px) {
      left: 55%;
    }
  }
}
