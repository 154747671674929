@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.folder_details_container {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  // min-width: calc(100vw - 450px) !important;
  min-width: calc(100vw - 450px) !important;
  max-height: calc(100vh - 109px);
  height: calc(100vh - 109px);
  background-color: white;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 20px 80px;
  padding-right: 0 !important;
  padding-bottom: 0;
  z-index: 3;
  overflow: scroll;
  overflow-x: auto;
  @media (max-width: 1366px) {
    min-width: calc(100vw - 420px) !important;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgb(161, 161, 161);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  .close_btn {
    top: 1px !important;
    right: 20px !important;
    color: #00468c;
    cursor: pointer;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -ms-align-self: flex-end;
    align-self: flex-end;
    // padding: 20px 50px 20px 20px;
    position: absolute;
    z-index: 3;
  }
  .folder_details_content_container {
    .folder_header_container,
    .folder_description_container,
    .folder_tags_container {
      margin-bottom: 25px;
    }
    .folder_header_container {
      .folder_header {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        transform: translateX(-38px);
        padding-bottom: 0;
        p {
          color: $blue_primary;
          margin-bottom: 0 !important;
          font-size: 30px;
          font-weight: 500;
        }
        svg {
          margin-right: 12px;
        }
      }
      .folder_root_container {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        p {
          color: #83b8e6;
          margin-bottom: 0 !important;
          font-size: 16px;
          font-weight: 400;
        }
        svg {
          margin-right: 12px;
        }
      }
    }
  }
  .folder_description_container {
    .folder_description_title {
      font-size: 20px;
      font-weight: 700;
    }
    .paragraphe {
      font-size: 1rem;
    }
  }
  .folder_tags_container {
    .folder_tags_title {
      font-size: 20px;
      font-weight: 700;
    }
    .paragraphe {
      font-size: 1rem;
    }
  }
  .folder_products_container {
    .folder_products_title {
      font-size: 20px;
      font-weight: 700;
    }
    .products_list {
      @include flexbox();
      @include align-items(basline);
      @include justify-content(flex-start);
      // overflow-x: scroll;
      overflow-x: scroll;
      // overflow-y: hidden;
      white-space: nowrap;
      // height: 627px;
      &::-webkit-scrollbar {
        width: 8px;
        // margin-left: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: D;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: transparent;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }
      .product_card {
        display: inline-block;

        height: 100%;
      }
    }
  }
}

.product_card {
  text-decoration: none !important;
}
