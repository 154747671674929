@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";
.team_management_container {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  padding-top: 150px;
  padding-bottom: 50px;
  //   @include flexbox();
  //   @include flex-direction(column);
  //   @include align-items(center);
  @media screen and (min-width: 1500px) {
    max-width: 1400px !important;
  }
  @media screen and (max-width: 768px) {
    padding-top: 115px !important;
    // height: calc(100vh - 87px);
  }
  @media screen and (max-width: 575px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .alerts_position {
    position: absolute;
    top: 120px;
    right: 20px;
  }
  p {
    color: black;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 18px;
    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .team_management_title {
    color: $blue-primary;
    font-size: 32px;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .invite_member_container {
    padding: 10px 20px;
    margin-bottom: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    width: 100%;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    @media screen and (max-width: 1000px) {
      border: none;
      @include flex-direction(column);
      @include align-items(flex-start);
      padding: 0;
    }
    .add_member_form {
      @include flexbox();
      @include align-items(center);
      @media screen and (max-width: 1000px) {
        @include justify-content(space-between);
        width: 100%;
      }
      @media screen and (max-width: 768px) {
        @include flex-direction(column);
        width: 100%;
      }
      .input_new_member {
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 300px;
        margin-right: 10px;
        @media screen and (max-width: 768px) {
          width: inherit;
          margin-right: 0px;
        }
      }
      .ant-spin {
        @media screen and (max-width: 768px) {
          transform: translateX(-36px) !important;
        }
      }
    }

    .invite_member_description {
      @include flexbox();
      @include align-items(center);
      @media screen and (max-width: 1000px) {
        margin-bottom: 12px;
      }
      svg {
        margin-right: 10px;
      }
    }

    .submit_button_blue {
      font-size: 18px !important;
      padding: 20px 50px;
      @media screen and (max-width: 1000px) {
        padding: 12px 50px;
        width: 400px;
      }
      @media screen and (max-width: 768px) {
        width: inherit;
        margin: 10px 0;
      }
    }
  }
  .input_search_member {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 350px;
    margin-right: 10px;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
      width: inherit;
      margin-right: 0px;
    }
  }
  .items_titles {
    padding-right: 20px !important;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    .item_col_title {
      //   padding-left: 30px;
      margin-bottom: 10px;
      font-size: 1rem;
      color: $dark-gray;
      font-weight: 500;
    }
  }

  .item_container {
    border: 1px solid #ccc;
    border-bottom: none;
    font-weight: 500;
    text-align: start;
    padding: 15px 0 !important;
    background: white;
    @media screen and (max-width: 768px) {
      padding: 10px 0 !important;
      margin: 10px 0 !important;
      border-bottom: 1px solid #ccc;
      border-radius: 4px;
    }

    .member_name {
      .owner_badge {
        background-color: $blue-primary;
        border-radius: 20px;
        color: white;
        padding: 5px 7px;
        font-size: 0.8rem;
        margin-left: 1rem;
      }
      .member_img {
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        width: 30px;
        margin-right: 10px;
        cursor: pointer;
      }
      p {
        font-size: 1rem;
      }
      .member_infos {
        p {
          color: $dark-gray;
          font-size: 14px;
          font-weight: 500 !important;
        }
        .member_name_text {
          font-size: 1rem;
          color: black;
          // font-weight: 700 !important;
        }
      }
    }

    .item_element {
      color: $dark-gray;
      font-size: 1rem;
      display: flex;
      align-items: center;
      //   padding: 18px 30px;
      p {
        margin-bottom: 0;
        font-weight: 400;
        color: black;
      }
    }
    .item_actions {
      .button_delete_member {
        background-color: white;
        border-radius: 6px;
        border: 1px solid #ccc;
        padding: 5px 10px;
        margin-right: 10px;
      }
      .trash_icon {
        height: 1rem;
        width: 1rem;
        cursor: pointer;
        transform: translateY(-2px);
      }
      .owner_badge {
        background-color: $blue-primary;
        border-radius: 20px;
        color: white;
        padding: 5px 10px;
        margin-left: 0px;
      }
    }
  }
  .item_container_first {
    margin-top: 0 !important;
  }
  .item_container_last {
    border: 1px solid #efefef !important;
  }
  .list_items {
    // overflow-y: scroll;
    // height: 60vh;
    padding-right: 5px !important;
    &::-webkit-scrollbar {
      width: 8px;
      transform: translateX(10px);
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background: rgb(161, 161, 161);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
}
.empty_search_result_team {
  text-align: center;
  margin-top: 30px;
  p {
    font-weight: 400;
  }
}

.add-user-icon{
  display: none;
}

.add-user-text {
  display:  block;
}
.invite_btn {
  background: $blue-primary;
  border: 1px solid $blue-primary;
  color: white;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  transition: all 0.125s ease-in-out;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
  &:hover {
    background: white;
    color: $blue-primary;
  }
  @media screen and (max-width: 768px) {
/*      display: flex;
    align-items: center;
    align-content: center;*/
   /* width: 50px;
    height: 50px;*/

    .add-user-icon {
      display: inline-block;
    }
    .add-user-text {
      display:  none ;
    }
  }
}
.add_new_client_title{
  font-size: 24px !important;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  padding: 20px;
  color: $blue-primary;
  @media screen and (max-width: 768px) {
    font-size: 16px !important;
  }
}
.font-size-head{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.add_client_label{
 label{
   font-size: 16px !important;
   color: #757575;
   @media screen and (max-width: 768px) {
     font-size: 12px !important;
   }
}

  input::placeholder{
    font-size: 16px !important;
    @media screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }

}

.email_display{

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.add_client_filed{
  font-size: 16px  !important;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}

.add_client_filed::placeholder {
  font-size: 16px  !important;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.add_new_client_btn_company{
  font-size: 16px;
  color: $blue-primary;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }

}
 .add_client_filed:focus {
  border: 1px solid $blue-primary !important;
}

.add_client_filed:hover {
  border: 1px solid $blue-primary !important;
}
.cancel_btn {
  background: white;
  border: 1px solid $blue-primary;
  color: $blue-primary;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  transition: all 0.125s ease-in-out;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 12px !important;
  }
  &:hover {
    background: $blue-primary;
    color: white;
  }
}

.invite-teammate-drawer {
  .ant-form-item-required {
    color: #182844FF !important;
  }
}

.invit_new_member_title{
 margin-top: 20px;
  padding: 20px;
  font-size: 24px !important;
  @media screen and (max-width: 768px) {
    font-size: 16px !important;
  }
}
 .ant-btn-primary {
  background: $blue-primary !important;

}
.approved{
  color: rgb(7, 171, 18)!important
}
.not-approved{
  color: #00468c !important;
}
.approved_active{
  background-color: #f5f2f2  !important;
  cursor: not-allowed;
  pointer-events: none;
}
.list_of_companies_title{
    color: #182844;
  font-size: 32px !important;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: $font;
  @media screen and (max-width: 768px) {
    font-size: 20px !important;
  }
}


.button_member{
  background-color: white;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin-right: 10px;
}