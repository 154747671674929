@import "./variables.scss";
@import "./mixins.scss";

.direct_order {
  padding-top: 105px !important;

  @media (max-width: 768px) {
    padding-top: 87px !important;
  }
  @media (min-width: 1100px) {
    padding-top: 109px !important;
  }
}
.order_list_side {
  @media (max-width: 768px) {
    height: calc(100vh - 87px);
  }
}

.order_content {
  margin-left: auto;
  padding: 30px;

  @media (max-width: 580px) {
    width: 100%;
    padding: 10px;
  }
  @media (min-width: 767px) {
    max-width: calc(100vw - 400px);
  }
  @media (min-width: 581px) and (max-width: 768px) {
    max-width: calc(100vw - 300px);
  }

  & > .ant-collapse-icon-position-left {
    background: transparent !important;
  }
  & > .ant-collapse-icon-position-left:nth-child(n + 2) {
    margin-top: 10px;
  }

  &
    > .ant-collapse-icon-position-left
    > .ant-collapse-no-arrow
    > .ant-collapse-header {
    text-align: center;
    color: $blue-primary !important;
    font-weight: 600 !important;
    font-family: $font !important;
    text-transform: capitalize;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  &
    > .ant-collapse-icon-position-left
    > .ant-collapse-no-arrow
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 0;
  }
}

.order_selected_header {
  @include flexbox();
  @include align-items(center);
  @include justify-content(space-between);

  & > a:hover {
    text-decoration: none;
  }
  .edit_btn_order {
    @include flexbox();
    @include align-items(baseline);
    background: white;
    border: 1px solid $light-gray;
    font-family: $font;
    color: $dark-gray;
    border-radius: 5px;
    padding: 10px 15px;
    outline: none;
    font-size: 16px;
    & > svg {
      color: $blue-primary;
      margin: 0 10px 0 0;
      @media (max-width: 540px) {
        margin: 0;
        font-size: 12px;
      }
    }

    &:hover {
      background: $whitish;
    }

    @media (max-width: 425px) {
      align-items: center;
      white-space: nowrap;
    }
  }
  & > div > h1 {
    font-family: $font;
    font-weight: 400;
    font-size: 32px;
    cursor: pointer;
    color: $blue-primary;

    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 400;

    }
  }
}
.order_selected_title_header {
  & > h1 {
    @media (max-width: 768px) {
      font-size: 20px !important;
    }
  }
}

.collapse_header {
  background: $blue-sky;
  color: $blue-primary;
  font-family: $font;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px !important;
  }
}
.table_details_option_td
{
  width: 50%;
  padding: 8px;
  border-bottom: 1px solid #DEE2E6FF;
  border-right: 1px solid #DEE2E6FF;

}

.option_details_collapse {
  & > p {
    font-family: $font;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
    & > strong {
      font-family: $font;
      font-size: 16px;
      font-weight: 600 !important;
      @media (max-width: 768px) {
        font-size: 12px !important;
      }
    }
  }
}

.file_option_miniature {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  height: 100%;
  // min-width: 100px;

  background: $blue-sky-light;
  color: $dark-gray;

  & > svg {
    color: $blue-primary;
    font-size: 50px;
  }

  & > p {
    font-size: 14px;
    font-family: $font;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.548);
    border-radius: 5px;
    opacity: 0;
    z-index: 10;
    transition: all 0.3s ease-in-out;
  }
  &::after {
    content: "\f06e";
    font-family: "Font Awesome 5 Free";
    font-size: 22px;
    color: $blue-primary;
    padding: 30px;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    z-index: 11;
    width: 65px;
    height: 65px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  &:hover::before {
    opacity: 1;
  }
  &:hover::after {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.files_wrapper {
  max-height: 250px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: $blue-sky-light;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgba($blue-primary, 0.5);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

.order_details_container {
  height: calc(100vh - 109px);
  overflow-y: auto;
}

.article_name_input {
  border: none;
  border-bottom: 1px solid rgba($blue-primary, 0.5);
  outline: none;
}

.articles_list > div > div > div > svg {
  transition: all 0.3s ease-in-out;
  padding: 1px !important;
}
.articles_list > div > div > div > svg:hover {
  padding: 0px !important;
  background: $blue-sky;
  border-radius: 50%;
}

.add_collab_popover {
  .ant-select-show-search {
    width: 300px !important;
  }
}

.order_sent_drawer > .ant-drawer-content-wrapper {
  height: calc(100vh - 109px) !important;
  box-shadow: none !important;
  .ant-drawer-content .ant-drawer-content .ant-drawer-body {
    text-align: center;
  }
  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body > div {
    text-align: center;
  }

  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body > div > h1 {
    font-family: $font !important;
    font-weight: 500 !important;
    font-size: 45px !important;
  }
  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
  }
}

.order_sent_drawer > .ant-drawer-mask {
  background: transparent !important;
}


.order_sent_drawer_customized > .ant-drawer-content-wrapper {
  height: calc(100vh - 109px) !important;
  box-shadow: none !important;
  .ant-drawer-content .ant-drawer-content .ant-drawer-body {
    text-align: center;
  }
  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body > .head {
    text-align: center;
  }

  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body > .head > h1 {
    font-family: $font !important;
    font-weight: 500 !important;
    font-size: 45px !important;
  }
  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
    @include flexbox();
    @include flex-direction(column);
    /*     @include align-items(center);
    @include justify-content(center); */
  }
}
.order_sent_drawer_customized > .ant-drawer-mask {
  background: transparent !important;
}

.ant-drawer-close {
  outline: none !important;

  & > span > svg {
    fill: $blue-primary;
  }
}
.order_sent_drawer_mobile > .ant-drawer-content-wrapper {
  height: calc(100vh - 88px) !important;

  .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body > div > div {
    h1 {
      font-size: 30px;
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
    & > div > button {
      border-radius: 5px;
      border-color: $blue-primary !important;
      color: $blue-primary !important;
    }
  }
}

.and_others_collabs {
  padding: 10px;
  border: 1px solid rgba($blue-primary, 0.5);
  border-radius: 50%;
  margin-left: -20px;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.205);
  font-family: $font;
}

.loading_orders {
  height: 100vh;
  @media (max-width: 768px) {
    height: calc(100vh - 88px);
  }
  width: 100vw;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);

  & > .ant-spin-spinning > span > svg {
    color: $blue-primary !important;
  }
}

.drawer_collaboration {
  width: 30% !important;
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  @media (max-width: 1024px) {
    width: 80% !important;
  }
}

.drawer_collaboration {
  .ant-drawer-content-wrapper
    > .ant-drawer-content
    > .ant-drawer-wrapper-body
    > .ant-drawer-body
    > .collab_drawer_header {
    // width: 80% !important;

    & > .add_collab_popover {
      margin: 0 !important;
      padding: 0 !important;
      width: fit-content !important;

      & > .ant-select-show-search {
        width: 200px !important;
      }
    }
  }
}

.article_popover_btn {
  background: transparent;
  border: none;
  border-radius: 5px;

  &:hover {
    background: rgba($light-gray, 0.5);
  }
  &:focus {
    outline: none;
    background: rgba($light-gray, 0.5);
  }
}

.delete_attached_cusomization {
  cursor: pointer;
  background: #c8c9ca !important;
  /* &:hover {
    // background: rgba(255, 39, 39, 0.685) !important;
    background: #eef5fc !important;
  } */
}

.ant-select-selection-item-remove > .anticon-close {
  vertical-align: 0.2em !important;
}

.add_new_article_direct_order {
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.add_new_article_direct_order:disabled {
  background: $light-gray;
}

.shared_order_btn {
  background: transparent;
  border: none;
  margin-right: 20px;
  padding: 0;
  @media (max-width: 1024px) {
    margin-right: 8px;
  }
  & > svg > path {
    fill: $blue-primary !important;
  }
}

.collaborateur-avatar {
  position: relative !important;
}
.collaborateur-avatar:hover:after {
  opacity: 1;
}

.collaborateur-avatar:after {
  content: "\f2ed";
  font-family: "Font Awesome 5 Free";
  color: white;
  opacity: 0;
  background: rgba(255, 0, 0, 0.432) !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0px !important;
  left: 0px !important;
}
